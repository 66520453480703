/*! Copyright 2024 Lane Crawford, All Rights Reserved. */




(function( LC, $ ) {

	"use strict";

	/**
	 * set up a global pubsub hook on top of the LC
	 * global namespace.
	 *
	 * Also create one for tracking to send data
	 * from the core module to any affiliates.
	 */

	LC.pubsub = $({});
	LC.track = $({});

	/**
	 * usage examples:
	 *
	 * 		subscribe / listen:
	 *
	 *  		LC.pubsub.on("module.event", function( data ){});
	 *
	 * 		publish / emit:
	 *
	 *   		LC.pubsub.trigger("module.event", {} );
	 *
	 */




	/**
	 * try to attach an instance of window.location to LC.location
	 * so that IE9 can handle it's stuff.
	 *
	 * https://github.com/devote/HTML5-History-API
	 */

	LC.location = window.history.location || window.location;



}( window.LC = window.LC || {}, jQuery ));


/**
 * using the theory described in the "jquery" section of
 * Addy Osmani's excellent javascript-module article;
 * https://addyosmani.com/largescalejavascript/#modtheory
 *
 * we have a "lcmodule" namespace to define modules across
 * the site. LC modules will time their own execution, and will
 * auto-run the following methods in order;
 *
 *  // instantly
 * 		- setup()
 * 	 	- subscribe()
 *
 *	// onDomReady
 * 		- init()
 * 	 	- events()
 *
 * A sublime snippet is available to generate modules
 * http://gitlab.lanecrawford.com/frontend/snippets/blob/master/js-lcmodule2.sublime-snippet
 */

( function( LC, $ ) {

	"use strict";

	var defaults, waitfor;

	/**
	 * method to return a promise which waits for
	 * another module to load. This is useful if we need
	 * to wait for another module to exist before executing
	 * code in a module.
	 *
	 * Use like; 'ex.waitfor( "other.module" ).done( ... )'
	 *
	 * @param   {string}  module  | module's name/path. eg: "delivery.promise"
	 * @return  {promise}         | jQuery deferred promise
	 */
	waitfor = function( module ) {

		var def = new $.Deferred(),
			timeout = 3000,
			interval,
			timer,
			check;

		check = function() {

			var moduleToWaitFor = _.get( LC, module ),
				moduleExists = typeof moduleToWaitFor !== "undefined",
				moduleInitialised = moduleExists && moduleToWaitFor.initialised;

			if ( moduleInitialised ) {

				clearTimeout( timer );
				clearInterval( interval );
				def.resolve();
				moduleToWaitFor = null;

			}

		};

		if ( module ) {

			interval = setInterval( check, 100 );

			timer = setTimeout( function() {

				clearTimeout( timer );
				clearInterval( interval );
				def.reject();

			}, timeout );

		} else {

			def.reject();

		}

		return def;

	};

	/**
	 * object of default public values for all modules;
	 * these can be overridden inside of the module.
	 * @type {Object}
	 */
	defaults = window.lcmoduledefaults = {

		openClass: 		"is-open",
		tempClass: 		"is-temp",
		clearClass: 	"is-clear",
		activeClass: 	"is-active",
		selectedClass: 	"is-selected",
		disabledClass: 	"is-disabled",
		hiddenClass: 	"hide",
		speed: 			200,
		waitfor: 		waitfor

	};

	/**
	 * lcmodule2 constructor, must always be called like;
	 *
	 *     new lcmodule2( "module.name", function( exports, _this ) {
	 *
	 *         _this.privateVar =     0;
	 *         exports.publicVar =    1;
	 *
	 *         exports.setup =        function() {};
	 *         exports.subscribe =    function() {};
	 *         exports.init =         function() {};
	 *         exports.events =       function() {};
	 *
	 *         _this.privateFunc =    function() {};
	 *         exports.publicFunc =   function() {};
	 *
	 *     });
	 */
	window.lcmodule2 = window.lcmodule2 || function( name, module ) {

		var _this = this,
			created = false;

		/**
		 * if the name passed in is not a string, then
		 * we create a new name for the module, and we
		 * assume the first parameter must then be the
		 * module reference.
		 */
		if ( typeof name !== "string" ) {

			// set the module to the first argument.
			module = name;
			name = _.uniqueId( "lcmodule_" );
			LC.warn( "lcmodule; 'name' is not a string, using: ", name );

		}

		/**
		 * the module cannot initialise without a function
		 * passed in as module content
		 */
		if ( typeof module !== "function" ) {

			LC.error( "lcmodule; 'module' is not a valid function" );
			return null;

		}

		/**
		 * start timing the module's execution
		 */
		window.timingMark({
			name: name,
			start: true
		});

		/**
		 * execute the "module" function with the context set as the
		 * current "new()" module, pass in the "exports" as this context.
		 * Last parameter is just a private object. Returns true/false.
		 * @type {boolean}
		 */
		created = module.call( _this, _this, {} ) ? !0 : !1;

		/**
		 * set some default values for this module,
		 * useful for getting the state of the module
		 * if we ever need it.
		 */
		_this.initialised = false;
		_this.moduleName = name;

		/**
		 * order of events....
		 * =================================================================
		 *   setup() -> subscribe() -> [ DOM READY ] -> init() -> events()
		 * =================================================================
		 */

		/**
		 * run the "setup()" method if it exists, this
		 * would be used for any module setup to execute
		 * at run-time, no need for DOM to be ready
		 */
		if ( _this.setup && typeof _this.setup === "function" ) {
			_this.setup();
		}

		/**
		 * run the "subscribe()" method if it exists, this
		 * should only contain pubsub subscriptions
		 */
		if ( _this.subscribe && typeof _this.subscribe === "function" ) {
			_this.subscribe();
		}

		/**
		 * when jQuery has determined that the DOM is ready
		 * we execute the "init()" and "events()" methods on this module.
		 */
		$( function() {

			if ( _this.init && typeof _this.init === "function" ) {
				_this.init();
			}

			if ( _this.events && typeof _this.events === "function" ) {
				_this.events();
			}

			/**
			 * module is now initialised
			 * @type {Boolean}
			 */
			_this.initialised = created;

			/**
			 * end timing the module's execution
			 */
			window.timingMark({
				name: name,
				end: true
			});

		});

		/**
		 * create a new object on the "LC" namespace
		 * with a reference to this module.
		 *   e.g.
		 *      LC.myModule
		 *      LC.pdp.myModule
		 */
		_.set( window.LC, name, _this );

		return _this;

	};

	/**
	 * add the default values to the lcmodule prototype
	 */
	_.extend( window.lcmodule2.prototype, defaults );


	// 🔸
	// 🔸
	// 🔸
	// 🔸 legacy lcmodule format for backwards compat
	// 🔸 please try to use lcmodule2 from above !
	// 🔸
	// 🔸
	// 🔸

	window.lcmodule = window.lcmodule || function( module ) {

		var id = module.moduleName || _.uniqueId();

		// start timing the module's execution
		window.timingMark({ name: id, start: true });

		$( function() {

			// run the initialisation method after DOM is ready.
			if ( module.init && typeof module.init === "function" ) {
				module.init();
			}

			// end timing the module's execution
			window.timingMark({ name: id, end: true });

		});

		return module;

	};

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> config
 *
 * replacement for the old-lc style of using
 * lcInfo, this has the same `getValue()` and
 * `setValue()` methods, but is written a bit
 * more efficiently (using lodash methods)
 * and also part of the LC namespace
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.config = lcmodule( function( exports ) {

		exports.moduleName = "config";

		/* eslint no-console: "off" */

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = _.extend( {}, window.lcmoduledefaults, exports ),
			_this = {},
			config = {},
			settings = {};

		if ( typeof window.lcInfo === "undefined" ||
		    typeof window.lcInfo.config === "undefined" ) {

			return false;

		} else {

			config = window.lcInfo.config;

		}

		/**
		 * Loop through the "config.settings" object
		 * and add each one to the LC.config object store.
		 *
		 * Each property in the config.settings object
		 * is deleted upon adding.
		 */
		_this.createSettings = function() {

			var configObj;

			while ( config.settings.length ) {

				configObj = config.settings.shift();

				if ( $.type( configObj ) === "object" ) {
					_this.add( configObj );
				}

			}

		};

		/**
		 * merge an object in to the LC.config store
		 *
		 * @param {Object} obj Object to merge in
		 */
		_this.add = function( obj ) {

			_.extend( settings, obj );

		};

		/**
		 * method to return a value from the
		 * LC.config object using dot-notation as
		 * an accessor to deeper levels.
		 *
		 * @param  {String} key dot-notation way of accessing a property
		 * @return {Depends}
		 */
		ex.getValue = function( key ) {

			var ret;

			if ( typeof key === "undefined" ) {

				console.warn( "getValue: missing key" );

			} else {

				ret = _.get( settings, key );

				if ( typeof ret === "undefined" ) {
					console.info( "getValue: no such key (" + key + ")" );
				}

			}

			return ret;

		};

		/**
		 * method to intuitively set a value in
		 * the LC.config object store by using
		 * dot-notation.
		 *
		 * @param {String} key   	dot-notation representation of the property to set
		 * @param {Depends} value 	value to be set
		 */
		ex.setValue = function( key, value ) {

			var ret;

			if ( typeof key === "undefined" || typeof value === "undefined" ) {

				console.warn( "setValue: missing key/value" );

			} else {

				ret = _.set( settings, key, value );

			}

			return ret;

		};

		// we don't wait for init, we just
		// instantly create the settings object
		// so it's ready for usage throughout the app
		_this.createSettings();

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 * a bunch of configuration and default code,
 * or helper methods related to jQuery.
 */


$(function() {

	"use strict";


	$(document).ajaxError( function( e, response ) {

		if ( response.status === 409 ) {

			LC.pubsub.trigger( "global.sessionError" );

		}

	});


});




/**
 * a bunch of configuration and default code,
 * or helper methods related to jQuery UI.
 */


$(function() {

	"use strict";

	var closeText = Lang[ "dialog.close" ];


	/**
	 * extend the default autocomplete options with the values below.
	 */
	$.extend( $.ui.autocomplete.prototype.options, {

		delay: 50,
		minLength: 0,
		autoFocus: true,
		position: { my: "center top", at: "center bottom-1px" },

		create: function() {

			var $complete = $( this ),
				extensionPoint = $complete.data( "ui-autocomplete" );

			/**
			 * modify the resulting list for the autocomplete,
			 * add a list and shadow class so that it has nicer styling.
			 */

			extensionPoint._renderMenu = function( ul, items ) {

				var menu = this;

				ul.addClass( "list pop-shadow / txt-14 pos-a ofy-a bor-1 bor-grey bg-white" );

				$.each( items, function( index, item ) {
					menu._renderItemData( ul, item );
				});

			};

			/**
			 * modify the resulting item for the autocomplete,
			 * add necessary calsses for nice styles.
			 */

			extensionPoint._renderItem = function( ul, item ) {

				var listHtml =
					"<li class=\"of-h cur-p\">" +
						"<div class=\"pad-v5 pad-h10\">" +
							item.label +
						"</div>" +
					"</li>",

					$li = $( listHtml );

				return $li.appendTo( ul );

			};

			/**
			 * iPhones / iPads seem to fire the events in a strange
			 * order, meaning it would require 2 touches to activate the
			 * menu and select an item. So we will remove some extra events
			 * for those devices.
			 *
			 * @param  { Boolean } LC.helpers.isIos | Is this an iPhone (uses LC.helpers.js)
			 *
			 */
			if ( LC.helpers.isIos() ) {

				extensionPoint.menu.element
					.off( "menufocus hover mouseover" );

			}

		}

	});

	/**
	 * extend the default dialog options with the values below.
	 */
	$.extend( $.ui.dialog.prototype.options, {

		modal: true,
		width: "auto",
		minHeight: 0,
		closeText: "",
		autoOpen: true,
		position: false,

		create: function() {

			var $modal = $( this );

			$modal
				.parents()
				.find( ".ui-dialog-titlebar-close .ui-button-icon" )
				.addClass("lc-icon txt-14")
				.attr({
					"data-icon": "close",
					"title": closeText
				});

		},

		open: function() {

			var $modal = $( this );

			// close any preloaders.
			LC.pubsub.trigger("preloader.close");

			// remove any orphans.
			LC.pubsub.trigger("global.unorphanize", { scope: $modal });

			// make sure forms are handled properly.
			LC.pubsub.trigger("global.openmodal", { scope: $modal });

			// make sure foundation modules are working.
			$modal.foundation();

			// autofocus the close button
			//  - prevent jquery ui auto focus the first tabbable element
			//  - the auto-hover effect may make user confuse
			$modal
				.parents()
				.find( ".ui-dialog-titlebar-close" )
				.trigger( "focus" );

		},

		close: function() {

			// always destroy dialogs when they
			// are closed, this prevents having the same
			// ajax content / code open twice.
			$( this ).dialog("destroy");

		}

	});



});




/**
 * a bunch of configuration and default code,
 * or helper methods related to Foundation.
 */


$(function() {

	"use strict";

	Foundation.Tabs.defaults.linkClass = "lc-tabs__tab";
	Foundation.Tabs.defaults.panelClass = "lc-tabs__panel";

	Foundation.Accordion.defaults.multiExpand = false;
	Foundation.Accordion.defaults.allowAllClosed = true;

	Foundation.Tooltip.defaults.showOn = "tb";
	Foundation.Tooltip.defaults.hOffset = 14;
	Foundation.Tooltip.defaults.vOffset = 14;
	Foundation.Tooltip.defaults.hoverDelay = 50;
	Foundation.Tooltip.defaults.triggerClass = "lc-tooltip";
	Foundation.Tooltip.defaults.positionClass = "top";
	Foundation.Tooltip.defaults.fadeInDuration = 100;
	Foundation.Tooltip.defaults.fadeOutDuration = 400;

	$(document).foundation();

	Foundation.Tooltip.cleanup = function() {

		$(".tooltip[role='tooltip']").remove();

	};

	// for iPad / touch devices, need such signal to hide all tooltips upon modal show
	LC.pubsub.on("global.beforeModal", function() {

		// only grab foundation tooltips
		var $tooltips = $("[data-tooltip]");

		if ( $tooltips.length > 0 ) {
			$tooltips.foundation("hide");
		}
	});

});


/**
 * a bunch of configuration and default code,
 * or helper methods related to lanecrawford.
 */



/**
 * replace the default console.log with a LC version.
 */
(function( LC ) {

	/* eslint no-console: "off" */

	"use strict";

	var canlog,
		cantlog;

	LC.log = function() {};
	LC.info = function() {};
	LC.warn = function() {};
	LC.error = function() {};

	/**
	 * method to determine if we can display
	 * log messages to the console.
	 *
	 * @return {Boolean}
	 */
	canlog = function() {

		// get the url parameters
		var sitelog = LC.config.getValue( "site.log" ),
			urlParams = URI.parseQuery( new URI( window.location ).query() );

		// set persistent logging to true if param is found
		if ( urlParams.showlog || urlParams.lcdebug ) {
			LC.localStorage.setItem( "showlog", true );
		}

		// return if there's a "showlog" or "lcdebug" in the url
		return sitelog ||
			urlParams.showlog ||
			urlParams.lcdebug ||
			LC.localStorage.getItem( "showlog" ) ||
			false;

	};

	/**
	 * method to run, if we're not allowed to log
	 */
	cantlog = function() {

		if ( window.console && window.console.log ) {

			console.log( "%c🙅💬%c LC logging disabled",
			            "font-size: 20px;", "color: #f24886; margin-left: 20px;" );

		}

	};

	/**
	 * set up all the LC logging methods
	 */
	if ( window.console && canlog() ) {

		console.log( "%c🙆💬%c LC logging enabled",
		            "font-size: 20px;", "color: #00e29e; margin-left: 20px;" );

		if ( console.log ) {
			LC.log = console.log;
		}

		if ( console.info ) {
			LC.info = console.info;
		}

		if ( console.warn ) {
			LC.warn = console.warn;
		}

		if ( console.error ) {
			LC.error = console.error;
		}

	} else {

		cantlog();

	}

}( window.LC || {} ));


/**
 * Save newvisitor cookie upon new visit
 */
(function( LC ) {

	"use strict";

	var cookieName = "NEWVISITOR",
		cookieValue = "newVisitor",
		expiresIn = 30, // (days)
		domain;

	// get the site url from the config
	domain = LC.config.getValue( "site.siteUrl" );
	// then remove "www." or "secure." so we have a cross-domain string for cookie sharing
	domain = domain.replace( /(www|secure)\./i, "" );

	if ( domain ) {
		Cookies.set( cookieName, cookieValue, { expires: expiresIn, domain: domain });
	}

}( window.LC || {} ));


/**
 * Fire GTM event on jQuery document ready
 */
$(function() {

	"use strict";

	LCDataLayer.push({
		event: "pageInit"
	});

});


/**
 * a bunch of configuration and default code,
 * or helper methods related to Flickity.
 */


$(function() {

	"use strict";

	// this is the code from the SVG file for the "arrow-left" icon
	var arrow = "M75.58,2.88L73.36,0.67,24.11,50.09,73.36,99.33l2.22-2.22-47-47Z";

	Flickity.defaults.arrowShape = arrow;
	Flickity.defaults.dragThreshold = 10;

	Flickity.defaults.friction = 0.8;
	Flickity.defaults.selectedAttraction = 0.12;
	Flickity.defaults.freeScrollFriction = 0.055;

	// overridden method to make the touch-drag start a little
	// less easily, means pinch-zoom is easier.
	Flickity.prototype.hasDragStarted = function( moveVector ) {

		// start dragging after pointer has moved 'x' pixels in either direction
		return !this.isTouchScrolling &&
			Math.abs( moveVector.x ) > this.options.dragThreshold;

	};

	// override the disable method to add a class, instead of
	// actually disable (can't click disabled buttons)
	Flickity.PrevNextButton.prototype.disable = function() {

		if ( !this.isEnabled ) {
			return;
		}

		this.element.className += " is-disabled";
		this.isEnabled = false;

	};

	// override the enable method to remove a class, instead of
	// removing the disabled attribute (can't click disabled buttons)
	Flickity.PrevNextButton.prototype.enable = function() {

		if ( this.isEnabled ) {
			return;
		}

		var replacer = new RegExp( /\s*is-disabled\s*/gi );
		this.element.className = this.element.className.replace( replacer, "" );
		this.isEnabled = true;

	};

	/* eslint-disable */
	/**
	 * Below is an overloaded version of the "TapListener" prototype
	 * for "pointerUp"... the pointer up delay of 300/320ms is too
	 * fast for newer iPhones, causing clicks on "arrows" and "bullets"
	 * to trigger twice (touchend, mouseup) ... #sigh
	 */

	var isPageOffset = window.pageYOffset !== undefined;

	TapListener.prototype.pointerUp = function( event, pointer ) {
	  // ignore emulated mouse up clicks
	  if ( this.isIgnoringMouseUp && event.type == 'mouseup' ) {
	    return;
	  }

	  var pointerPoint = Unipointer.getPointerPoint( pointer );
	  var boundingRect = this.tapElement.getBoundingClientRect();
	  // standard or IE8 scroll positions
	  var scrollX = isPageOffset ? window.pageXOffset : document.body.scrollLeft;
	  var scrollY = isPageOffset ? window.pageYOffset : document.body.scrollTop;
	  // calculate if pointer is inside tapElement
	  var isInside = pointerPoint.x >= boundingRect.left + scrollX &&
	    pointerPoint.x <= boundingRect.right + scrollX &&
	    pointerPoint.y >= boundingRect.top + scrollY &&
	    pointerPoint.y <= boundingRect.bottom + scrollY;
	  // trigger callback if pointer is inside element
	  if ( isInside ) {
	    this.emitEvent( 'tap', [ event, pointer ] );
	  }

	  // set flag for emulated clicks 300ms after touchend
	  if ( event.type != 'mouseup' ) {
	    this.isIgnoringMouseUp = true;
	    // reset flag after 300ms
	    setTimeout( function() {
	      delete this.isIgnoringMouseUp;
	    }.bind( this ), 400 );
	  }
	};
	/* eslint-enable */

});


/**
 * mostly helper functions for use with Handlebars
 */

$(function() {

	"use strict";

	/**
	 * Math Helper for simple mathematical equations on
	 * handlebars values.
	 *
	 * use like: {{math @index "*" 2}}
	 *
	 * @param   {integer}  lvalue    the left value to operate on (eg: 5)
	 * @param   {string}   operator  the symbol for operation (eg: +/-*)
	 * @param   {integer}  rvalue    the right value to operate on (eg: 10)
	 * @param   {object}   options   map of options passed in by handlebars
	 * @return  {string}             the result of the operation
	 */
	Handlebars.registerHelper( "math", function( lvalue, operator, rvalue, options ) {

		lvalue = parseFloat(lvalue);
		rvalue = parseFloat(rvalue);

		return {
			"+": lvalue + rvalue,
			"-": lvalue - rvalue,
			"*": lvalue * rvalue,
			"/": lvalue / rvalue,
			"%": lvalue % rvalue
		}[ operator ];

	});


});

/**
 * Polyfill for Number.isFinite()
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isFinite
 */

if ( !Number.isFinite ) {

	/* eslint no-extend-native: "off" */

	Number.isFinite = function( value ) {

		"use strict";

	    return typeof value === "number" && isFinite( value );

	};

}

/**
 * Polyfill for Number.isFloat()
 *
 */

if ( !Number.isFloat ) {

	/* eslint no-extend-native: "off" */

	Number.isFloat = function( num ) {

		"use strict";

		return typeof num === "number" &&
			Number.isNaN( num ) === false &&
			Number.isInteger( num ) === false;

	};

}

/**
 * Polyfill for Number.isInteger()
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
 */

if ( !Number.isInteger ) {

	/* eslint no-extend-native: "off" */

	Number.isInteger = function( value ) {

		"use strict";

		return typeof value === "number" &&
			isFinite(value) &&
			Math.floor(value) === value;

	};

}

/**
 * Polyfill for Number.isNaN()
 *
 */

if ( !Number.isNaN ) {

	/* eslint no-extend-native: "off" */

	Number.isNaN = function( num ) {

		"use strict";

		return typeof num === "number" &&
			isNaN( num );

	};

}


/**
 * Prototype for Number.toCurrency() to return a formatted String
 *
 * - (123456.99).toCurrency() 				-> "123,456.99"
 * - (1234567).toCurrency() 				-> "1,234,567.00"
 * - (1234567).toCurrency(0) 				-> "1,234,567"
 * - (1234567).toCurrency(2, "/" ) 			-> "1,234,567/00"
 * - (1234567).toCurrency(0, "/", "*" ) 	-> "1*234*567"
 * - (1234567).toCurrency(2, "/", "*" ) 	-> "1*234*567/22"
 *
 * for formatting numbers in to currency
 *
 * https://stackoverflow.com/a/149099/1121532
 */


if ( !Number.toCurrency ) {

	/* eslint-disable */

	/**
	 * convert a number to a currency string
	 * @param  {Number} c | how many decimal places to display
	 * @param  {String} d | decimal placeholder symbol (.)
	 * @param  {String} t | thousands placeholder symbol (,)
	 * @return {String}   | the newly formatted number as currency string
	 */
	Number.prototype.toCurrency = function (c, d, t) {

		var n = this,
			c = isNaN(c = Math.abs(c)) ? 2 : c,
			d = d == undefined ? "." : d,
			t = t == undefined ? "," : t,
			s = n < 0 ? "-" : "",
			i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j = (j = i.length) > 3 ? j % 3 : 0;

		return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");

	};

	/* eslint-enable */

}

/**
 * Polyfill for String.includes(), should save us having to write:
 * - String.indexOf( "x" ) > -1;
 * for every time we want to check x in String.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
 */

if ( !String.prototype.includes ) {

	/* eslint no-extend-native: "off" */

	String.prototype.includes = function( search, start ) {

		"use strict";

		if ( typeof start !== "number" ) {

			start = 0;

		}

		if ( start + search.length > this.length ) {

			return false;

		} else {

			return this.indexOf( search, start ) !== -1;

		}

	};

}

/**
 *
 *  Polyfill which enables the passage of arbitrary arguments to the
 *  callback functions of JavaScript timers (HTML5 standard syntax).
 *
 *  https://developer.mozilla.org/en-US/docs/DOM/window.setInterval
 *
 *  Syntax:
 *  var intervalID = window.setInterval(func, delay[, param1, param2, ...]);
 *  var intervalID = window.setInterval(code, delay);
 *
 **/

/* eslint-disable */

(function() {

  var interval = setInterval(function(arg1) {

    clearInterval(interval);

    if (arg1 === 'test') {
      // feature test is passed, no need for polyfill
      return;
    }

    var __nativeSI__ = window.setInterval;
    window.setInterval = function(vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */ ) {
      var aArgs = Array.prototype.slice.call(arguments, 2);
      return __nativeSI__(vCallback instanceof Function ? function() {
        vCallback.apply(null, aArgs);
      } : vCallback, nDelay);
    };

  }, 0, 'test');

}());

/**
 *
 *  Polyfill which enables the passage of arbitrary arguments to the
 *  callback functions of JavaScript timers (HTML5 standard syntax).
 *
 *  https://developer.mozilla.org/en-US/docs/DOM/window.setTimeout
 *
 *  Syntax:
 *  var timeoutID = window.setTimeout(func, delay[, param1, param2, ...]);
 *  var timeoutID = window.setTimeout(code, delay);
 *
 **/

/* eslint-disable */

(function() {

	setTimeout(function(arg1) {

		if (arg1 === 'test') {
			// feature test is passed, no need for polyfill
			return;
		}

		var __nativeST__ = window.setTimeout;
		window.setTimeout = function(vCallback, nDelay /*, argumentToPass1, argumentToPass2, etc. */ ) {
			var aArgs = Array.prototype.slice.call(arguments, 2);
			return __nativeST__(vCallback instanceof Function ? function() {
				vCallback.apply(null, aArgs);
			} : vCallback, nDelay);
		};

	}, 0, 'test');

}());


( function( LC ) {

	"use strict";

	/**
	 * some regex properties for use throughout the app.
	 * @type { Object }
	 *
	 * the regexs must not have "g" (global) modifier, because
	 * the regex will then apply it's result globally to all calls
	 * for that regex... it's weird.
	 */
	LC.regex = LC.regex || {};

	/**
	 * checks if a string is a product image: /ABC123_in_xl.jpg
	 */
	LC.regex.productImage =
		/(\/[A-Z]\/[A-Z]\/[A-Z]\/)([A-Z]{3}\d{3})(\_[\w\d]{1,2}\_)(xs|s|m|l|xl){1}(\.jpg(\?.*)?$)/;

	/**
	 * checks a string to see if it matches an appropriate
	 * product id. This is weak and will also match an skuid
	 * - ABC123
	 * - ABC123-*
	 * - ABC123.X
	 */
	LC.regex.productid =
		/^([A-Z]{3}\d{3})/;

	/**
	 * checks a string to see if it matches an appropriate
	 * product id pattern but will not match a skuid!
	 * - ABC123
	 */
	LC.regex.productidStrong =
		/^([A-Z]{3}\d{3})(?!.)$/;

	/**
	 * checks a string to see if it matches an appropriate SKU pattern
	 * - ABC123-*
	 * - ABC123-X
	 * - ABC123-50
	 * - ABC123-40.5
	 */
	LC.regex.skuid =
		/^([A-Z]{3}\d{3})\-.+$/;

	/**
	 * checks a string to see if it matches an appropriate SKU pattern
	 * - ABC123-*
	 */
	LC.regex.starskuid =
		/^([A-Z]{3}\d{3})\-\*+$/;

	/**
	 * checks a string to see if it matches an appropriate commerce id pattern
	 * - ci3804000069
	 */
	LC.regex.commerceid =
		/^ci\d{8,22}$/;

	/**
	 * checks a string to see if it matches an appropriate commerce id pattern
	 * - ua101ii, ua102500000001ii
	 */
	LC.regex.unavailableiteminfoid =
		/^ua\d{3,22}ii$/;

	/**
	 * checks a string to see if it matches an appropriate wishlist id pattern
	 * - gl3804000069
	 */
	LC.regex.wishlistid =
		/^(CN)?gl\d{5,22}$/;

	/**
	 * capture the size part of sku id
	 * - ABC123-*   =>  *
	 * - ABC123-34  =>  34
	 * - ABC123-xx  =>  xx
	 * - ABC123-x-x	=>  x-x
	 */
	LC.regex.skuSize =
		/\b[A-Z]{3}\d{3}\-(.+)/;

	/**
	 * checks a string to see if it matches an appropriate idevice pattern
	 * - iPod
	 * - iPad
	 * - iPhone
	 */
	LC.regex.idevice =
		/i(phone|pod|pad)/i;

	/**
	 * checks a string to see if it matches an appropriate wechat in-app browser pattern
	 * - micromessenger
	 * - e.g. mozilla/5.0 (linux; u; android 4.1.2; zh-cn; mi-one plus build/jzo54k)
	 *			applewebkit/534.30 (khtml, like gecko) version/4.0 mobile safari/534.30
	 *			micromessenger/5.0.1.352
	 * - e.g. mozilla/5.0 (iphone; cpu iphone os 5_1_1 like mac os x)
	 *			applewebkit/534.46 (khtml, like gecko) mobile/9b206 micromessenger/5.0
	 * - set in  case insensitive
	 */
	LC.regex.wechatBrowser =
		/microMessenger/i;

}( window.LC = window.LC || {} ));

/**
 *
 * [ module ]
 * helpers
 *
 * place any methods in here for helping with other modules
 * throughout the application. Any string manipulation or something
 * like that which is purely a stand-alone helper.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.helpers = lcmodule( function( exports ) {

		exports.moduleName = "helpers.general";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		var ex = exports = $.extend( {}, exports );

		/**
		 * Helper method to return an object containing
		 * query parameters from a url/string.
		 * @param   { String }  [url]  | a URL containing "?query=param&more=less"
		 * @return  { Object }         | a key-value pair object of query params
		 */
		ex.getQueryParams = function( url ) {

			if ( typeof url !== "undefined" ) {
				url = new URI( url );
			} else {
				url = new URI();
			}

			return URI.parseQuery( url.query() );

		};

		/**
		 * Helper method to return the value of a given
		 * parameter which exists either in the page url,
		 * or the given url argument
		 * @param   { String }  param  | the parameter we want the value for
		 * @param   { String }  [url]  | a URL containing "?query=param&more=less"
		 * @return  { String }         | value of the given param
		 */
		ex.getQueryParam = function( param, url ) {

			var params;

			if ( typeof url === "undefined" ) {
				url = new URI().toString();
			}

			params = ex.getQueryParams( url );

			return params[ param ];

		};

		/**
		 * Helper Method for returning a URL String but removing
		 * all the queries which begin with "_".
		 *
		 * @param  { String } url | a URL String
		 * @return { String }     | a URL String
		 */
		ex.removeUnderscoreQueries = function( url ) {

			var ret = new URI( url ),
				query,
				queries = URI.parseQuery( ret.query() );

			for ( query in queries ) {

				if ( query.substring( 0, 1 ) === "_" ) {

					delete queries[ query ];

				}

			}

			return ret.query( queries ).toString();

		};

		/**
		 * method to test if the supplied string is an idevice
		 * - useful for iphone-specific crappy bugs.
		 *
		 * @param  { String } ua 	| user agent string to test
		 * @return { Boolean }      | result of test
		 */
		ex.isIos = function( ua ) {

			ua = ua || navigator.userAgent;
			return LC.regex.idevice.test( ua );

		};

		/**
		 * method to test if current browser as WEChat In App browser
		 * - useful for wechat in app browser crappy bugs.
		 *
		 * @param  { String } ua 	| user agent string (optional) to tests
		 * @return { Boolean }      | result of test
		 */
		ex.isWeChat = function( ua ) {

			if ( ua ) {
				return LC.regex.wechatBrowser.test( ua );
			} else {
				return LC.config.getValue("browserTyper.wechatInApp");
			}

		};

		/**
		 * method to test if the supplied string is LCApp
		 * - useful for LCApp in app browser crappy bugs.
		 *
		 * @return { Boolean }      | result of test
		 */
		ex.isLcApp = function( ) {

			return LC.config.getValue("browserTyper.lcApp");

		};

		/**
		 * check if a given element (HTMLElement / $Object)
		 * is completely inside the viewport
		 * @param  {HTMLElement}  | element - The element to check
		 * @return {Boolean}      | is in viewport
		 */
		ex.isInViewport = function( element ) {

			var rect;

			if ( typeof jQuery === "function" && element instanceof jQuery ) {
				element = element[ 0 ];
			}

			if ( element ) {

				rect = element.getBoundingClientRect();

				return (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight ) &&
					rect.right <= ( window.innerWidth || document.documentElement.clientWidth )
				);

			} else {

				return false;

			}

		};

		/**
		 * check if a given element (HTMLElement / $Object)
		 * is partially visible in the viewport
		 * @param  {HTMLElement}  | element - The element to check
		 * @param  {Number}  	  | buffer - how much of the element needs to be inside viewport
		 * @return {Boolean}      | is in viewport
		 */
		ex.isOnScreen = function( element, buffer ) {

			var rect;

			if ( typeof jQuery === "function" && element instanceof jQuery ) {
				element = element[ 0 ];
			}

			if ( element ) {

				rect = element.getBoundingClientRect();
				buffer = buffer || 0;

				return (
					rect.top >= ( 0 - rect.height + buffer ) &&
					rect.left >= ( 0 - rect.width + buffer ) &&
					rect.bottom <= ( ( window.innerHeight || document.documentElement.clientHeight ) + rect.height - buffer ) &&
					rect.right <= ( ( window.innerWidth || document.documentElement.clientWidth ) + rect.width - buffer )
				);

			} else {

				return false;

			}

		};

		/**
		 * method to test if the supplied string is a wishlistId
		 * based on our atg wishlist id pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isWishlistId = function( stringToCheck ) {

			return LC.regex.wishlistid.test( stringToCheck );

		};

		/**
		 * determine if the referrer is from LC site
		 * @return {Boolean} | is the visitor coming from LC site (upon country change)
		 */
		ex.isFromLaneCrawford = function() {

			var referrerDomain = new URI( document.referrer ).hostname(),
				lcDomainKeyword = "lanecrawford";

			return referrerDomain.includes( lcDomainKeyword );

		};

		/**
		 * take a string and gnerate a seeded HSL color
		 * based on it. This would produce the same color
		 * output for the same output.
		 * @param   {String}   str    | input string used to see the hue
		 * @param   {Integer}  [sat]  | number between 0 and 100 for the saturation %
		 * @param   {Integer}  [lum]  | number between 0 and 100 for the luminance %
		 * @return  {String}          | string in format; "hsl( 0, 50%, 50% )"
		 */
		ex.stringToHsl = function( str, sat, lum ) {

			var hue = 0, i;

			sat = sat || 50;
			lum = lum || 50;

			if ( typeof str === "string" ) {
				for ( i = 0; i < str.length; i++ ) {
					// eslint-disable-next-line no-bitwise
					hue = str.charCodeAt( i ) + (( hue << 5 ) - hue );
				}
			}

			hue = Math.floor( Math.abs(( Math.sin( hue ) * 10000)) % 360 );
			return "hsl(" + hue + ", " + sat + "%, " + lum + "%)";

		};

		/**
		 * Calculate a 32 bit FNV-1a hash
		 * Found here: https://gist.github.com/vaiorabbit/5657561
		 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
		 *
		 * @param  {string}  str              | the input value
		 * @param  {boolean} [asString=false] | set to true to return the hash value as 8-digit
		 * 	                                  | hex string instead of an integer
		 * @param  {integer} [seed]           | optionally pass the hash of the previous chunk
		 * @return {integer | string}
		 */
		ex.hash32 = function( str, asString, seed ) {
			/* eslint-disable no-bitwise */
			var i, l,
				hval = (seed === undefined) ? 0x811c9dc5 : seed;

			for (i = 0, l = str.length; i < l; i++) {
				hval ^= str.charCodeAt(i);
				hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
			}
			if ( asString ) {
				// Convert to 8 digit hex string
				return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
			}
			return hval >>> 0;
			/* eslint-enable no-bitwise */
		};

		/**
		 * @param  {string}  str              | the input value
		 * @param  {boolean} [asString=false] | set to true to return the hash value as 8-digit
		 * 	                                  | hex string instead of an integer
		 * @param  {integer} [seed]           | optionally pass the hash of the previous chunk
		 * @return {integer | string}
		 */
		ex.hash64 = function( str, asString, seed ) {
			var hash1 = ex.hash32( str, asString, seed );  // returns 32 bit (as 8 byte hex string)
			return hash1 + ex.hash32( hash1 + str, asString, seed );  // 64 bit (as 16 byte hex string)
		};

		/**
		 * method to return a "deep link" to a specific
		 * part of the info-help section of the site.
		 *
		 * @param  {String} page            | the page type of info-help, e.g, help-and-info / terms-of-service
		 * @param  {String} sectionId       | sectionId of the desired section
		 * @param  {String} subsectionId    | subsectionId of the desired subsection ( optional )
		 * @param  {String} contentId       | contentId of the desired content ( optional )
		 * @return {String}                 | a url for the deep link
		 */
		ex.getHelpLink = function( page, sectionId, subsectionId, contentId ) {

			var site = LC.config.getValue("site.siteUrl"),
				url = new URI( site + "/info/help/" );

			if ( page ) {

				url.segment( page );

				if ( sectionId ) {

					url.segment( sectionId );

					if ( subsectionId ) {

						url.segment( subsectionId );

						if ( contentId ) {

							url.segment( contentId );

						}

					}

				} else  {

					LC.warn( "Generating an empty info-help deeplink; no arguments provided" );

				}

			} else {

				LC.warn( "Generating an empty info-help deeplink; plese provide page type, " +
														"e.g. help-and-info / terms-of-service" );

			}

			LC.info( "Generating help/info deeplink; " + url.toString() );
			return url.toString();

		};

		/**
		 * method to return the first number from anything
		 * @param  {Any} any | anything
		 * @return {Float} | a float number
		 */
		ex.getNumber = function( any ) {

			// if it's null or undefined, return 0
			if ( !any ) { return 0; }
			// if it's not a string or number, return 0
			if ( typeof any !== "string" && ( typeof any !== "number" || isNaN( any ) ) ) { return 0; }
			// make sure it's a string
			any += "";
			// remove all non-numeric characters
			var matchingChars = any.match(/(-?\d+(,\d*)*(\.\d+)?)/g),
				matchingNumbers;
			// if there are no numbers, return 0
			if ( !matchingChars ) { return 0; }
			// remove any empty matches
			matchingNumbers = matchingChars.filter(function(m) { return !!m; });
			// return the first valid number
			return parseFloat( matchingNumbers[ 0 ].replace( /,/g, "" ));

		};

		ex.snakeToCamel = function( str ) {

			return str.toLowerCase().replace( /([-_][a-z])/g, function( group ) {
				return group
					.toUpperCase()
					.replace( "-", "" )
					.replace( "_", "" );
			} );

		};

		return exports;

	}( LC.helpers || {} ));

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * helpers (product functions)
 *
 * a bunch of useful helper methods that related
 * to product data (getting skus, images, etc).
 *
 * We pass in "LC.helpers" or an empty object to
 * initialise.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.helpers = lcmodule( function( exports ) {

		exports.moduleName = "helpers.product";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		var ex = exports = $.extend( {}, exports );

		/**
		 * method to test if the supplied string is a productid
		 * based on our strong product id pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isProduct = function( stringToCheck ) {

			return LC.regex.productidStrong.test( stringToCheck );

		};

		/**
		 * method to test if the supplied string is a skuid
		 * based on our sku id pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isSku = function( stringToCheck ) {

			return LC.regex.skuid.test( stringToCheck );

		};

		/**
		 * method to test if the supplied string is a skuid
		 * based on our skuid pattern, and that it's a "star skuid"
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isStarSku = function( stringToCheck ) {

			return LC.regex.starskuid.test( stringToCheck );

		};

		/**
		 * method to test if the supplied string is a commerceId
		 * based on our atg commerce id pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isCommerceId = function( stringToCheck ) {

			return LC.regex.commerceid.test( stringToCheck );

		};

		/**
		 * method to test if the supplied string is a commerceId
		 * based on our atg commerce id pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isUnavailableItemInfoId = function( stringToCheck ) {

			return LC.regex.unavailableiteminfoid.test( stringToCheck );

		};

		/**
		 * method to get the product ID from a skuId
		 * @param  {String} sku | skuid
		 * @return {String}     | productid
		 */
		ex.productFromSku = function( sku ) {

			var productid = "";

			if ( ex.isSku( sku ) ) {

				productid = sku.match( LC.regex.productid )[ 0 ];

			}

			return productid;

		};

		/**
		 * @param  {string} sku | skuid
		 * @return {string} 	| either empty string or the size or "One Size"
		 */
		ex.sizeFromSku = function( sku ) {

			var size = "",
				regex = LC.regex.skuSize;

			if ( ex.isSku( sku ) ) {

				size = sku.match( regex )[ 1 ];

				if ( size === "*" ) {

					size = "One Size";

				}

			}

			return size;

		};

		/**
		 * method to test if the supplied string is a product image
		 * based on our product image pattern
		 * @param  { String }  stringToCheck | the string to check against
		 * @return { Boolean }               | result of test
		 */
		ex.isProductImage = function( stringToCheck ) {

			return LC.regex.productImage.test( stringToCheck );

		};

		/**
		 * a helper to get a product
		 * image from a product id, and optional size
		 *
		 * @param  { String } id  	| The id of the product
		 * @param  { String } size 	| The size we would like (xs,s,m,l,xl)
		 * @param  { String } type 	| The image angle/tile (in,ro,)
		 * @return { String }      	| The full url string with image size.
		 */
		ex.imageFromId = function( id, size, type ) {

			var server, folder, imgpath,
				legalSizes = [ "xs", "s", "m", "l", "xl" ],
				legalTypes = [ "in", "ro", "fr", "bk", "sd", "s2", "1", "2", "3", "4" ];

			if ( typeof id === "undefined" ) {

				LC.error( "no ID provided for the image function" );
				return "";

			}

			if ( !LC.helpers.isProduct( id ) ) {

				LC.error( "the ID provided for the image function is invalid" );
				return "";

			}

			server = LC.config.getValue("url.mediaServerFullURL");
			folder = id.split( "" ).slice( 0, 3 ).join( "/" );
			imgpath = server + "/" + folder + "/" + id;

			// check if the size was provided, and it is a legal
			// size of image, otherwise return "m".
			if ( !size || !_.includes( legalSizes, size ) ) {
				size = "m";
			}

			// check if the type was provided, and it is a legal
			// type of image, otherwise return "in".
			if ( !type || !_.includes( legalTypes, type ) ) {
				type = "in";
			}

			return imgpath + "_" + type + "_" + size + ".jpg";

		};

		/**
		 * a helper to get a product id from
		 * a product image url
		 *
		 * @param  { String } imageUrl | The url to extract the product id from
		 * @return { String }      	| The product id or empty string
		 */
		ex.idFromImage = function( imageUrl ) {

			var ret = "",
				match;

			if ( imageUrl ) {

				match =
					imageUrl
						.match( /\/\w\w\w\d\d\d\_/ );

				if ( match ) {

					ret =
						match[ 0 ]
							.replace( "_", "" )
							.replace( "/", "" )
							.toUpperCase();

				}

			}

			if ( !ret ) {
				LC.info( "nothing returned from LC.helpers.idFromImage(" + imageUrl + ") call" );
			}

			return ret;

		};

		/**
		 * Return the correct css style for a color-chip
		 * @param  {String|Undefined} 	color | The color value (can be empty)
		 * @param  {String|Undefined} 	image | The background image (can be empty)
		 * @return {string}				The resulting css string to use in a `style=""` attribute
		 */
		ex.colorChipBackgroundStyle = function( color, image ) {

			var cssBg = "",
				imageTest,
				colorIsImage;

			// return empty string if there's no parameters.
			if ( !color && !image ) {
				return cssBg;
			}

			imageTest = /^(.*)\.(jpe*g|png|gif|svg|web[pm]|bmp)$/gi;
			colorIsImage = imageTest.test( color );

			// if the first parameter passed is actually an image,
			// instead of a color, then re-cast the parameter
			if ( colorIsImage ) {

				image = color;
				color = undefined;

			}

			if ( image ) {

				cssBg += "background-image: url( " + image + " ); ";

			}

			if ( color ) {

				cssBg += "background-color: " + color + "; ";

			}

			return cssBg;

		};

		return exports;

	}( LC.helpers || {} ));

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * helpers (region/site functions)
 *
 * useful methods to run for multisite/domain
 * or general country/region stuff.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.helpers = lcmodule( function( exports ) {

		exports.moduleName = "helpers.regions";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		var ex = exports = $.extend( {}, exports );

		/**
		 * return the current site ID
		 * @return 	{String} | the site ID of a current profile
		 */
		ex.getSiteIdFromProfile = function() {

			return LC.config.getValue( "site.siteID" );

		};

		/**
		 * return the site ID of the given countrycode if provided
		 * @param 	{String} countryCode | the country code to return a site-id for
		 * @return 	{String} 			 | is the site ID of a given countryCode
		 */
		ex.getSiteIdFromCountryCode = function( countryCode ) {

			if ( typeof countryCode !== "string" || typeof countryCode === "undefined" ) {

				LC.warn( "countryCode should not be undefined" );
				return undefined;

			}

			switch ( countryCode ) {

				case "HK":

					return "zh_HK";

				case "CN":

					return "zh_CN";

				case "":

					LC.warn( "countryCode should not be an empty string" );
					// falls through

				default:

					return "en_US";

			}

		};

		/**
		 * determine if this is the China Site
		 * @return {Boolean} | is it the China site
		 */
		ex.isChinaSite = function() {

			return ex.getSiteIdFromProfile() === "zh_CN";

		};

		/**
		 * determine if this is the Hong Kong Site
		 * @return {Boolean} | is it the Hong Kong site
		 */
		ex.isHongKongSite = function() {

			return ex.getSiteIdFromProfile() === "zh_HK";

		};

		/**
		 * determine if this is an International Site
		 * @return {Boolean} | is it any site other than Hong Kong / China
		 */
		ex.isInternationalSite = function() {

			return !ex.isChinaSite() && !ex.isHongKongSite();

		};

		/**
		 * return the phone-country-prefix based on the country code
		 * @param 	{String} countryCode | the country code to return a site-id for
		 * @return {String} | the country's phone prefix
		 */
		ex.getCountryPhonePrefix = function( countryCode ) {

			var promise = new $.Deferred();

			if ( typeof countryCode !== "string" || typeof countryCode === "undefined" ) {
				LC.warn( "countryCode should not be undefined" );
				promise.reject();
			}

			$.ajax("/common/ajax/phone-prefix.json", {
				dataType: "json",
				mimeType: "text/plain"
			}).done(function(response) {

				promise.resolve( response[ countryCode ] || "" );

			});

			return promise;

		};

		return exports;

	}( LC.helpers || {} ));

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * helpers.session
	 * useful methods related to the session
	 *
	 * @param  {Object} exports | public-access object of methods/properties
	 * @param  {Object} _this   | private-scoped object of methods/properties
	 * @return {Object}         | return the public-access object
	 */
	new lcmodule2( "helpers.session", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		ex.getConfirmation = function() {

			if ( !_this.field ) {

				_this.field = document.querySelector( "input[ name = _dynSessConf ]" ) || {};

			}

			return _this.field.value;

		};

		/**
		 * take a data object, and then attempt to add
		 * in a new property called `_synSessConf` which is
		 * needed in all the GiftlistActor rest calls
		 * @param   {Object}  data  | object for a rest call
		 * @return  {Object}        | object with session conf added in
		 */
		ex.mergeSessionConfirmation = function( data ) {

			var conf = ex.getConfirmation();

			if ( !_.isObject( data ) ) {
				data = {};
			}

			data._dynSessConf = conf;

			return data;

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * format
 * Formatting methods which are only used for returning
 * a formatted string.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.format = lcmodule( function( exports ) {

		exports.moduleName = "format";

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports );


		/**
		 * date formatting for the Delivery Promise
		 * display area. Takes a posix date and converts it to
		 * a usable string based on the options object
		 *
		 * @param  { object } options
		 *
		 *		{
		 * 			posix: 	"1456976492413",
		 * 	  		locale: "en_US",
		 * 			month: 	"long",
		 * 			year: 	true
		 * 	  	}
		 *
		 * @return { string }
		 *
		 * 		usable string for UI
		 *
		 */
		ex.deliveryDate = function( options ) {

			var day, dayNum, month, year, date,
				defaults = {

					posix: Date.now(),
					locale: LC.config.getValue("profile.locale"),
					month: "long",
					year: true

				};

			options = _.extend( defaults, options );

			date = parseInt( options.posix, 10 );
			date = new Date( date );

			day = date.getDay();
			day = Lang[ "day." + day ];

			dayNum = date.getDate();
			month = date.getMonth();
			year = date.getFullYear();


			if ( options.month === "long" ) {

				month = Lang[ "month.full." + month ];

			} else {

				month = Lang[ "month." + month ];

			}

			if ( options.year ) {

				year = ", " + year;

			} else {

				year = "";

			}

			switch ( options.locale ) {

				case "zh_CN":
					return month + dayNum + Lang.day + day + year;

				case "en_US":
				default:
					return day + ", " + dayNum + " " + month + year;

			}

		};

		/**
		 * return the correct default date format (for use with date formatting)
		 * @returns {string} the default date format for the locale
		 */
		ex.defaultDateFormat = function() {

			if ( LC.config.getValue("profile.locale") === "zh_CN" ) {

				return "yyyy年MM月dd日";

			} else {

				return "dd/MM/y";

			}

		};

		/**
		 * method for returning a formatted date string similar
		 * to that which PHP can do.
		 * borrowed from http://stackoverflow.com/a/14638191/1121532
		 *
		 * @param  { Date } date
		 *     Date object for formatting.
		 *
		 * @param  { String } format
		 *     The desired string arrangement.
		 *
		 * @param  { Bool } utc
		 *     whether to use UTC time or not.
		 *     ( see http://stackoverflow.com/a/14740343/1121532 )
		 *
		 * @return { String }
		 *     A usable string for the date.
		 *
		 *
		 * use like:
		 *
		 *     var date 		= new Date();
		 *     var dateString 	= LC.format.date( date, "dd/MM/yyyy" );
		 *     var dateString 	= LC.format.date( date, "yyyy年MM月dd日" );
		 *
		 */
		ex.date = function( date, format, locale, utc ) {

			/* eslint computed-property-spacing: [ 0 ] */

			if ( typeof date === "undefined" ) {

				date = new Date();

			}

			if ( typeof format === "undefined" ) {

				format = ex.defaultDateFormat();

			}

			if ( typeof locale === "undefined" ) {

				locale = LC.config.getValue("profile.locale");

			}

			var MMMM, MMM, dddd, ddd, y, M, d,
				H, h, m, s, f, T, t, tz, K, tzHrs, tzMin, day;


			MMMM = [
				"\x00", "January", "February", "March", "April", "May", "June", "July",
				"August", "September", "October", "November", "December" ];

			MMM = [
				"\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
				"Aug", "Sep", "Oct", "Nov", "Dec" ];

			dddd = [
				"\x02", "Sunday", "Monday", "Tuesday", "Wednesday",
				"Thursday", "Friday", "Saturday" ];

			ddd = [ "\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ];


			if ( locale === "zh_CN" ) {

				dddd = [ "\x02", "星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
				ddd = [ "\x03", "天", "一", "二", "三", "四", "五", "六" ];

			}


			function pad( i, len ) {

				var string = i + "";
				len = len || 2;

				while ( string.length < len ) {
					string = "0" + string;
				}

				return string;

			}

			y = utc ? date.getUTCFullYear() : date.getFullYear();

			format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
			format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
			format = format.replace(/(^|[^\\])y/g, "$1" + y);

			M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
			format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
			format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
			format = format.replace(/(^|[^\\])MM/g, "$1" + pad(M));
			format = format.replace(/(^|[^\\])M/g, "$1" + M);

			d = utc ? date.getUTCDate() : date.getDate();
			format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
			format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
			format = format.replace(/(^|[^\\])dd/g, "$1" + pad(d));
			format = format.replace(/(^|[^\\])d/g, "$1" + d);

			H = utc ? date.getUTCHours() : date.getHours();
			format = format.replace(/(^|[^\\])HH+/g, "$1" + pad(H));
			format = format.replace(/(^|[^\\])H/g, "$1" + H);

			if ( H > 12 ) {
				h = H - 12;
			} else if ( H === 0 ) {
				h = 12;
			} else {
				h = H;
			}

			format = format.replace(/(^|[^\\])hh+/g, "$1" + pad(h));
			format = format.replace(/(^|[^\\])h/g, "$1" + h);

			m = utc ? date.getUTCMinutes() : date.getMinutes();
			format = format.replace(/(^|[^\\])mm+/g, "$1" + pad(m));
			format = format.replace(/(^|[^\\])m/g, "$1" + m);

			s = utc ? date.getUTCSeconds() : date.getSeconds();
			format = format.replace(/(^|[^\\])ss+/g, "$1" + pad(s));
			format = format.replace(/(^|[^\\])s/g, "$1" + s);

			f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
			format = format.replace(/(^|[^\\])fff+/g, "$1" + pad(f, 3));
			f = Math.round(f / 10);
			format = format.replace(/(^|[^\\])ff/g, "$1" + pad(f));
			f = Math.round(f / 10);
			format = format.replace(/(^|[^\\])f/g, "$1" + f);

			T = H < 12 ? "AM" : "PM";
			format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
			format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

			t = T.toLowerCase();
			format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
			format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

			tz = -date.getTimezoneOffset();

			if ( utc || !tz ) {
				K = "Z";
			} else if ( tz > 0 ) {
				K = "+";
			} else {
				K = "-";
			}

			if (!utc) {
				tz = Math.abs(tz);
				tzHrs = Math.floor(tz / 60);
				tzMin = tz % 60;
				K += pad(tzHrs) + ":" + pad(tzMin);
			}

			format = format.replace(/(^|[^\\])K/g, "$1" + K);

			day = (utc ? date.getUTCDay() : date.getDay()) + 1;
			format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
			format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

			format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
			format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

			format = format.replace(/\\(.)/g, "$1");

			return format;

		};

		/**
		 * return the current site's currency code (eg: HKD)
		 * @returns {String}
		 */
		ex.currencyCode = function() {
			return LC.config.getValue( "site.currencyCode" );
		};

		/**
		 * return the current site's currency symbol (eg: HK$)
		 * @returns {String}
		 */
		ex.currencySymbol = function() {
			return LC.config.getValue( "site.currencySymbol" );
		};

		/**
		 * return a currency string in current site's currency format
		 * @param {Number} value the value to return in currency format
		 * @param {Number} decimals the amount of decimal-places to return, defaults to 0
		 * @returns {String}
		 */
		ex.currency = function( value, decimals ) {
			var s = ex.currencySymbol();
			decimals = decimals || 0;
			if ( typeof value !== "number" ) {
				return s + "0";
			}
			return s + value.toCurrency( decimals );
		};

		/**
		 * a format helper to replace the size
		 * value in a product image string
		 *
		 * @param  { String } img  | The image string to replace the size for
		 * @param  { String } size | The new size we would like
		 * @return { String }      | The new full string with image size changed.
		 */
		ex.productImage = function( img, size ) {

			var replace = /(\_[\w\d]{1,2}\_)(xs|s|m|l|xl){1}(\.jpg(\?.*)?$)/,
				legal = [ "xs", "s", "m", "l", "xl" ];

			// check if the size was provided, and it is a legal
			// size of image, otherwise return "m".
			if ( !size || !_.includes( legal, size ) ) {
				size = "m";
			}

			if ( LC.helpers.isProductImage( img ) ) {

				img = img.replace( replace, function( $1, $type ) {
					return $type + size + ".jpg";
				});

			}

			return img;

		};

		ex.distance = function( km ) {

			var meters = Lang[ "misc.meters" ] || "m",
				kilometers = Lang[ "misc.kilometers" ] || "km";

			if ( typeof km === "number" ) {

				if ( km < 0.5 ) {

					return Math.round( km * 1000 ) + " " + meters;

				} else {

					return km.toFixed(2) + " " + kilometers;

				}

			} else {

				LC.warn( "argument for LC.formatDistance() should be an integer/float" );

				return 0 + meters;

			}

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));

/* eslint computed-property-spacing: [0] */

/**
 * A helper used in interfaces where we wish to check the "touch-end" event.
 * This is needed because "touch-end" is fired when a user removes their finger,
 * but that could happen while the user is scrolling/swiping...
 *
 * So we bind a helper to a "touch-start" event on a the given element... and if the
 * "touch-end" did _not_ occur within a certain disance of the start, then we don't consider
 * it a "touch-end" on that element.
 *
 * example usage:
 *
 * 	// binds a namespaced touch-start and sets some data on the element
 * 	// called "touchmoved", if the user has moved it's \true\, otherwise \false\.
 *  // unbinds the listeners on touch-end, and persists the data on element.
 *
 * 		$element
 * 			.checkmove()
 * 			.on("touchend", function(e) {
 *
 *			if ( !$element.data("touchmoved") ) {
 *
 *				// user did not move finger while clicking
 *				// which means we can run our code.
 *
 *			}
 *
 * 		});
 *
 *
 * We also might want to know if a user moved their finger and then
 * paused for a little while, possibly indicating they don't want to
 * interact, but also possibly to cancel an interaction.
 *
 *  // allows us to know if the user held down the finger
 *  // for a long time after moving by exposing some data
 *  // for "touchlong", use like:
 *
 * 		$element
 * 			.checkmove()
 * 			.on("touchend", function(e) {
 *
 *			// the test for "checkmove" data needs to be async,
 *			// because there's a chance that the events fire
 *			// out of order and the "touchlong" data is not yet
 *			// correctly applied. So we use setTimeout.
 *
 * 			setTimeout( function() {
 *
 *			 	if ( !$element.data("touchlong") ) {
 *
 *					// the touch event was not a long one.
 *
 *				}
 *
 * 			}, 10 );
 *
 * 		});
 *
 *
 * We also might want to know if a user was trying to
 * use multiple fingers when interacting, and so this
 * can be exposed through the "touchmulti" data.
 *
 * 		$element
 * 			.checkmove()
 * 			.on("touchend", function(e) {
 *
 *			// the test for "checkmulti" data needs to be async,
 *			// because there's a chance that the events fire
 *			// out of order and the "touchmulti" data is not yet
 *			// correctly applied. So we use setTimeout.
 *
 * 			setTimeout( function() {
 *
 *			 	if ( !$element.data("touchmulti") ) {
 *
 *					// the touch event didnt use multiple fingers.
 *
 *				}
 *
 * 			}, 10 );
 *
 * 		});
 *
 */

$.fn.checkmove = function() {

	"use strict";

	// the deltas of distance/time we will compare against.
	var delta = {

		distance: 10,
		time: 250

	};

	return $( this ).each( function( i, el ) {

		var $el = $( el );

		$el
			.off( ".touchhelper .temp" )
			.on( "touchstart.touchhelper", function( eStart ) {

				var
					start,
					end,
					distance,
					moved = false,
					long = false,
					multi = ( eStart.originalEvent.touches.length > 1 );

				$el.data({
					touchmoved: moved,
					touchlong: long,
					touchmulti: multi
				});

				// when we detect a touchstart, store the coordinates
				start = {
					coords: [
						eStart.originalEvent.touches[0].clientX,
						eStart.originalEvent.touches[0].clientY
					],
					time: Date.now()
				};

				end = $.extend( {}, start );

				$el

					.on( "touchmove.temp", function( eMove ) {

						// reset the start time for testing long hold
						start.time = Date.now();
						multi = ( eMove.originalEvent.touches.length > 1 );

						end.coords = [
							eMove.originalEvent.touches[0].clientX,
							eMove.originalEvent.touches[0].clientY
						];

						if ( !moved ) {

							distance = Math.sqrt(
								Math.pow( start.coords[0] - end.coords[0], 2 ) +
								Math.pow( start.coords[1] - end.coords[1], 2 )
							);

							if ( distance >= delta.distance ) {

								moved = true;
								$el.data( "touchmoved", moved ).off( eMove );

							}

						}

					})

					.on( "touchend.temp", function( eEnd ) {

						end.time = Date.now();

						if ( end.time - start.time > delta.time ) {
							long = true;
						}

						$el
							.data({
								touchlong: long,
								touchmulti: ( eEnd.originalEvent.touches.length > 1 )
							})
							.off(".temp");

					});

			});

	});

};

/* eslint new-cap: [2, { "capIsNewExceptions": ["GetDimensions"] }] */

/**
 * jQuery Extension to get dimensions of an element
 * -- better than the build-in $.fn.position()
 *
 * @return {object} contains a lot of dimension data, including the parent/window
 */

$.fn.dimensions = function() {

	"use strict";

	if ( typeof Foundation !== "undefined" && typeof Foundation.Box !== "undefined" ) {

		return Foundation.Box.GetDimensions( this[ 0 ] );

	} else {

		LC.error("Tried to get dimensions of element without Foundation.Box");
		return false;

	}

};


/**
 * jQuery Extension to reverse the given jQuery collection
 */

$.fn.reverse = function() {

	"use strict";

	return Array.prototype.reverse.call( this );

};


/* eslint */

/**
 * A small helper for getting an asset path
 * in the javascript layer.
 */
( function( LC ) {

	"use strict";

	/**
	 * helper to return an asset path
	 *
	 * @param  {String} path | path of asset relative to _assets/
	 *                       | eg: "img/x.png"
	 *                       | eg: "css/x.css"
	 *
	 * @return {String}      | full path of asset url (environment-contextual)
	 *                       | eg: "/_assets/dist/css/x.png"
	 *                       | eg: "//media.lanecrawford.com/_assets/dist/css/x.css"
	 */
	LC.asset = function( path ) {

		var assetUrl = LC.config.getValue( "url.assetUrl" ) || "";
		path = path || "";

		return assetUrl + path;

	};

}( window.LC = window.LC || {} ));

/* eslint-disable */

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/*  Latitude/longitude spherical geodesy formulae & scripts           (c) Chris Veness 2002-2014  */
/*   - www.movable-type.co.uk/scripts/latlong.html                                   MIT Licence  */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

"use strict";

/** Extend Number object with method to convert numeric degrees to radians */

if (typeof Number.prototype.toRadians === "undefined") {
    Number.prototype.toRadians = function() {
        return this * Math.PI / 180;
    };
}

/** Extend Number object with method to convert radians to numeric (signed) degrees */

if (typeof Number.prototype.toDegrees === "undefined") {
    Number.prototype.toDegrees = function() {
        return this * 180 / Math.PI;
    };
}

/**
 * Creates a LatLon point on the earth's surface at the specified latitude / longitude.
 *
 * @classdesc Tools for geodetic calculations
 * @requires Geo
 *
 * @constructor
 * @param {number} lat - Latitude in degrees.
 * @param {number} lon - Longitude in degrees.
 * @param {number} [height=0] - Height above mean-sea-level in kilometres.
 * @param {number} [radius=6371] - (Mean) radius of earth in kilometres.
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119);
 */

function LatLon(lat, lon, height, radius) {
    // allow instantiation without "new"
    if (!(this instanceof LatLon)) {
        return new LatLon(lat, lon, height, radius);
    }

    height = height || 0;
    radius = radius || 6371;
    radius = Math.min(Math.max(radius, 6353), 6384);

    this.lat    = Number(lat);
    this.lon    = Number(lon);
    this.height = Number(height);
    this.radius = Number(radius);
}

/**
 * Returns the distance from "this" point to destination point (using haversine formula).
 *
 * @param   {LatLon} point - Latitude/longitude of destination point.
 * @returns {number} Distance between this point and destination point, in km
 * (on sphere of "this" radius).
 *
 * @example
 *     var p1 = new LatLon(52.205, 0.119), p2 = new LatLon(48.857, 2.351);
 *     var d = p1.distanceTo(p2); // d.toPrecision(4): 404.3
 */

LatLon.prototype.distanceTo = function(point) {

    var R = this.radius,
        φ1 = this.lat.toRadians(),
        λ1 = this.lon.toRadians(),
        φ2 = point.lat.toRadians(),
        λ2 = point.lon.toRadians(),
        Δφ = φ2 - φ1,
        Δλ = λ2 - λ1,

        a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2),

        c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)),

        d = R * c;

    return d;
};


/**
 * register a resize event which will fire a windowResize pubsub
 * for better resize checking, allows to not bind crap directly
 * to the resize event.
 *
 * 		LC.pubsub.on( "windowResize", ... );
 *
 */

$(function() {

	"use strict";

	var $window = $(window),
		resizeInterval,
		resized = false;

	/*
     * simply set a flag to true when we resize.
     * this is very fast/performant
	 */
	$window.on("resize.globalresize", function() {
		resized = true;
	});

	resizeInterval = function() {

		if ( resized ) {

			resized = false;

			LC.pubsub.trigger( "windowResize" );

		}

	};

	/*
	 * poll the window every 300ms, instead of trying
	 * to do it on resize.
	 */
	setInterval( resizeInterval, 300 );

});


/**
 * register a scroll event which will fire a windowScroll pubsub
 * event every 120ms if the window really scrolled. This is used
 * for things such as a lazy-loading images. It also allows us to not
 * bind crap to window-scroll events and destroy scrolling performance.
 *
 * 		LC.pubsub.on( "windowScroll", ... );
 *
 */

$(function() {

	/* eslint lc-custom-rules/no-window-scroll: "off" */
	/* we don't want to flag an error in ESLINT for this module */

	"use strict";

	var $window = $(window),
		positionInterval,
		scrolled = false,
		winTop,
		winHeight,
		winBottom,
		bodyHeight;

	/*
     * simply set a flag to true when we scroll.
	 */
	$window.on("scroll.checkscroll", function() {
		scrolled = true;
	});

	positionInterval = function() {

		if ( scrolled ) {

			scrolled = false;
			winTop = $window.scrollTop();
			winHeight = $window.height();
			winBottom = winTop + winHeight;
			bodyHeight = $("body").height();

			LC.pubsub.trigger( "windowScroll", {
				windowTop: winTop,
				windowBottom: winBottom,
				windowHeight: winHeight,
				atTop: winTop <= 0,
				atBottom: winBottom >= bodyHeight
			});

		}

	};

	/**
	 * We wait a small amount of time before triggering the
	 * initial scroll event, this is because some elements on the
	 * page have their position manipulated by Javascript, and
	 * they will not have initialized, yet.
	 */

	setTimeout( function() {

		/*
		 * poll the window position, instead of trying
		 * to do it on scroll.
		 */
		setInterval( positionInterval, 120 );

	}, 300 );

});


/* eslint "no-unused-vars": "off" */


// this file is used inside of "inline" scripts on landing
// pages and the PDP before any libraries have loaded.
//
// It's main purpose is to provide simple helpers for
// things needed when doing inline scripts without jQuery.

(function( LC ) {

	"use strict";

	/**
	 * simple "removeClass" method to remove a css class
	 * from a HTML element without using jQuery.
	 * @param  {HTMLElement} el        	| element to remove css class from
	 * @param  {String} className 		| css class to remove from element
	 */
	LC.removeClass = function( el, className ) {

		var regex;

		if ( el instanceof HTMLElement && typeof className === "string" ) {

			if ( el.classList ) {

				el.classList
					.remove(className);

			} else {

				regex = new RegExp( "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi" );

				el.className =
					el.className
						.replace( regex, " " );

			}

		}

	};


	/**
	 * simple "addClass" method to add a css class
	 * to a HTML element without using jQuery.
	 * @param  {HTMLElement} el        	| element to add css class to
	 * @param  {String} className 		| css class to add to element
	 */
	LC.addClass = function( el, className ) {

		if ( el instanceof HTMLElement && typeof className === "string" ) {

			if ( el.classList ) {

				el.classList.add( className );

			} else {

				el.className += " " + className;

			}

		}

	};

}( window.LC = window.LC || {} ));

/**
 *
 * because the iOS safari has bug in BFCache, the pageshow event
 * are not always fire properly. Generally the pageshow event can
 * fire in the first "back" event, but NOT quite predictable in
 * the 2nd, 3rd event. Therefore, will try to refreshing page for
 * preventing future pageshow event failure
 *
 * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
 *
 * While chrome, firefox and desktop safari has no bug in BFCache,
 * so only need to close the dialog modal
 *
 */

( function( LC ) {

	"use strict";

	var isIos = LC.helpers.isIos();

	if ( isIos ) {

		window.onpageshow = function( event ) {

			if ( event.persisted ) {

				LC.location.reload();

			}

		};

	}

}( window.LC = window.LC || {}, jQuery ));



/**
 *
 * [ module ]
 * LC.modal
 * universal module for showing/hiding a modal
 * which can accept static content or ajax response.
 *
 */

( function( LC, $ ) {

	"use strict";

	if ( typeof $.ui === "undefined" ||
		typeof $.ui.dialog === "undefined" ) {

		LC.warn( "no $.ui.dialog available for modals" );
		return;

	}

	new lcmodule2( "modal", function( exports, _this ) {

		var ex = exports;

		// set the default style, name and options
		// for the modal dialogs which we will use later

		_this.defaults = {

			style: "fixed", // "fixed", "full"
			name: "anonymous",
			overlayCloses: true,
			widthClass: ""

		};

		// make sure all dialogs are removed when we navigate
		// back to the page via the "BackForwarcCache" (BFCache),
		// this is for safari mostly which will show the dialogs
		// if the page is navigated back to with window.history.back().

		_this.modalsClosedAtLoad = false;

		window.addEventListener( "popstate", function() {
			ex.closeOnPageLoad();
		});

		window.addEventListener( "pageshow", function() {
			ex.closeOnPageLoad();
		});

		// continue with usual LCModule code

		ex.setup = function() {

			/**
			 * a value for the how far off the top of hte screen
			 * we would like to show the modal.
			 * @type {Number}
			 */
			_this.paddingTop = 10;

			/**
			 * path to the error message folder, for showing
			 * error messages when using ajax modals.
			 * @type {String}
			 */
			_this.errorPath = "/dialog/_revamp/error/";

			/**
			 * number to use for counting how many errors a dialog
			 * has encountered. We then refresh the page if too many
			 * have occurred
			 * @type {Number}
			 */
			_this.errorCount = 0;

			/**
			 * a time (in ms) to wait before showing/firing the
			 * error modals. This is to prevent crazy-load-flashes when
			 * the server is down.
			 * @type {Number}
			 */
			_this.errorTime = 1000;

		};

		/**
		 * initialise.
		 */
		ex.init = function() {

			_this.window = $(window);

		};

		/**
		 * Method for extending the default options of the dialog
		 *
		 * @param  {Object} options | object containing the modal options, this would be
		 *                          | custom options for the modal, and also the jQuery
		 *                          | ui-dialog options
		 *
		 * @return {Object}         | a new options object
		 */
		_this.extendOptions = function( options ) {

			options = _.extend( {}, _this.defaults, options );

			if ( options.width ) {
				options.widthClass = " ui-dialog--with-width";
			}

			return options;

		};

		/**
		 * get the top position of the screen
		 */
		_this.getScreenTop = function() {

			_this.screenTop = _this.window.scrollTop();
			return _this.screenTop;

		};

		/**
		 * Sroll to the screen top value, or to 0.
		 */
		_this.setScreenTop = function() {

			var t = _this.screenTop || 0;
			return _this.window.scrollTop( t );

		};

		/**
		 * method to set the dialog to take up
		 * the whole viewport
		 * @param  {object} $modal | jQuery object referencing the modal dialog
		 */
		_this.fullscreenDialog = function( $modal ) {

			var h = $( window ).height() - ( _this.paddingTop * 2 ),
				t = _this.paddingTop;

			// scroll to top of the screen, because
			// certain devices struggle to do fullscreen
			// dialogs when not at top of page.
			$( window ).scrollTop( 0 );

			requestAnimationFrame( function() {

				$modal.css({ height: h, top: t });

			});

		};

		/**
		 * open / create a modal with content
		 *
		 * @param  { String / jQuery Object } 	content | A string of content, or jQuery Element
		 * @param  { Object } 					options | Options for the modal
		 * @return { jQuery Object }         	The modal reference
		 */
		ex.open = ex.create = function( content, options ) {

			var $modal = null,
				$placeholder = $("<div />"),
				fullScreen;

			// sort out the options for opening
			options = _this.extendOptions( options );
			fullScreen = ( options.style === "full" );

			LC.pubsub.trigger( "global.beforeModal", options );

			if ( content instanceof jQuery ) {

				$modal = $( content );

			} else {

				$placeholder.html( content );
				$modal = $placeholder;

			}

			// freeze the site BG if it's fullScreen style dialog
			if ( fullScreen ) {
				LC.freezebg.freeze();
			}

			// store the pre-opened modal screen top.
			_this.getScreenTop();

			$modal
				.on( "dialogopen", _this.registerCloseButtons )
				.on( "dialogopen", _this.registerOverlay )
				.on( "dialogopen", function() {

					if ( fullScreen ) {

						_this.fullscreenDialog( $modal );

					} else if ( !options.width || options.width >= window.innerWidth ) {

						// remove the width set by jQuery UI to allow
						// css to take over width setting if there's no
						// explicit width given, or the width is smaller than the window.

						$modal.parent().css( "width", "" );

					}

				})
				.on( "dialogclose", function() {
					if ( fullScreen ) {
						LC.freezebg.unfreeze();
						_this.setScreenTop();
					}
				})
				.dialog( options )
				.closest(".ui-dialog")
				.addClass(
					"opc-0 ui-dialog--" + options.style +
					" ui-dialog--" + options.name +
					options.widthClass
				);

			// fade the dialog in nicely
			requestAnimationFrame( function() {

				$modal
					.closest( ".ui-dialog" )
					.addClass("fade-in")
					.removeClass( "opc-0" );

			});

			LC.pubsub.trigger( "global.initModal", options );

			// position the window/ dialog after the modal opens,
			// if it is not a full-screen dialog.
			if ( !fullScreen ) {
				_this.setScreenTop();
				ex.positionDialog( $modal.closest(".ui-dialog") );
			}

			return $modal;

		};

		/**
		 * load an AJAX modal, which takes a URL and loads the response
		 * html in to the modal.
		 *
		 * @param  { String } url     | The URL we wish to load
		 * @param  { Object } options | An object of options for the dialog
		 * @return { Object }  		  | Object with promise and modal values
		 */
		ex.load = ex.ajax = function( url, options ) {

			var ajaxCall = null,
				ret = null,
				$modal,
				fullScreen;

			if ( url ) {

				// sort out the options for opening
				options = _this.extendOptions( options );
				fullScreen = ( options.style === "full" );

				// we don't "autoOpen" the dialog, as we need
				// it to finish the request before opening.
				options.autoOpen = false;

				LC.pubsub.trigger( "global.beforeModal", options );

				$modal =
					$("<div />")
						.on( "dialogopen", _this.registerCloseButtons )
						.on( "dialogopen", _this.registerOverlay )
						.on( "dialogopen", function() {

							if ( fullScreen ) {

								_this.fullscreenDialog( $modal );

							} else if ( !options.width || options.width >= window.innerWidth ) {

								// remove the width set by jQuery UI to allow
								// css to take over width setting if there's no
								// explicit width given, or the width is smaller than the window.

								$modal.parent().css( "width", "" );

							}

						})
						.on( "dialogclose", function() {
							if ( fullScreen ) {
								LC.freezebg.unfreeze();
							}
							_this.setScreenTop();
						})
						.dialog( options );

				LC.pubsub.trigger("preloader.open");

				ajaxCall =

					$.get( url )

						.done( function( data ) {

							// store the pre-opened modal screen top.
							_this.getScreenTop();

							// freeze the site BG if it's full style dialog
							if ( fullScreen ) {
								LC.freezebg.freeze();
							}

							$modal
								.closest(".ui-dialog")
								.addClass( "opc-0" )
								.addClass( "ui-dialog--" + options.style )
								.addClass( " ui-dialog--" + options.name )
								.addClass( options.widthClass );

							$modal.html( data );

							LC.pubsub.trigger("preloader.close");
							$modal.dialog("open");

							// fade in the modal after it's opened.
							requestAnimationFrame(function() {

								$modal
									.closest(".ui-dialog")
									.addClass( "fade-in" )
									.removeClass( "opc-0" );

							});

							LC.pubsub.trigger( "global.initModal", options );

							// position the window/ dialog after the modal opens,
							// if it is not a full-screen dialog.
							if ( !fullScreen ) {
								_this.setScreenTop();
								ex.positionDialog( $modal.closest(".ui-dialog") );
							}

						})

						.fail( function( response ) {

							$modal.dialog("destroy");
							_this.ajaxError( response, url, options );

						});

				ret = {

					promise: ajaxCall,
					modal: $modal

				};

			} else {

				LC.error( "no url provided for loading ajax" );

			}

			return ret;

		};

		/**
		 * center the dialog if it will fit inside the window
		 * with a "nice space" (padding) around the top and bottom.
		 *
		 * if it wont fit, place it ~10px from the top of the screen
		 *
		 * @param  { jQuery Object } $dialog | the dialog to adjust
		 */
		ex.positionDialog = function( $dialog ) {

			var centerPadding = 120,
				topPadding = 10,
				$window = $( window ),
				windowTop = $window.scrollTop(),
				windowHeight = $window.height(),
				dialogHeight = $dialog.outerHeight(),
				canFit = dialogHeight < ( windowHeight - centerPadding );

			if ( $dialog.length ) {

				if ( canFit ) {

					$dialog.css({

						top: ( windowTop + ( windowHeight / 2 ) ) - ( dialogHeight / 2 )

					});

				} else {

					$dialog.css({

						top: windowTop + topPadding

					});

				}

			}

		};

		/**
		 * register the overlay background panel
		 * to close the associated modal when it is clicked
		 */
		_this.registerOverlay = function() {

			var $modal = $(this),
				closes = $modal.dialog( "option", "overlayCloses" );

			if ( closes ) {

				$modal
					.closest(".ui-dialog")
					.nextAll(".ui-widget-overlay")
					.not(".ui-widget-preloader")
					.first()
					.one( "click", function() {

						ex.close( $modal );

					});

			}

		};

		/**
		 * Method to look inside the modal just opened,
		 * and bind any `.js-close-modal` elements to auto-close
		 * the modal.
		 */
		_this.registerCloseButtons = function() {

			$(".ui-dialog.ui-widget")
				.off( "click.closeModal" )
				.on( "click.closeModal", ".js-close-modal", function( e ) {

					e.preventDefault();
					ex.close( $(this) );

				});

		};

		/**
		 * close a modal, by supplying an element inside of it
		 * or the modal itself.
		 *
		 * @param  { jQuery Object } $modal  	| A Jquery Object which is inside the modal
		 */
		ex.close = ex.destroy = function( $modal ) {

			if ( !$modal ) {

				// if we didn't supply a jquery modal element, then
				// try to close the last opened modal
				$modal = $(".ui-dialog.ui-widget").last();

			} else if ( $modal.closest( ".ui-dialog.ui-widget" ).length ) {

				// otherwise, we will climb up the doc tree until
				// we find the parent dialog window, and close it.
				$modal = $modal.closest( ".ui-dialog.ui-widget" );

			}

			$modal.each( function() {

				var $thisModal = $( this );

				if ( $thisModal.is( ".ui-dialog.ui-widget" ) ) {

					LC.pubsub.trigger( "global.closeModal", $thisModal );

					$thisModal
						.children(".ui-dialog-content")
						.dialog( "close" );

				}

			});


		};

		/**
		 * close all modals currently open.
		 */
		ex.closeAll = function() {

			var $modal = $(".ui-dialog.ui-widget");

			ex.close( $modal );
			LC.pubsub.trigger("preloader.close");

		};

		/**
		 * general handling for ajax errors
		 * - if result is 404, show the not-found dialog
		 * - if the result is 409, show session timeout dialog
		 * - otherwise, show a general error message.
		 *
		 * @param  { Object } response  | server response
		 * @param  { String } url       | url of original request for retries
		 * @param  { Object } options   | object of original options for retries
		 */
		_this.ajaxError = function( response, url, options ) {

			if ( response.status >= 400 ) {

				options = options || {};

				if ( response.status === 404 ) {

					ex.notFound();

				} else if ( response.status === 409 ) {

					ex.conflict( url, options );

				} else {

					ex.generalError();

				}

			}

		};

		/**
		 * method to run when the server returns a "409" conflict
		 * @param  {String} url     | the url of the dialog we were trying to load
		 * @param  {Object} options | object of options for the dialog
		 */
		ex.conflict = function( url, options, delay ) {

			delay = ( typeof delay === "undefined" ) ? _this.errorTime : delay;

			// wait a set time before closing/loading the dialog.
			setTimeout( function() {

				// we are counting the session / server errors
				// because sometimes the AJAX will start working
				// after one failed request.

				_this.errorCount++;

				ex.closeAll();

				// if the server still returns errors
				// after multiple tries, then we show the dialog.

				if ( _this.errorCount > 1 ) {

					// open a new dialog with the
					// error message, and when it closes;
					// reload the page.

					ex.load( _this.errorPath + "session.jsp", {
						close: function() {
							LC.location.reload();
						}
					});

				} else if ( typeof url !== "undefined" ) {

					// if there was a URL provided (from a modal ajax open), then
					// we'll try to load the modal one more time.

					ex.load( url, options );

				}

			}, delay );

		};

		/**
		 * method to run for when server returns 404
		 */
		ex.notFound = function( delay ) {

			delay = ( typeof delay === "undefined" ) ? _this.errorTime : delay;

			// wait a set time before closing/loading the dialog.
			setTimeout( function() {

				ex.closeAll();

				// we are counting the session / server errors
				// because sometimes the AJAX will start working
				// after a failed request.

				_this.errorCount++;

				// try to load the error page 1 time then give
				// up. This is because it's probably server is down.

				if ( _this.errorCount <= 1 ) {

					// open the 404 error dialog
					ex.load( _this.errorPath + "404.jsp" );

				} else {

					ex.generalError();

				}

			}, delay );

		};

		/**
		 * method to run for general errors (not 404/409)
		 */
		ex.generalError = function( delay ) {

			delay = ( typeof delay === "undefined" ) ? _this.errorTime : delay;

			// wait a set time before closing/loading the dialog.
			setTimeout( function() {

				ex.closeAll();

				// we are counting the session / server errors
				// because sometimes the AJAX will start working
				// after a failed request.

				_this.errorCount++;

				// keep trying to load the error page 2 times
				// then finally give up. This is because after 2 times
				// it's probably server is down.

				if ( _this.errorCount <= 2 ) {

					// open the general error dialog
					ex.load( _this.errorPath + "general.jsp" );

				}

			}, delay );

		};

		ex.closeOnPageLoad = function() {

			ex.closeAll();
			_this.modalsClosedAtLoad = true;

		};

		/**
		 * open a modal on page load after all the modals have
		 * been closed by the popstate/pageshow event listeners
		 *
		 * ⚠️ Use this ONLY for modals that open automatically during page-load.
		 *
		 * @param  { String / jQuery Object } 	content | A string of content, or jQuery Element
		 * @param  { Object } 					options | Options for the modal
		 */
		ex.openOnPageLoad = function( content, options ) {

			var promise = new $.Deferred(),
				modal;

			if ( _this.modalsClosedAtLoad ) {

				modal = ex.open( content, options );
				promise.resolve( modal );

			} else {

				setTimeout( function() {
					ex.openOnPageLoad( content, options );
				}, 500 );

			}

			return promise;

		};

		/**
		 * open a modal on page load after all the modals have
		 * been closed by the popstate/pageshow event listeners
		 *
		 * ⚠️ Use this ONLY for modals that open automatically during page-load.
		 *
		 * @param  { String } url     | The URL we wish to load
		 * @param  { Object } options | Options for the modal
		 * @param  { Promoise } promise | Promise
		 */
		ex.loadOnPageLoad = function( url, options, promise ) {

			var firstLoad = typeof promise === "undefined",
				modal;

			// ensure promise is not overrided
			if ( firstLoad ) {

				promise = new $.Deferred();

			}

			if ( _this.modalsClosedAtLoad ) {

				modal = ex.load( url, options );
				promise.resolve( modal );

			} else {

				setTimeout( function() {
					ex.loadOnPageLoad( url, options, promise );
				}, 500 );

			}

			return promise;

		};

		ex.events = function() {

		};

		ex.subscribe = function() {

			LC.pubsub.on( "global.sessionError", function() {
				ex.conflict();
			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * freezebg helper module for freezing the background of the website
 * at specified positions
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "freezebg", function( exports, _this ) {

		var ex = exports;

		ex.init = function() {

			_this.$window = 	$( window );
			_this.$bg = 		$( "html, body" );

			_this.classes = "of-h";

		};

		// save the window position so we can go back
		// if the screen "overscroll"s
		_this.saveTop = function() {

			_this.frozen = _this.$window.scrollTop();

		};

		// set the window position back to original
		_this.setTop = function() {

			_this.$window.scrollTop( _this.frozen );

		};

		ex.freeze = function() {

			_this.$bg.addClass( _this.classes );
			_this.saveTop();

		};

		ex.unfreeze = function() {

			_this.$bg.removeClass( _this.classes );
			_this.setTop();

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));



/**
 *
 * [ module ]
 * LC.preloader
 * universal module for showing/hiding a preloader.
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "preloader", function( exports, _this ) {

		var ex = exports;

		// set up default property-values for the
		// passable options.
		_this.defaults = {

			/**
			 * withSpinner
			 * should this preloader have a "spinner" on top of it?
			 * @type {Boolean}
			 */
			withSpinner: true,
			/**
			 * spinnerType
			 * default value ("logo"/"circle") is set through LCUIConfiguration.properties
			 * which is also defined at "jsResourceBundle.jspf" in "window.lcInfo.config".
			 * Refer to "config.js" for the "LC.config" object properties which is a
			 * replacement for the old-lc style of using "lcInfo".
			 * @type {String}
			 */
			spinnerType: LC.config.getValue("ui.preloaderSpinnerType") || "logo"

		};

		ex.setup = function() {

			_this.created = false;

		};

		ex.init = function() {

			_this.$body = 			$("body");
			_this.$spinner = 		$("<div class=\"spinner spinner--dark\" />");
			_this.$spinnerLogo = 	$("#lanecrawford-logo").clone();

			_this.$overlay =
				$("<div class=\"ui-widget-overlay ui-widget-preloader / fade\" />");

			_this.logoClasses();

		};

		/**
		 * set the classes on the logoSpinner (clone) to be
		 * the same as the original logo, but with the extra loading-classes
		 */
		_this.logoClasses = function() {

			var classes = _this.$spinnerLogo.attr( "class" );

			_this.$spinnerLogo.attr({
				class: classes + " spinner-logo loading"
			});

		};

		/**
		 * Create a global preloader in the style of having
		 * a full-bg that covers the page.
		 *
		 *	{
		 *		withSpinner: Boolean,
		 *		spinnerType: String
		 *	}
		 *
		 * @param  { Object } options | Object with the properties for the preloader
		 * @return { Promise }        | Deferred to resolve after animation
		 *
		 */
		ex.create = ex.open = function( options ) {

			var settings = _.extend( {}, _this.defaults, options ),
				promise = new $.Deferred();

			// we dont want to create a preloader if there
			// already is one
			if ( !_this.created ) {

				// make sure there's no preloader open, first.
				_this.removeSpinner();
				_this.removeOverlay();

				// bind the events to this preloader
				ex.overlayEvents( settings );

				_this.created = true;

				// add spinner if needed.
				if ( settings.withSpinner ) {
					ex.addSpinner( settings );
				}

				// use opacity to smoothly animate the loader in.
				_this.$overlay
					.addClass("opc-0")
					.appendTo( _this.$body );

				// the animation frame call allows it to apply after
				// it's in the DOM, on the next animation frame, otherwise
				// it might just become opaque before the frame is drawn.
				window.requestAnimationFrame( function() {

					_this.$overlay
						.removeClass("opc-0");

					window.setTimeout( function() {
						promise.resolve();
					}, 200 );

				});

			}

			return promise;

		};

		/**
		 * add a spinner to the $overlay
		 * @param {Object} settings | object with the spinner type (logo/circle)
		 */
		ex.addSpinner = function( settings ) {

			settings = settings || _this.defaults;

			// default to circle spinner type
			var $spinner = _this.$spinner;

			// override with "logo" if it is set
			if ( settings.spinnerType === "logo" && Modernizr.cssanimations ) {
				$spinner = _this.$spinnerLogo;
			}

			// append to overlay
			_this.$overlay.append( $spinner );

		};

		/**
		 * remove any spinner from the $overlay
		 */
		_this.removeSpinner = function() {

			_this.$overlay.find( _this.$spinner ).remove();
			_this.$overlay.find( _this.$spinnerLogo ).remove();

		};

		/**
		 * remove any overlays from the DOM
		 */
		_this.removeOverlay = function() {

			_this.$overlay.remove();

		};

		/**
		 * close the overlay visible on screen
		 */
		ex.close = ex.destroy = function() {

			var promise = new $.Deferred();

			if ( _this.created ) {

				_this.$overlay.addClass("opc-0");

				setTimeout( function() {

					_this.removeSpinner();
					_this.removeOverlay();

					_this.$overlay.removeClass("opc-0");
					promise.resolve();

				}, 250 );

				_this.created = false;

			}

			return promise;

		};

		ex.subscribe = function() {

			LC.pubsub.on("preloader.open", function( e, options ) {

				ex.open( options );

			});

			LC.pubsub.on("preloader.stop", function() {

				_this.removeSpinner();

			});

			LC.pubsub.on("preloader.close", function() {

				ex.close();

			});


		};

		ex.overlayEvents = function( settings ) {

			// make sure all preloaders are removed when we navigate
			// back to the page via the "BackForwarcCache" (BFCache),
			// this is for safari mostly which will show the preloaders
			// if the page is navigated back to with window.history.back().
			window.addEventListener("popstate", function() { ex.close(); } );
			window.addEventListener("pageshow", function() { ex.close(); } );

			if ( !settings.blocking ) {

				// close the preloader when the overlay
				// is clicked on
				_this.$overlay
					.off( ".overlay" )
					.on( "click.overlay", function(e) {

						e.preventDefault();
						ex.destroy();

					});

			}

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


/**
 * lovely jquery-chainable methods
 * for animating an element to "drop" in and out,
 * allows for callbacks to fire after running.
 *
 * note: 	"dropped" elements are only hidden visually
 * 			and still take up space in the document-flow.
 *    		Should be used for animating between states, not
 *      	for hiding/showing content.
 *
 * use like:
 * 	$(".my-element").dropIn();
 * 	$(".my-element").dropOut();
 * 	$(".my-element").dropOutIn();
 */

$(function() {

	"use strict";

	var delay = 200;



	/**
	 * animate the element to be "dropping" in from
	 * slightly above it's original position.
	 *
	 * @param  { Number }   duration | duration of the animation (optional)
	 * @param  { Function } callback | function to fire after animation completes (optional)
	 * @return { $object }           | the element which animation was fired on
	 */
	$.fn.dropIn = function( duration, callback ) {

		var timing;

		// handle first argument as a function/callback
		if ( typeof duration === "function" ) {
			callback = duration;
		}

		// handle first argument as a number
		if ( typeof duration === "number" ) {
			timing = duration;
			delay = duration;
		}

		return $(this).each(function() {

			var $this = $( this );

			$this.removeClass( "an-drop-out an-drop-in go" );

			requestAnimationFrame(function() {

				$this.addClass( "an-drop-in" );

				requestAnimationFrame(function() {

					// if we supplied a new timing duration,
					// we set that transition time on the element
					if ( timing ) {
						$this.css( "transition-duration", timing + "ms" );
					}

					$this.addClass( "go" );

				});

			});

			setTimeout( function() {

				$this.css( "transition-duration", "" );

				if ( callback ) {

					callback.call( $this );

				}

			}, delay );

		});

	};




	/**
	 * animate the element to be "dropping" out from
	 * it's original position to slightly below.
	 *
	 * @param  { Number }   duration | duration of the animation (optional)
	 * @param  { Function } callback | function to fire after animation completes (optional)
	 * @return { $object }           | the element which animation was fired on
	 */
	$.fn.dropOut = function( duration, callback ) {

		var timing;

		// handle first argument as a function/callback
		if ( typeof duration === "function" ) {
			callback = duration;
		}

		// handle first argument as a number
		if ( typeof duration === "number" ) {
			timing = duration;
			delay = duration;
		}

		return $(this).each(function() {

			var $this = $( this );

			$this.removeClass( "an-drop-out an-drop-in go" );

			requestAnimationFrame(function() {

				$this.addClass( "an-drop-out" );

				// if we supplied a new timing duration,
				// we set that transition time on the element
				if ( timing ) {
					$this.css( "transition-duration", timing + "ms" );
				}

				requestAnimationFrame(function() {

					$this.addClass( "go" );

				});

			});

			setTimeout( function() {

				$this.css( "transition-duration", "" );

				if ( callback ) {

					callback.call( $this );

				}

			}, delay );

		});

	};




	/**
	 * animate the element to be "dropping" out from
	 * it's original position, then dropping back in from above
	 *
	 * @param  { Number }   duration 	| duration of the animation (optional)
	 * @param  { Function } callbackOut | function to fire after dropOut completes (optional)
	 * @param  { Function } callbackIn 	| function to fire after dropIn completes (optional)
	 * @return { $object }           	| the element which animation was fired on
	 */
	$.fn.dropOutIn = function( duration, callbackOut, callbackIn ) {

		// handle first argument as a function/callback
		if ( typeof duration === "function" ) {
			callbackIn = callbackOut;
			callbackOut = duration;
		}

		// handle first argument as a number
		if ( typeof duration === "number" ) {
			// split the duration in half for each part
			// of the animation whole.
			delay = duration / 2;
		}

		return $(this).each(function() {

			var $this = $( this );

			$this.dropOut( delay, callbackOut );

			setTimeout( function() {

				$this.dropIn( delay, callbackIn );

			}, delay );

		});

	};

});


/**
 * override the jQuery default fade-in and fade-out
 * methods to handle it with css classes, instead.
 */

$(function() {

	"use strict";

	var fadeIn = "fade-in",
		fadeOut = "fade-out",
		fadeTime = 330;

	/**
	 * method to nicely (with css) fade in an element (or set).
	 * the first argument can be a number or a callback;
	 *
	 * @param  {Function} callback | callback to run after the transition finished
	 * @param  {Number}   timing   | time in milliseconds to animate for (eg: 500)
	 * @return {Object}            | jQuery Object for the element passed in.
	 */
	$.fn.fadeInNicely = function( callback, timing ) {

		var fadeInTime = fadeTime;

		if ( typeof callback !== "function" ) {
			if ( typeof callback === "number" ) {
				fadeInTime = callback;
			}
			callback = undefined;
		}

		if ( typeof timing === "number" ) {
			fadeInTime = timing;
		}

		return $( this ).each( function() {

			var $this = $(this),
				delay = fadeInTime + 10;

			// first we make sure the element
			// is not visible, and non-animated state
			$this
				.addClass( "opc-0" )
				.removeClass( fadeIn )
				.removeClass( fadeOut )
				.removeClass( "hide" )
				.css( "transition", "none" );

			// then after a animation tick, we
			// fade it in nicely over the given time.
			requestAnimationFrame( function() {

				$this
					.addClass( fadeIn )
					.removeClass( "opc-0" )
					.css({
						"transition": "",
						"transition-duration": fadeInTime + "ms"
					});

			});

			// run the callback function if we can,
			// and remove the transition css property.
			setTimeout( function() {

				$this.css( "transition-duration", "" );

				if ( callback ) {
					callback.call( $this );
				}

			}, delay );

		});

	};

	/**
	 * method to nicely (with css) fade out an element (or set).
	 * the first argument can be a number or a callback;
	 *
	 * @param  {Function} callback | callback to run after the transition finished
	 * @param  {Number}   timing   | time in milliseconds to animate for (eg: 500)
	 * @return {Object}            | jQuery Object for the element passed in.
	 */
	$.fn.fadeOutNicely = function( callback, timing ) {

		var fadeOutTime = fadeTime;

		if ( typeof callback !== "function" ) {
			if ( typeof callback === "number" ) {
				fadeOutTime = callback;
			}
			callback = undefined;
		}

		if ( typeof timing === "number" ) {
			fadeOutTime = timing;
		}

		return $(this).each(function() {

			var $this = $( this ),
				delay = fadeOutTime + 10;

			// first check the element is not already
			// hidden, as we dont want to animate if it is.
			if ( !$this.hasClass( "hide" ) ) {

				// then we add the fade out class
				// and the correct transition time, and fade it out.
				$this
					.removeClass( fadeIn )
					.addClass( fadeOut )
					.addClass( "opc-0" )
					.css( "transition-duration", fadeOutTime + "ms" );

				// then after the transition has finished
				// we set the element to be hidden and remove
				// the fade classes.
				setTimeout( function() {

					$this
						.addClass( "hide" )
						.removeClass( fadeOut )
						.removeClass( "opc-0" );

				}, delay );

			} else {

				delay = 10;

			}

			// run the callback function if we can,
			// and remove the transition css property.
			setTimeout( function() {

				$this.css( "transition-duration", "" );

				if ( callback ) {
					callback.call( $this );
				}

			}, delay );

		});

	};

});


/**
 * lovely jquery-chainable methods
 * for animating slide up and slide down
 *
 * uses css animations (an-slide) for controlling
 * the sliding animation. Prefer to use this over $.fn.slideUp()
 * or $.fn.slideDown() for showing/hiding elements with slide.
 *
 * note: 	The element we're animating must be
 * 			displayed as a block: "display: block;"
 */

$(function() {

	"use strict";

	/**
	 * default time based on the
	 * default speed of an-slide css class.
	 * @type {Number}
	 */
	var defaultTime = 330,

		/**
		* safety is a small delay to allow
		* animation to finish before executing code
		* @type {Number}
		*/
		safety = 15;




	/**
	 * animates the element opening (sliding) nicely using
	 * css animations to reveal it's content. (must be a block)
	 *
	 * @param  { Number }   duration | length of animation in ms (optional)
	 * @param  { Function } callback | function to fire after animation completes (optional)
	 * @return { $object }           | element the animtion fired on
	 */
	$.fn.slideDownNicely = function( duration, callback ) {

		var timing = defaultTime;

		/**
		 * allow the first parameter to be either
		 * a callback function or a duration value.
		 */
		if ( typeof duration === "function" ) {

			callback = duration;
			duration = defaultTime;

		}

		return $( this ).each( function() {

			var $this = $( this ),
				$clone = $this.clone(),
				originalCss = $this.attr("style") || "",
				height, marTop, marBot, padTop, padBot;

			/**
			 * "duration" is used for the css animation
			 * "timing" is used for the js timeout()
			 */
			if ( typeof duration === "number" ) {
				timing = duration;
			}

			/**
			 * use a cloned element, which is hidden
			 * and only exists momentarily to get the
			 * final height / margins of the element.
			 *
			 * This is because we cannot get the height
			 * of a hidden element, and we need the height
			 * to be able to animate with css.
			 */
			$clone
				.css({
					display: "block",
					opacity: "0.001"
				})
				.appendTo( $this.parent() );

			height = $clone.dimensions().height;
			marTop = $clone.css("margin-top");
			marBot = $clone.css("margin-bottom");
			padTop = $clone.css("padding-top");
			padBot = $clone.css("padding-bottom");
			$clone.remove();

			/**
			 * we want to remove all heights/margins from
			 * the animating element and set it to be
			 * visible, so that we can animate it.
			 */
			$this
				.addClass( "of-h" )
				.css({
					"display": "block",
					"height": 0,
					"margin-top": 0,
					"margin-bottom": 0,
					"padding-top": 0,
					"padding-bottom": 0
				});

			/**
			 * we wait a fraction of a second (safety) before
			 * triggering the animation as we need to make sure we
			 * have had time to reset the css values.
			 */
			setTimeout( function() {

				/**
				 * Setting the "transition-duration" just before adding
				 * class "an-slide" is because of safari 9.0 bug
				 *
				 * After setting "transition-duration", safari 9.0 will actually
				 * add whole "-webkit-transition" attribute to the element.
				 * So if "transition-duration" is set as 1000ms, when setting
				 * height, it will triggers a 1000ms amimation and it will causes
				 * interference with the later slide up / down animation in Safari 9.0
				 */

				$this.css( "transition-duration", duration + "ms" );

				/**
				 * add the slide classes, if they were not
				 * already present, and animate the height/margin.
				 */
				$this
					.addClass("an-slide")
					.css({
						"height": height,
						"margin-top": marTop,
						"margin-bottom": marBot,
						"padding-top": padTop,
						"padding-bottom": padBot
					});

				/**
				 * we now wait for the animation to finish
				 * before executing the next block of code.
				 */
				setTimeout( function() {

					/**
					 * remove the animation class,
					 * reset the css back to it's original state
					 * and make sure the element is visible.
					 */
					$this
						.removeClass("an-slide")
						.attr( "style", originalCss )
						.css( "display", "block" );

					/**
					 * execute any callback function that exists
					 */
					if ( callback ) {
						callback.call( $this );
					}

				}, safety + timing );

			}, safety );

		});

	};




	/**
	 * animates the element closing (sliding) nicely using
	 * css animations to hide it's content. (must be a block)
	 *
	 * @param  { Number } duration   | length of animation in ms (optional)
	 * @param  { Function } callback | function to fire after animation completes (optional)
	 * @return { $object }           | element the animtion fired on
	 */
	$.fn.slideUpNicely = function( duration, callback ) {

		var timing = defaultTime;

		/**
		 * allow the first parameter to be either
		 * a callback function or a duration value.
		 */
		if ( typeof duration === "function" ) {

			callback = duration;
			duration = defaultTime;

		}

		return $( this ).each( function() {

			var $this = $( this ),
				height = $this.height(),
				originalCss = $this.attr("style") || "";

			/**
			 * "duration" is used for the css animation
			 * "timing" is used for the js timeout()
			 */
			if ( typeof duration === "number" ) {
				timing = duration;
			}

			/**
			 * make sure the sliding-element has an explicit
			 * height set (because cannot animate "auto" height in css)
			 */
			$this.css( "height", height );

			/**
			 * we wait a fraction of a second (safety) before
			 * triggering the animation as we need to make sure we
			 * have had time to reset the css values.
			 */
			setTimeout( function() {

				/**
				 * Setting the "transition-duration" just before adding
				 * class "an-slide" is because of safari 9.0 bug
				 *
				 * After setting "transition-duration", safari 9.0 will actually
				 * add whole "-webkit-transition" attribute to the element.
				 * So if "transition-duration" is set as 1000ms, when setting
				 * height, it will triggers a 1000ms amimation and it will causes
				 * interference with the later slide up / down animation in Safari 9.0
				 */

				$this.css( "transition-duration", duration + "ms" );

				/**
				 * make sure the element as the sliding class,
				 * and then set all the properties to 0, which
				 * will trigger the smooth animation.
				 */
				$this
					.addClass( "an-slide of-h" )
					.css({
						"height": "0px",
						"margin-top": "0px",
						"margin-bottom": "0px",
						"padding-top": "0px",
						"padding-bottom": "0px"
					});

				/**
				 * we now wait for the animation to finish
				 * before executing the next block of code.
				 */
				setTimeout( function() {

					/**
					 * remove the animation class,
					 * reset the css back to it's original state
					 * and make sure the element is hidden.
					 */
					$this
						.removeClass("an-slide of-h")
						.attr( "style", originalCss )
						.css( "display", "none" );

					/**
					 * execute any callback function that exists
					 */
					if ( callback ) {
						callback.call( $this );
					}

				}, safety + timing );

			}, safety );

		});

	};

});

/**
 *
 * A jquery component to bind a "back to top" arrow in the page
 *
 * The HTML for the "back to top" icon needs to exist in the page
 * for this to work. The icon can be added easily to the HTML
 * using the JSP tagfile;
 *
 * 		<lcui:back-to-top />
 *
 */

$.fn.backToTop = function() {

	"use strict";

	return $( this ).each( function() {

		var $arrow = $( this ),
			href = $arrow.attr( "href" ),
			$target = $( href );

		if ( !$target.length ) {
			$target = $( "body" );
		}

		// animate the page scroll back to the target
		// when we click on the arrow
		$arrow.on( "click.backToTop", function( e ) {

			e.preventDefault();

			var top = $target.offset().top - 50;
			$( "html,body" ).animate({ scrollTop: top }, 500 );

		});

		// toggle the visibilty of the arrow depending on whether
		// the browser has scrolled past the "target" top
		LC.pubsub.on( "windowScroll", function( e, data ) {

			var pastTop = data.windowTop > $target.offset().top;
			$arrow.toggleClass( "opc-0", !pastTop );

		});

	});

};


$(function() {

	"use strict";

	// init the help icons
	LC.pubsub
		.off("bindBackToTop")
		.on("bindBackToTop", function( e, data ) {

			$("#back-to-top").backToTop();

		}).trigger("bindBackToTop");

});

/**
 * perform a lazy-loading animation on any color-chips
 * which have a url image background which improves load
 * times, and makes the loading appear nicer.
 *
 * for the effect to work, the html needs to look like below;
 *
 * 	<label class="lc-label lc-label--colour / lc-label--loading / fade-in opc-0"
 * 		data-lazy="http://media.lanecrawford.com/colorimg/XRH613_swatch.jpg" title="CHERRY RED">
 * 	</label>
 *
 * the key css classes which allow this effect to work are: "lc-label--loading" which
 * is what the script uses to detect if it should perform the effect, and "fade-in opc-0"
 * which are used for the animation.
 *
 */


$.fn.colorChipLoader = function() {

	"use strict";

	$( this ).each( function() {

		var $chip = $( this ),
			colorImage = $chip.data( "lazy" ),
			imageToLoad = new Image();

		if ( !colorImage ) {
			return this;
		}

		imageToLoad.onload = function() {

			// after the image has lazy-loaded, we
			// add the background image to the chip and then
			// remove the opacity (which triggers fade animation)
			$chip.css( "background-image", "url(" + colorImage + ")" )
				.removeClass("lc-label--loading opc-50");

			setTimeout( function() {

				// remove all fade css classes just in case,
				// we need to remove these so that the normal
				// box-shadow animation can still work after the
				// chip has loaded.
				$chip.removeClass( "fade fade-in fade-out" );

			}, 500 );

		};

		imageToLoad.onerror = function() {

			$chip.removeClass( "lc-label--loading" );
			LC.warn("color chip image not found: " + colorImage );

		};

		imageToLoad.src = colorImage;

		return this;

	});

	return this;

};


/**
 * handle loading any "aysnc load" color
 * chips as the page is rendering.
 */
$(function() {

	"use strict";

	LC.pubsub

		.on( "bindChipLoader global.openmodal", function( e, data ) {

			var $scope = $("body");

			if ( data && typeof data.scope !== "undefined" ) {
				$scope = data.scope;
			}

			$scope
				.find( ".lc-label--loading" )
				.colorChipLoader();

		})

		.trigger( "bindChipLoader" );

});


/* eslint */

/**
 * A component for binding dropdown-list style functionality
 * to a DOM object, the DOM should look like:
 *
 *      <div class="lc-dropdown">
 *      	<div class="lc-dropdown__display">
 *      		<span class="lc-dropdown__display-text">
 *      			Selected
 *      		</span>
 *      		<i class="lc-dropdown__icon / lc-icon" data-icon="arrow-down-thick"></i>
 *      	</div>
 *
 *      	<ul class="lc-dropdown__list">
 *      		<li class="lc-dropdown__list-item / is-selected">Selected</li>
 *      		<li class="lc-dropdown__list-item">Not Selected</li>
 *      		<li class="lc-dropdown__list-item">Also Not Selected</li>
 *      	</ul>
 *
 *      </div>
 *
 * It's possible to mimic the style of a <select></select> by applying the
 * css class "lc-dropdown--button-style" to the main element:
 *
 * 		<div class="lc-dropdown / lc-dropdown--button-style">
 * 			...
 * 		</div>
 *
 */
( function( LC, $ ) {

	"use strict";

	// initialise the components object,
	// if it doesn't already exist.
	LC.Components = LC.Components || {};

	// initialise the Dropdown component constructor,
	// used for prototyping dropdowns.
	LC.Components.Dropdown = function( $el, options ) {

		var _this = this;

		/**
		 * Default options for the dropdown component
		 * @type {Object}
		 */
		_this.defaults = {

			openClass: 			"is-open",
			activeClass: 		"is-active",
			selectedClass: 		"is-selected",
			namespace: 			_.uniqueId("lcDropdown.")

		};

		/**
		 * manipulateText is a method which allows string-manipulation
		 * of the text returned when a user selects a dropdown
		 * element. This essentially allows anything to be displayed
		 * in the dropdown "display" area.
		 *
		 * @param  { String } text | the string returned from the dropdown item
		 * @return { String }      | the manipulated string.
		 */
		_this.defaults.manipulateText = function( text ) {
			return text.trim();
		};

		// we can't do anything without a valid
		// element to initialise on.
		if ( !$el || !$el.length ) {
			LC.warn( "cannot 'Dropdown' when there's nothing to 'Dropdown'." );
			return false;
		}

		_this.isOpen = 		false;
		_this.options = 	_.extend( {}, _this.defaults, options );

		_this.$dropdown = 	$el;
		_this.$body = 		$("body");
		_this.$display = 	_this.$dropdown.children(".lc-dropdown__display");
		_this.$list = 		_this.$dropdown.children(".lc-dropdown__list");
		_this.$text = 		_this.$display.find(".lc-dropdown__display-text");
		_this.$arrow = 		_this.$display.find(".lc-dropdown__icon");
		_this.$default = 	_this.$list.find( "." + _this.options.selectedClass ).first();

		// useful object for storing event names
		_this.events 		= {};
		_this.events.click	= "click." + _this.options.namespace;

		// set the default state based on how the
		// dropdown currently looks.
		_this.defaultState = {

			text:  _this.options.manipulateText(_this.$text.html() ),
			originalText: _this.$text.html(),
			$selected: _this.$default

		};

		return _this;

	};

	// some variables for making access easier.
	var Dropdown = LC.Components.Dropdown,
		_proto = Dropdown.prototype;


	/**
	 * initialise the component
	 */
	_proto.init = function() {

		var _this = this;

		_this.setDefault();
		_this.bind();
		_this.initialClasses();

		_this.$dropdown.trigger( "init.dropdown", _this.state );

	};

	_proto.setDefault = function() {

		var _this = this;

		// set the initial state
		_this.state = _.extend( {}, _this.defaultState );

		// if something is default to selected, then select it.
		if ( _this.defaultState.$selected.length ) {

			_this.selectItem( _this.defaultState.$selected );

		} else {

			_this.setText( _this.defaultState.text );

		}

	};

	/**
	 * set some initial css classes on elements
	 */
	_proto.initialClasses = function() {

		var _this = this;

		_this.$dropdown.toggleClass( _this.options.activeClass, false );
		_this.$list.toggleClass( _this.options.activeClass, false );

		_this.$arrow
			.toggleClass( "an-r-180", true )
			.toggleClass( "go", false );

	};

	/**
	 * get the children of the list element
	 * who are actually list items and not disabled
	 * @return  {$object}  jquery object containing children of list
	 */
	_proto.getItems = function() {

		var _this = this;
		return _this.$list.children( ".lc-dropdown__list-item" ).not( "[disabled]" );

	};

	/**
	 * open the dropdown list, this will
	 * simply add css classes for opening.
	 */
	_proto.open = function() {

		var _this = this;

		if ( !_this.isOpen ) {

			_this.isOpen = true;
			_this.$list.addClass( _this.options.openClass );
			_this.$dropdown.addClass( _this.options.activeClass );
			_this.$arrow.addClass( "go" );
			_this.lazyBind();

			_this.$dropdown.trigger( "open.dropdown", _this.state );

		}


	};

	/**
	 * close the dropdown list.
	 */
	_proto.close = function() {

		var _this = this;

		if ( _this.isOpen ) {

			_this.isOpen = false;
			_this.$list.removeClass( _this.options.openClass );
			_this.$dropdown.removeClass( _this.options.activeClass );
			_this.$arrow.removeClass( "go" );
			_this.lazyUnbind();

			_this.$dropdown.trigger( "close.dropdown", _this.state );

		}


	};

	/**
	 * bind events to the body and list
	 * when it is opened.
	 */
	_proto.lazyBind = function() {

		var _this = this;

		// unbind any existing events.

		_this.lazyUnbind();

		// after an item in the menu was clicked,
		// we want to set it's state as selected and
		// also update the text on the display area.

		_this.getItems()
			.on( _this.events.click, function( e ) {

				e.preventDefault();
				var $selected = $(this);

				_this.selectItem( $selected );
				_this.$dropdown.trigger( "select.dropdown", _this.state );
				_this.close();

			});

		// bind an event on the body to handle
		// when user clicks outside of the
		// dropdown area, we want to close the dropdown
		_this.$body
			.on( _this.events.click, function( e ) {

				var $target = $( e.target );

				// if the target was not the display area,
				// and the display area doesn't have the target
				// as a child... then close the dropdown!
				if (
					!$target.is( _this.$display ) &&
					!$.contains( _this.$display[ 0 ], e.target )
				) {

					_this.close();

				}

			});

	};

	/**
	 * unbind the events bound in lazyBind()
	 */
	_proto.lazyUnbind = function() {

		var _this = this;

		_this.$body.off( _this.events.click );
		_this.$list.children().off( _this.events.click );

	};

	/**
	 * set the text on the "display" label
	 * @param { String } text | a string of text to use
	 */
	_proto.setText = function( text ) {

		var _this = this,
			newText;

		if ( typeof text === "undefined" ) {

			text =
				_this.getItems()
					.filter( "." + _this.options.selectedClass )
					.text();

		}

		newText = _this.options.manipulateText( text );
		_this.$text.html( newText );

		_this.state.originalText = text;
		_this.state.text = newText;

	};

	/**
	 * Method for returning the state of the
	 * dropdown list
	 * @return { Object } | the states of the dropdown
	 */
	_proto.getState = function() {

		var _this = this;

		return _this.state;

	};

	/**
	 * select the given item from the list of items
	 * @param  { $ } $item 	| the jQuery DOM reference to the item we want to select
	 */
	_proto.selectItem = function( $item ) {

		var _this = this;

		_this.getItems()
			.removeClass( _this.options.selectedClass );

		_this.$list
			.find( $item )
			.addClass( _this.options.selectedClass );

		_this.state.$selected = $item;

		_this.setText( $item.text() );

	};

	/**
	 * bind the event for opening the dropdown and
	 * closing it
	 */
	_proto.bind = function() {

		var _this = this;

		_this.unbind();

		_this.$display
			.on( _this.events.click, function( e ) {

				e.preventDefault();

				if ( _this.isOpen ) {

					_this.close();

				} else {

					_this.open();

				}

			});

	};

	/**
	 * remove the event bindings set up at init
	 */
	_proto.unbind = function() {

		var _this = this;

		_this.$display.off( _this.events.click );

	};

	/**
	 * destroy the dropdown
	 */
	_proto.destroy = function() {

		var _this = this;

		_this.unbind();
		_this.$dropdown.removeData( "lcDropdown" );
		_this = null;

	};




	/**
	 * Use a $.fn... binding to apply this component
	 * directly to a DOM element.
	 * @param  { Object } options  	| Object with options properties.
	 * @return { $ }         		| returns the jQuery chainable.
	 */
	$.fn.lcDropdown = function( options ) {

		var $this = $(this);

		return $this.each( function( k, el ) {

			var $el = $( el ),
				dropdown;

			// assign the prototype to a data-attribute for
			// access later on.
			dropdown = new LC.Components.Dropdown( $el, options );
			dropdown.init();

			$el.data( "lcDropdown", dropdown );

		});

	};

}( window.LC = window.LC || {}, jQuery ));




/**
 *
 * finally, bind all ".lc-dropdown" elements to
 * the lcDropdown component, and set up a global
 * event to re-bind them if needed.
 *
 */

$(function() {

	"use strict";

	LC.pubsub.on("bindDropdowns global.openmodal", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".lc-dropdown" )
			.lcDropdown();

	}).trigger("bindDropdowns");

});

/**
 *
 * A jquery component to bind help icons to help panels/popups.
 * Popups/Panels and Links need to be in a correct oder in the
 * page, as it uses their navigable indexs to reference the links.
 *
 * basic usage should look like;
 *
 * 	<button
 *    type="button"
 *    id="x"
 *    data-icon="help"
 * 	  class="lc-help-panel-link / lc-icon / txt-20"></button>
 *
 *	<div data-for="x" class="lc-help-panel / pad-10 bg-very-light txt-14">
 *	  Content of the Help Panel
 *	</div>
 *
 */



$.fn.lcHelpicon = function() {

	"use strict";

	return $(this).each( function() {

		var $this = $( this );

		$this
			.off(".helpicon")
			.on("mouseenter.helpicon mouseleave.helpicon click.helpicon", function( e ) {

				e.preventDefault();

				var links = ".lc_hint_panel_link, .lc-help-panel-link",
					targets = ".lc_hint_popup, .lc_hint_panel, .lc-help-popup, .lc-help-panel",

					$links = $( links ),
					i = $links.index( $this ),
					$target = $( targets ).eq( i ),
					dims = $this.dimensions(),
					popup = $this.data("popup"),
					anchor = $this.data("anchor"),
					position = {
						left: 0,
						center: -(( $this.data("width") * 0.5 ) - ( dims.width ) + 1 ),
						right: -( $this.data("width") - ( dims.width ) )
					};


				// if it's a popup, we want to position the popup correctly
				if ( popup && e.type !== "click" ) {

					$target.css({

						position: "absolute",
						width: $this.data("width"),
						left: ( anchor ) ? position[ anchor ] : position.center

					}).fadeToggle();

				// if it is a inline box, we want to open it on the correct event
				// and close it on the correct one.
				} else if ( !popup && e.type === "click" ) {

					$target.slideToggle();

				}

			});

	});

};

$(function() {

	"use strict";

	// init the help icons
	LC.pubsub.on("bindHelpicons global.initModal", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = $( data.scope );
		}

		$scope
			.find(".lc_hint_panel_link, .lc-help-panel-link")
			.lcHelpicon();

	}).trigger("bindHelpicons");

});


/* eslint */

/**
 * Apply uniform handling for spellcheck/autocorrect
 * on all input fileds across the site.
 */

$.fn.lcInputAttributes = function() {

	"use strict";

	return $( this ).each( function( i, el ) {

		var $input = $( el );

		if ( $input.is(":input") ) {

			$input.attr({

				spellcheck: false,
				autocorrect: "off"

			});

		}

	});

};

$(function() {

	"use strict";

	LC.pubsub.on("global.openmodal global.inputAttributes", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".lc-field__input, .lc-field__textarea" )
			.not(".lc-field__fake")
			.lcInputAttributes();

	}).trigger("global.inputAttributes");

});


/**
 * A helper is using to count the lenght of input and then
 * update the corresponding area with the input length
 *
 *
 *	.js-count-char		: 	trigger the counting char function
 *	.js-count-display	: 	name of the class for displaying the length of input
 * 	#inputData			: 	using to define the data attribute as <dsp:textarea> does not support data attribute
 *
 *  Code Example:
 *
 *  <textarea
 * 		id="x"
 * 		class="js-count-char">
 * 	</textarea>
 * 	<span id="inputData" data-update-count-class="js-count-display" class="hide">
 *
 * 	<input
 * 		type="text"
 * 		id="x"
 * 		class="js-count-char"
 * 		updateCountClass="js-count-display" >
 *
 *	<dsp:textarea iclass="js-count-char">
 *	</dsp:textarea>
 *	<span id="inputData" data-update-count-class="js-count-display" class="hide">
 *	<p><span class="js-count-display">0</span>/240</p>
 *
 *
 * 	<dsp:input type="text" class="js-count-char">
 *   <dsp:tagAttribute name="updateCountClass" value="js-count-display" />
 *	</dsp:input>
 * 	<p><span class="js-count-display">0</span>/240</p>
 *
 *
 *	text area need extra <span> because
 *	- <dsp:textarea> doesn't support data attribute
 *	- <dsp:tagAttribute> is only support <dsp:input> and <dsp:select>
 *	Ref: https://docs.oracle.com/cd/E26180_01/Platform.94/PageDevGuide/html/s1234dsptagattribute01.html
 *
 */

$.fn.countingLength = function() {

	"use strict";

	var countingChar, updateInputCount;

	countingChar = function( $input, updateClass ) {

		var $inputLength = $input.val().length;

		updateInputCount( $input, $inputLength, updateClass );

	};

	updateInputCount  = function( $input, $inputLength, updateClass ) {

		$input.parent().find( "." + updateClass ).text( $inputLength );

	};

	return $( this ).each( function( i, el ) {

		var $input = $( el ),
			updateClass = $input.parent().find("#inputData").data("updateCountClass") ||
							$input.attr( "updateCountClass" ) ||
							$input.data("updateCountClass");


		if ( !updateClass ) {

			LC.warn("attempted to run $.fn.inputCountLength on field with no updateClass ");

			return;

		} else {

			/*
				apply the countingChar fn once the updateClass is ready. That is for
				handling the field with content once onLoad.
			 */
			countingChar( $input, updateClass );

		}

		$input
			.off(".countInputLength")
			.on("change.countInputLength keyup.countInputLength", function( e ) {

				countingChar( $input, updateClass );
				e.preventDefault();

			});


	});

};

$(function() {

	"use strict";

	LC.pubsub

		.on( "countInputLength", function( e, data ) {

			var $scope = $("body");

			if ( data && typeof data.scope !== "undefined" ) {
				$scope = data.scope;
			}

			$scope
				.find( ".js-count-char" )
				.countingLength();

		})

		.trigger( "countInputLength" );

});

/**
 * A helper is using to count the number of line break if
 * reached the limitation
 *
 * 	.js-count-line		: using to trigger the line break counting
 *  attribute lineMax 	: define the line limtation
 *  data-line-Max 		: define the line limtation
 *  #inputData			: using to define the data attribute as <dsp:textarea> does not support data attribute
 *
 *  Code Example:
 *
 *  <textarea
 * 		id="x"
 * 		class="js-count-line">
 * 	</textarea>
 * 	<span id="inputData" data-line-max="10" class="hide">
 *
 * 	<input
 * 		type="text"
 * 		id="x"
 * 		class="js-count-line"
 * 		lineMax="10" >
 *
 *	<dsp:textarea iclass="js-count-line">
 *	</dsp:textarea>
 *	<span id="inputData" data-line-max="10" class="hide">
 *
 *
 * 	<dsp:input type="text" class="js-count-line">
 *   <dsp:tagAttribute name="lineMax" value="10" />
 *	</dsp:input>
 *
 *	text area need extra <span> because
 *	- <dsp:textarea> doesn't support data attribute
 *	- <dsp:tagAttribute> is only support <dsp:input> and <dsp:select>
 *	Ref: https://docs.oracle.com/cd/E26180_01/Platform.94/PageDevGuide/html/s1234dsptagattribute01.html
 *
 */


$.fn.countingLineBreak = function() {

	"use strict";


	$( this ).each( function() {

		var $input = $( this );

		$input
			.off(".countInputLine")
			.on("change.countInputLine keydown.countInputLine", function( e ) {

				var linesUsed = $input.val().split( "\n" ).length,
					maxLines = $input.parent().find("#inputData").data("lineMax" ) ||
								$input.attr( "lineMax" ) ||
								$input.data("lineMax");

				// if lines break is equal or greater than the limiation
				// and the key down is "Enter"
				// will return false to prohibit any more keydown action

				if ( linesUsed >= maxLines && e.keyCode === 13 ) {

					return false;

				}

				return this;

			});


	});

};

$(function() {

	"use strict";

	LC.pubsub

		.on( "countInputLine", function( e, data ) {

			var $scope = $("body");

			if ( data && typeof data.scope !== "undefined" ) {
				$scope = data.scope;
			}

			$scope
				.find( ".js-count-line" )
				.countingLineBreak();

		})

		.trigger( "countInputLine" );

});


/**
 * A helper for lazy-loading any <img> element by replacing
 * the src with the `data-lazy` while it's downloading.
 *
 * if the images we choose to lazy-load are actually
 * product images ( like: http://media.lanecrawford.com/B/X/L/BXL495_in_l.jpg )
 * then this will try to do a nice blurry-load effect on those
 * images.
 */

$.fn.lazyImage = function( options ) {

	"use strict";

	var loadImg,
		loadProductWithBlur,
		isProduct,
		parentPositioned,
		defaults = {
			extraBlurry: false
		};

	options = $.extend( {}, defaults, options );


	/**
	 * Replaces the image source with a spinner, then will
	 * wait for the image to load, and finally display the
	 * image defined in `data-lazy`
	 *
	 * 	  <img
	 * 	  	class="lazy-load" src=""
	 * 	  	data-lazy="/path/to/image.jpg" >
	 *
	 * @param  { jQuery Object } $el 	| The element we wish to replace the image for
	 * @param  { String } img  			| The image source we wish to show eventually
	 */
	loadImg = function( $el, img ) {

		var $mommy = $el.parent(),
			removeSpinner,
			applyImage,
			imageToLoad = new Image(),
			size = $el.data("spinner-size"),
			$spinner = $mommy.children( ".spinner" );

		// make sure the image we're lazy-loading is
		// not visible
		$el.addClass("opc-0");

		// if there's no spinner in the container
		// of the image, then we need to create one
		if ( !$spinner.length ) {

			$spinner = $("<div class=\"spinner fade-out\" />");

			if ( size ) {
				$spinner.addClass( "spinner--" + size );
			}

			$spinner.appendTo( $mommy );

		}

		imageToLoad.onload = function() {

			applyImage( img );

			removeSpinner();

		};

		imageToLoad.onerror = function() {

			var notFoundImage = $el.data("notfound-img");

			if ( typeof notFoundImage !== "undefined" ) {

				applyImage( notFoundImage );

			}

			removeSpinner();

		};

		applyImage = function( imgUrl ) {

			// after the image has loaded, fade it in.
			// Also tag it as loaded and trigger an event
			// we can listen for.
			$el
				.attr( "src", imgUrl )
				.removeClass( "js-lazy lazy-load blur-load" );

			// need to use a timeout, otherwise the
			// data/css classes are applied before a
			// screen paint, meaning no animation occurs.
			setTimeout(function() {

				$el
					.removeClass( "opc-0" )
					.data( "lazy-loaded", true )
					.trigger( "lazyloaded" );

			}, 10 );

		};

		removeSpinner = function() {

			// hide the spinner
			$spinner.addClass("opc-0");

			// remove spinner after it's finished animating
			// and trigger an event we can listen for
			setTimeout( function() {

				$spinner.remove();
				$el.removeClass( "lazy-load blur-load" );
				$el.trigger( "spinnergone" );

			}, 600);

		};

		imageToLoad.src = img;

	};

	/**
	 * Replaces the product image with a `xs` version and blurs
	 * it, then after the image has loaded, it will fade the image in.
	 * this method requires the parent to have position, and the image
	 * to be a product image.
	 *
	 * 	  <img
	 * 	  	class="blur-load"
	 * 	  	src="http://media.lanecrawford.com/B/X/L/BXL495_in_xs.jpg"
	 * 	  	data-lazy="http://media.lanecrawford.com/B/X/L/BXL495_in_l.jpg" >
	 *
	 * @param  { jQuery Object } $el 	| The element we wish to replace the image for
	 * @param  { String } img  			| The image source we wish to show eventually
	 *
	 */
	loadProductWithBlur = function( $el, img ) {

		var imageToLoad = new Image(),
			fadeOutBlurry,
			revealProductImage,
			showComingSoonImage,
			xs = LC.format.productImage( img, "xs" ),
			notFoundImage = $el.data("notfound-img"),
			delay = 100,
			transition = 400,
			blurClass = "blur-load",

			// blurry is a small, blurry image that sits
			// on top of the original, and eventually fades out
			// to reveal the original image.
			$blurry = $el.clone(),
			$spinner = $el.siblings(".spinner"),
			hasSpinner = $spinner.length > 0;

		// extra blurry can be used for larger product images
		if ( options.extraBlurry ) {
			blurClass += " extra-blurry";
		}

		// append the blurry image and position it
		// properly with teh correct source
		$blurry
			.attr( "src", xs )
			.addClass( blurClass + " pos-a t-0 l-0 r-0 b-0 mar-a" )
			.appendTo( $el.parent() );

		/**
		 * method to reveal the original image once it has
		 * loaded, this should fade out the smaller, blurry
		 * image to reveal.
		 */
		revealProductImage = function() {

			// set the source to the large image,
			// remove the blurry css class, and trigger
			// an event we can listen to, if needed.
			$el
				.attr( "src", img )
				.removeClass( blurClass + " js-lazy lazy-load blur-load" )
				.data( "lazy-loaded", true )
				.trigger( "lazyloaded" );

			fadeOutBlurry();

		};

		showComingSoonImage = function() {

			$el
				.attr( "src", notFoundImage )
				.removeClass( blurClass + " js-lazy lazy-load blur-load" )
				.data( "lazy-loaded", true )
				.trigger( "lazyloaded" );

			fadeOutBlurry();

		};

		fadeOutBlurry = function() {
			// after s short delay...
			setTimeout( function() {

				// try to fade-out the spinner
				if ( hasSpinner ) {
					$spinner.addClass("opc-0");
				}

				// fade out the small, blurry
				// image that sits on top....
				$blurry.addClass("opc-0");

				// after the spinner and blurry image
				// has faded out...
				setTimeout( function() {

					// remove the spinner
					if ( hasSpinner ) {
						$spinner.remove();
					}

					// remove the blurry image, and trigger
					// an event on the large image we
					// can listen to if needed
					$blurry.remove();
					$el.trigger( "blurgone" );

				}, transition );

			}, delay );
		};

		imageToLoad.onload = revealProductImage;
		imageToLoad.onerror = showComingSoonImage;
		imageToLoad.src = img;

		// test for cache, this is used to let
		// us shorten the animation when the image
		// already exists in cache
		if ( imageToLoad.complete || imageToLoad.width + imageToLoad.height > 0 ) {

			$blurry.css({
				"-webkit-transition-duration": "200ms",
				"-moz-transition-duration": "200ms",
				"-ms-transition-duration": "200ms",
				"transition-duration": "200ms"
			});

			delay = 10;
			transition = 250;

		}

	};

	/**
	 * test if given image string is a product-image
	 *
	 * @param  { String }  img | Image url to test
	 * @return { Boolean }     | returns the result of test.
	 */
	isProduct = function( img ) {

		var regex = LC.regex.productImage;
		return regex.test( img, "i" );

	};

	/**
	 * test if the parent of the given element has
	 * positioning or not.
	 *
	 * @param  { jQuery Object } $el 	| the element we want to check the parent for
	 * @return { Boolean }     			| whether the parent has position rel/abs.
	 */
	parentPositioned = function( $el ) {

		var $parent = $el.parent(),
			pos = $parent.css("position");

		return pos === "relative" || pos === "absolute" ||
			$parent.hasClass("pos-r") || $parent.hasClass("pos-a");

	};


	/**
	 * Loop through the given elements, and try to load
	 * the image for them. We check the data-lazy-spinner attribute
	 * to see if we want to override the load type in to a spinner
	 */
	return $(this).each(function() {

		var $el = $(this),
			data = $el.data(),
			image = data.lazy,
			spinner = data.lazySpinner;

		if ( $el.is( "img" ) && image ) {

			if (
				!spinner &&
				isProduct( image ) &&
				parentPositioned( $el ) &&
				Modernizr.cssfilters
			) {

				loadProductWithBlur( $el, image );

			} else {

				loadImg( $el, image );

			}

		}

	});

};




/**
 *
 * A helper method to add every element to a global
 * array, allowing us to later check that array for lazy-loaded
 * elements and try to load anything in there.
 *
 */
$.fn.lazy = function( options ) {

	"use strict";

	var bind,
		defaults = {
			grace: 200,
			instant: false
		};

	options = $.extend( {}, defaults, options );

	bind = function( key, el ) {

		var $window = $( window ),
			$el = $( el ),
			winTop = $window.scrollTop(),
			winHeight = $window.height(),
			winBottom = winTop + winHeight;

		if ( !$el.data( "lazy-load-options" ) ) {
			$el.data( "lazy-load-options", options );
		}

		window.lazyLoaders = window.lazyLoaders || [];
		window.lazyLoaders.push( $el );

		/*
		 * trigger an "addLazyItem" event each time
		 * an element is added to the lazyLoaders list
		 * so we can load it if needed.
		 */
		LC.pubsub.trigger("addLazyItem", {
			windowTop: winTop,
			windowBottom: winBottom,
			windowHeight: winHeight
		});

	};

	return $(this).each( bind );

};


/**
 *
 * Bind a global event which fires each time
 * we scroll, this event will check the array
 * of lazy-loaded items, and check each element
 * in that array to see if it's in the viewport.
 *
 * If it's in the viewport, it will then proceed to
 * fire the "lazyload" method on that element and
 * finally remove that element from the array.
 *
 */

$(function() {

	"use strict";

	window.lazyLoaders = window.lazyLoaders || [];

	var $body = $("body");

	LC.pubsub

		.on( "windowScroll addLazyItem", function( e, data ) {

			var $el,
				options,
				dimensions,
				stillExists,
				hasLoaded,
				shouldLoad = false,
				n;

			for ( n = window.lazyLoaders.length - 1; n >= 0; n-- ) {

				$el = 			window.lazyLoaders[ n ];
				stillExists = 	$body.find( $el ).length > 0;
				hasLoaded = 	$el.data( "lazy-loaded" );

				if ( !stillExists || hasLoaded ) {

					// check first if the image still exists, or
					// if the image already loaded -- if so,
					// remove that image from the array of lazy-load images

					window.lazyLoaders.splice( n, 1 );

				} else {

					// next check if the element is supposed to load
					// instantly (no scroll check), or if it is in the viewport at all,
					// and start loading. We use a "grace" padding which allows
					// us to start loading the image "x" pixels before the top/bottom
					// of the screen.

					options = $el.data("lazy-load-options");
					dimensions = $el.dimensions();

					shouldLoad = (
						$el.is( ":visible" ) && ( options.instant || (
							dimensions.offset.top <= data.windowBottom + options.grace &&
							dimensions.offset.top + dimensions.height >= data.windowTop - options.grace
						))
					);

					if ( shouldLoad ) {

						window.lazyLoaders.splice( n, 1 );
						$el.lazyImage( options );

					}

				}

			}

		});


	// init the lazyload images
	LC.pubsub

		.on( "bindLazy global.openmodal", function( e, data ) {

			var $scope = $("body");

			if ( data && typeof data.scope !== "undefined" ) {
				$scope = data.scope;
			}

			$scope
				.find(".lazy-load, .blur-load, .js-lazy")
				.lazy();

		})

		.trigger( "bindLazy" );

});


/* eslint */

/**
 * A helper for nicely styled and ux'ed form labels/placeholders.
 * use like:
 *
 * 		<input
 * 			type="text"
 * 		 	id="x"
 * 		 	class="lc-field__input"
 * 		 	placeholder="my placeholder" >
 *
 * 		<textarea
 * 			id="x"
 * 			class="lc-field__textarea"
 * 			data-placeholder="my placeholder">
 *
 * 		</textarea>
 *
 * 		<dsp:input type="text" class="lc-field__input">
 *   		<dsp:tagAttribute name="placeholder" value="${ content }" />
 *		</dsp:input>
 *
 * 		<dsp:textarea class="lc-field__textarea">
 *			<span data-placeholder="${ content }" class="hide">
 *		</dsp:textarea>
 *
 * 	text area need extra <span> because
 *	- <dsp:textarea> doesn't support data attribute
 *	- <dsp:tagAttribute> is only support <dsp:input> and <dsp:select>
 *	Ref: https://docs.oracle.com/cd/E26180_01/Platform.94/PageDevGuide/html/s1234dsptagattribute01.html
 *
 */

$.fn.lcPlaceholder = function() {

	"use strict";

	var labelClass = "lc-field__placeholder",
		updateClasses;


	updateClasses = function( $label, $input ) {

		var filled = $input.val().trim() !== "";

		$label.toggleClass( "is-shifted", filled );
		$input.toggleClass( "is-filled", filled );

	};

	return $( this ).each( function( i, el ) {

		var $input = $( el ),
			$label = $( "<label class='" + labelClass + "' />" ),
			placeholder;

		// try to get a placeholder value
		placeholder = $input.next( "[data-placeholder]" ).data( "placeholder" ) ||
					$input.attr( "placeholder" ) ||
					$input.data( "placeholder" );

		if ( !placeholder ) {

			LC.warn( "attempted to run $.fn.lcPlaceholder on field with no placeholder" );
			return;

		}

		/*
		 * remove any pre-existing labels, we don't want dupes !!
		 */
		$input
			.prop( "placeholder", "" )
			.attr( "data-placeholder", placeholder )
			.next( "." + labelClass )
			.remove();

		/*
		 * add the label to the dom, with the correct data.
		 */
		$label
			.text( placeholder )
			.attr( "for", $input.attr("id") )
			.insertAfter( $input );

		$input
			.off(".lcPlaceholder")
			.on(
				"change.lcPlaceholder " +
				"focus.lcPlaceholder " +
				"blur.lcPlaceholder " +
				"compositionupdate.lcPlaceholder " +
				"compositionend.lcPlaceholder " +
				"keyup.lcPlaceholder " +
				"update.lcPlaceholder",
				function() {

					updateClasses( $label, $input );

				});

		updateClasses( $label, $input );

	});

};

$(function() {

	"use strict";

	LC.pubsub.on("global.openmodal forms.placeholders", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".lc-field__input, .lc-field__textarea" )
			.not(".lc-field__fake")
			.not(".lc-field__input--new")
			.lcPlaceholder();

	}).trigger("forms.placeholders");

});


/* eslint */

/**
 * A component for creating a quantity picker with correct
 * interactions and bindings, plus API for incrementing/decrementing
 * and programmatically setting the values.
 *
 *
 * HTML structure should look something like;
 *
 *		<div class="lc-quantity">
 *
 *		  <button class="lc-quantity__minus / lc-label lc-label--inset lc-label--square">
 *		    <i class="lc-icon txt-black" data-icon="minus"></i>
 *		  </button>
 *
 *		  <input
 *		  	class="lc-quantity__input / lc-label lc-label--inset lc-label--active / bor-0 mar-h5 txt-lc"
 *		  	type="number" min="1" max="5" value="1">
 *
 *		  <button class="lc-quantity__plus / lc-label lc-label--inset lc-label--square">
 *		    <i class="lc-icon txt-black" data-icon="plus"></i>
 *		  </button>
 *
 *		</div>
 *
 */
( function( LC, $ ) {

	"use strict";

	// initialise the components object,
	// if it doesn't already exist.
	LC.Components = LC.Components || {};

	// initialise the Quantity Select component constructor,
	// used for prototyping components.
	LC.Components.QuantitySelect = function( $el, options ) {

		var _this = this;

		// we can't do anything without a valid
		// element to initialise on.
		if ( !$el || !$el.length ) {

			LC.warn( "cannot 'Quantity Select' when there's no element." );
			return false;

		}

		_this.$wrapper =	$el;
		_this.$input = 		_this.$wrapper.find(".lc-quantity__input");
		_this.$plus = 		_this.$wrapper.find(".lc-quantity__plus");
		_this.$minus = 		_this.$wrapper.find(".lc-quantity__minus");


		/**
		 * parse the value to integer.
		 * @param  {Integer} value 			| the input value
		 * @param  {Integer} defaultValue 	| the default value for the non-integer input
		 * @return {Integer} 				| the parsed integer
		 */
		_this.parseInputValue = function( value, defaultValue ) {

			var parsedValue =  parseInt( value, 10 );

			if ( isNaN( parsedValue ) ) {

				return defaultValue;

			} else {

				return parsedValue;

			}

		};

		/**
		 * set up the state of this quantity selector
		 * by referencing the DOM elements or using a default value
		 * @type {Object}
		 */
		_this.state = {

			min: _this.parseInputValue( _this.$input.prop( "min" ), 1 ),
			max: _this.parseInputValue( _this.$input.prop( "max" ), 5 ),
			quantity: _this.parseInputValue( _this.$input.val(), 1 )

		};

		/**
		 * Default options for the dropdown component
		 * @type {Object}
		 */
		_this.defaults = {

			disabledClass: 		"is-disabled",
			namespace: 			"lcQuantitySelect"

		};

		_this.options = 	_.extend( {}, _this.defaults, options );

		// useful object for storing event names
		_this.events 		= {};
		_this.events.click	= "click." + _this.options.namespace;

		return _this;

	};

	// some variables for making access easier.
	var QuantitySelect = LC.Components.QuantitySelect,
		_proto = QuantitySelect.prototype;


	/**
	 * initialise the component with the
	 * correct values and set the display
	 */
	_proto.init = function() {

		var _this = this;

		_this.setQuantity();
		_this.bind();

		_this.$wrapper.trigger( "init.quantity", _this.state );

	};

	/**
	 * method to programmatically set the value of
	 * the quantity field to an integer. Will not allow
	 * a value higher than the max, or lower than the min
	 *
	 * @param  {Integer} val 	| the quantity we want to set to
	 * @return {Integer} 		| the quantity we set to
	 */
	_proto.setQuantity = function( val ) {

		var _this = this;

		if ( Number.isInteger( val ) ) {

			_this.state.quantity = val;

		}

		_this.validateQuantity();
		_this.updateDisplay();

		return _this.state.quantity;

	};

	/**
	 * make sure that the current quantity
	 * is actually possible based on the min/max
	 * @return {Integer} | the quantity state
	 */
	_proto.validateQuantity = function() {

		var _this = this;

		if ( _this.state.quantity < _this.state.min ) {

			_this.state.quantity = _this.state.min;

		} else if ( _this.state.quantity > _this.state.max ) {

			_this.state.quantity = _this.state.max;

		}

		return _this.state.quantity;

	};

	/**
	 * set the minimum value for the quantity picker
	 * programmatically, will also update the DOM and the
	 * quantity value if it's lower than the new min
	 *
	 * @param  {Integer} val 	| the integer to set for the minimum
	 * @return {Integer} 		| the integer we just set
	 */
	_proto.setMin = function( val ) {

		var _this = this;

		// only apply if it's an integer
		if ( Number.isInteger( val ) ) {

			_this.state.min = val;

			_this.$input
				.prop( "min", val )
				.attr( "min", val );

			_this.validateQuantity();
			_this.updateDisplay();

		}

		return _this.state.min;

	};

	/**
	 * set the maximum value for the quantity picker
	 * programmatically, will also update the DOM and the
	 * quantity value if it's higher than the new max
	 *
	 * @param  {Integer} val 	| the integer to set for the maximum
	 * @return {Integer} 		| the integer we just set
	 */
	_proto.setMax = function( val ) {

		var _this = this;

		// only apply if it's an integer
		if ( Number.isInteger( val ) ) {

			_this.state.max = val;

			_this.validateQuantity();
			_this.updateDisplay();

		}

		return _this.state.max;

	};

	/**
	 * increase the quantity by 1 if possible
	 * @return {Integer} | the new quantity value
	 */
	_proto.increment = function() {

		var _this = this;
		_this.setQuantity( _this.state.quantity + 1 );

	};

	/**
	 * decrease the quantity by 1 if possible
	 * @return {Integer} | the new quantity value
	 */
	_proto.decrement = function() {

		var _this = this;
		_this.setQuantity( _this.state.quantity - 1 );

	};

	/**
	 * return the current quantity state
	 * @return {Integer} | the current quantity value
	 */
	_proto.getQuantity = function() {
		return this.state.quantity;
	};

	/**
	 * return the current minimum state
	 * @return {Integer} | the current minimum value
	 */
	_proto.getMin = function() {
		return this.state.min;
	};

	/**
	 * return the current maximum state
	 * @return {Integer} | the current maximum value
	 */
	_proto.getMax = function() {
		return this.state.max;
	};

	/**
	 * return if the current quantity is at/below the minumum
	 * @return {Boolean} | is the current quantity at/below minumum state
	 */
	_proto.isMin = function() {
		return this.state.quantity <= this.state.min;
	};

	/**
	 * return if the current quantity is at/above the maximum
	 * @return {Boolean} | is the current quantity at/above maximum state
	 */
	_proto.isMax = function() {
		return this.state.quantity >= this.state.max;
	};

	/**
	 * programmatically ask the component to update it's
	 * state in the UI (DOM elements) which should set the
	 * classes on plus/minus correctly, and update the value in
	 * the quantity field
	 */
	_proto.updateDisplay = function() {

		var _this = this,
			isMin = _this.isMin(),
			isMax = _this.isMax();

		_this.$minus
			.attr( "disabled", isMin )
			.toggleClass( _this.options.disabledClass, isMin );

		_this.$plus
			.attr( "disabled", isMax )
			.toggleClass( _this.options.disabledClass, isMax );

		_this.$input
			.prop({
				min: _this.state.min,
				max: _this.state.max
			})
			.attr({
				min: _this.state.min,
				max: _this.state.max
			})
			.val( _this.state.quantity )
			.attr( "value", _this.state.quantity );


		_this.$wrapper.trigger( "update.quantity", _this.state );

	};

	/**
	 * bind the events for incrementing,
	 * decrementing and inputting the values
	 */
	_proto.bind = function() {

		var _this = this,
			namespace = _this.defaults.namespace;

		_this.unbind();

		_this.$plus
			.on( _this.events.click, function( e ) {

				e.preventDefault();
				_this.increment();

			});

		_this.$minus
			.on( _this.events.click, function( e ) {

				e.preventDefault();
				_this.decrement();

			});

		_this.$input
			.on(
				"change." + namespace + " " +
				"compositionupdate." + namespace + " " +
				"compositionend." + namespace + " " +
				"keyup." + namespace + " " +
				"update." + namespace,
				function() {

					var input = parseInt( $( this ).val(), 10 );

					// only try to set the quantity if the value is
					// an integer, this is to prevent trying to set
					// the quantity for strings or undefined which would
					// reset the text-input back to the previous quantity
					if ( Number.isInteger( input ) ) {

						_this.setQuantity( input );

					}

				});

		_this.$input
			.on( "focus." + namespace, function() {

				$( this ).select();

			});

		_this.$input
			.on( "blur." + namespace, function() {

				if ( $(this).val().trim() === "" ) {

					_this.updateDisplay();

				}

			});

	};

	/**
	 * remove the event bindings set up at init
	 */
	_proto.unbind = function() {

		var _this = this,
			namespace = _this.defaults.namespace;

		_this.$input.off( "." + namespace );
		_this.$plus.off( "." + namespace );
		_this.$minus.off( "." + namespace );

	};

	/**
	 * destroy the quantity selector
	 */
	_proto.destroy = function() {

		var _this = this;

		_this.unbind();
		_this.$wrapper.removeData( "lcQuantitySelect" );
		_this = null;

	};




	/**
	 * Use a $.fn... binding to apply this component
	 * directly to a DOM element.
	 * @param  { Object } options  	| Object with options properties.
	 * @return { $ }         		| returns the jQuery chainable.
	 */
	$.fn.lcQuantitySelect = function( options ) {

		var $this = $(this);

		return $this.each( function( k, el ) {

			var $el = $( el ),
				quantity;

			// assign the prototype to a data-attribute for
			// access later on.
			quantity = new LC.Components.QuantitySelect( $el, options );
			quantity.init();

			$el.data( "lcQuantitySelect", quantity );

		});

	};

}( window.LC = window.LC || {}, jQuery ));




/**
 *
 * finally, bind all ".lc-quantity" elements to
 * the lcQuantitySelect component, and set up a global
 * event to re-bind them if needed.
 *
 */

$(function() {

	"use strict";

	LC.pubsub.on("bindQuantities global.openmodal", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".lc-quantity, .lc-quantity-new" )
			.lcQuantitySelect();

	}).trigger("bindQuantities");

});



/**
 * A helper for nicely styled and ux'ed form select labels.
 * use like:
 *
 * 		<select id="x" class="lc-field__select">
 *     		<option selected>blah</option>
 *      </select>
 *
 */

/**
 *
 * @param {Object} options
 * @param {String} options.labelClass the class to apply to the select label
 * @param {String} options.iconClass the class to apply to the icon
 * @param {String} options.dataIcon the icon to apply to the select label
 * @param {Function} options.formatter a function to format the text of the label
 * @returns {$Object} the jQuery object
 */
$.fn.lcSelects = function( options ) {

	"use strict";

	var settings = 		$.extend({}, options),
		labelClass = 	settings.labelClass || "lc-field__select-label",
		iconClass = 	settings.iconClass || "lc-field__select-icon",
		dataIcon =		settings.dataIcon || "arrow-down-thick",
		formatter = 	settings.formatter || function( text, data, $el ) { return text; },

		/**
		 * set the value of the replacement label based on
		 * the current selected <option> element, also change style
		 * to make it easier for customer to see
		 * @param {$Object} $select the <select> dropdown element
		 * @param {$Object} $label the <label> which sits on top of the <select> hiding it
		 * @param {$Object} $icon the <i> which holds the 🔽 down arrow
		 */
		setValue = function( $select, $label, $icon ) {

			var $options = $select.find("option"),
				$selected = $options.filter(":selected"),
				text = formatter( $selected.text(), $selected.data(), $selected ),
				isDefault = ( $selected.val() === "" && $selected.index() === 0 );

			if ( $select.is( "button" ) ) {
				text = $select.text();
			}

			if ( !$options.length && text === "" ) {
				LC.warn( "attempted to run $.fn.selects on select with no options" );
				return;
			}

			$label.html( text );
			$label.toggleClass( "txt-grey", isDefault );
			$icon.toggleClass( "txt-dark-grey", isDefault );

		};

	return $( this ).each( function( i, el ) {

		var $select = 	$( el ),
			$label = 	$( "<label class='" + labelClass + " / pad-h10 pad-r30' />" ),
			$icon = 	$( "<i class='lc-icon " + iconClass +
			           	   " / txt-12' data-icon='" + dataIcon + "' />" );


		/*
		 * remove any pre-existing labels, we don't want dupes !!
		 */
		$select
			.addClass( "is-replaced" )
			.siblings( "." + iconClass + ", ." + labelClass )
			.remove();

		/*
		 * add the label to the dom, with the correct data.
		 */
		$label
			.attr( "for", $select.attr("id") )
			.insertAfter( $select );

		setValue( $select, $label, $icon );

		/*
		 * style and append the icon
		 */
		$icon.insertAfter( $label );

		$select
			.off(".lcSelects")
			.on("change.lcSelects " +
				"focus.lcSelects " +
				"blur.lcSelects " +
				"compositionupdate.lcSelects " +
				"compositionend.lcSelects " +
				"keyup.lcSelects " +
				"update.lcSelects",
			function() {

				setValue( $select, $label, $icon );

			});

	});

};

$(function() {

	"use strict";

	LC.pubsub.on("global.openmodal forms.selects", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".lc-field__select" )
			.not( ".lc-field__fake, .is-replaced" )
			.lcSelects();

	}).trigger("forms.selects");

});


/* eslint */

/**
 * A helper to stack swatches (labels) in the ui (color/size) when
 * supplied a container which contains only swatches (&/ breaks)
 * use like:
 *
 * 		$(".product-sizes__list").lcStackSwatches();
 *
 */

$.fn.lcStackSwatches = function( options ) {

	"use strict";

	var defaults = {

			listSelector: ".product-sizes__item, .product-colors__item",
			moreSelector: ".product-sizes__item--more, .product-colors__item--more",
			breakHtml: " <li class='breaker'></li> ",
			hiddenClass: "hide",

			showArrow: false,
			selected: 0,
			limit: 20

		},

		settings = $.extend( defaults, options ),

		/**
		 * calculate the breaks needed to display the
		 * ui correctly, algorithm is based on Andy Massey's desire.
		 *
		 * @param  {number} len | the number of items to generate breaks for
		 * @return {array}      | array of where to place breaks
		 */
		getBreaks = function( len ) {

			var breakpoints;

			if ( len <= 5 ) {
				breakpoints = [ 5 ];
			} else if ( len <= 8 ) {
				breakpoints = [ 4 ];
			} else if ( len <= 10 ) {
				breakpoints = [ 5 ];
			} else if ( len <= 12 ) {
				breakpoints = [ 4, 8 ];
			} else if ( len <= 15 ) {
				breakpoints = [ 5, 10 ];
			} else if ( len <= 16 ) {
				breakpoints = [ 4, 8, 12 ];
			} else if ( len <= 18 ) {
				breakpoints = [ 5, 10, 14 ];
			} else if ( len <= 20 ) {
				breakpoints = [ 5, 10, 15 ];
			} else if ( len <= 23 ) {
				breakpoints = [ 5, 10, 15, 19 ];
			} else if ( len <= 25 ) {
				breakpoints = [ 5, 10, 15, 20 ];
			} else if ( len <= 28 ) {
				breakpoints = [ 5, 10, 15, 20, 24 ];
			} else if ( len <= 30 ) {
				breakpoints = [ 5, 10, 15, 20, 25 ];
			} else if ( len <= 33 ) {
				breakpoints = [ 5, 10, 15, 20, 25, 29 ];
			} else if ( len <= 35 ) {
				breakpoints = [ 5, 10, 15, 20, 25, 30 ];
			} else if ( len <= 38 ) {
				breakpoints = [ 5, 10, 15, 20, 25, 30, 34 ];
			} else {
				breakpoints = [ 5, 10, 15, 20, 25, 30, 35 ];
			}

			return breakpoints;

		};

	return $( this ).each( function( i, el ) {

		var $container = $( el ),
			$swatches,
			swatchLength,
			breakArray,
			breakLength,
			selected = settings.selected,
			limit = settings.limit,
			visible = ( limit - 1 ),
			displayStart = 0,
			displayEnd = visible,
			hitUpper,
			hitLower,
			half = ( visible / 2 ),
			total,
			more = false;

		// remove all the current breaks so we are
		// no counting them in our arrangement.
		$container.find( ".breaker" ).remove();

		// store the swatches for use later
		$swatches =
			$container
				.find( settings.listSelector )
				.not( settings.moreSelector )
				.removeClass( settings.hiddenClass );

		// save the total
		total = $swatches.length;

		// hide any swatches that after before/after
		// the selected one, so that we only see the
		// chosen maximum ( or -1 with arrow ) on the screen.
		if ( settings.showArrow && total > limit ) {

			more = true;

			hitLower = ( selected - half ) <= 0;
			hitUpper = ( selected + half ) >= total;

			if ( hitLower ) {

				// hide all the swatches after the ( limit - 1 )
				$swatches
					.not( $swatches.slice( 0, displayEnd ) )
					.addClass( settings.hiddenClass );

			} else if ( hitUpper ) {

				displayStart = ( total - visible );

				// hide all the swatches before the ( total - visible )
				$swatches
					.not( $swatches.slice( displayStart ) )
					.addClass( settings.hiddenClass );

			} else {

				displayStart = selected - Math.floor( half );
				displayEnd = displayStart + visible;

				// hide swatches before and after half the limit
				// from the selected
				$swatches
					.not( $swatches.slice( displayStart, displayEnd ) )
					.addClass( settings.hiddenClass );

			}

		}

		// show / hide the "more" arrow
		// when necessary.
		$container
			.find( settings.moreSelector )
			.toggleClass( settings.hiddenClass, !more );

		// store an array with breakpoints based
		// on the currently visible swatches
		swatchLength =
			$swatches
				.filter(":not( ." + settings.hiddenClass + " )")
				.length;

		// the amount of swatches should include the
		// ( > ) "more arrow", for breaking correctly
		if ( more ) {
			swatchLength += 1;
		}

		breakArray = getBreaks( swatchLength );
		breakLength = breakArray.length;

		// and add in the new breaks, but looping
		// through the amount of breaks and then adding
		// breaks at the correct point
		while ( breakLength-- ) {

			// but when we want to show a "more" arrow,
			// we only want to add breaks before the "limit"
			// so we don't accidentally get the "more" arrow
			// shown on a new line
			if ( !settings.showArrow || breakArray[ breakLength ] < limit ) {

				// add breaks after the
				// swatches that need them
				$swatches
					.eq( displayStart + ( breakArray[ breakLength ] - 1 ) )
					.after( settings.breakHtml );

			}

		}

	});

};


/* eslint */

/**
 * A component for binding scrolling-table functionality
 * to a wrapper, the DOM should look like:
 *
 *      <div class="table-wrapper">
 *      	<div class="table-scroller / ofx-a">
 *
 *       		<table class="w-auto"> ... </table>
 *
 *      	</div>
 *      </div>
 *
 */
( function( LC, $ ) {

	"use strict";

	// initialise the components object,
	// if it doesn't already exist.
	LC.Components = LC.Components || {};

	// initialise the TableScroll component constructor,
	// used for prototyping scrolling table elements.
	LC.Components.TableScroll = function( $el, options ) {

		var _this = this;

		/**
		 * Default options for the dropdown component
		 * @type {Object}
		 */
		_this.defaults = {

			startClass: 		"at-start",
			endClass: 			"at-end",
			activeClass: 		"is-active",
			scrollerClass: 		"table-scroller",
			pollingInterval: 	100,
			edgeBuffer: 		10,

			namespace: 			"lcTableScroll"

		};

		// we can't do anything without a valid
		// element to initialise on.
		if ( !$el || !$el.length ) {
			LC.warn( "cannot 'TableScroll' when there's nothing to 'TableScroll'." );
			return false;
		}

		_this.options = 	_.extend( {}, _this.defaults, options );

		_this.$wrapper = 	$el;
		_this.$scroller = 	$el.children( "." + _this.options.scrollerClass );
		_this.$table = 		$el.find( "table" );

		return _this;

	};

	// some variables for making access easier.
	var TableScroll = LC.Components.TableScroll,
		_proto = TableScroll.prototype;


	/**
	 * initialise the component
	 */
	_proto.init = function() {

		var _this = this;

		_this.state = {};
		_this.guid = _.uniqueId();

		_this.cloneTable();
		_this.updateState();
		_this.setClasses();
		_this.bind();

		_this.$wrapper.trigger( "init.tableScroll", _this.state );

	};

	/**
	 * clone the table so that we can fix it's
	 * position while the user scrolls horizontal
	 */
	_proto.cloneTable = function() {

		var _this = this,
			$clone = _this.$table.clone();

		$clone
			.addClass( "lc-table-clone lc-table-fixed / pos-a t-0 l-0" )
			.insertAfter( _this.$scroller );

	};

	/**
	 * delete the cloned table from the wrapper
	 */
	_proto.deleteClone = function() {

		var _this = this,
			$clone = _this.$wrapper.find(".lc-table-clone");

		$clone.remove();

	};

	/**
	 * set the state of the table using the scroller's
	 * width, and the wrapper's scrollLeft() property
	 */
	_proto.updateState = function() {

		var _this = this,
			left = _this.$scroller.scrollLeft(),
			scrollerWidth = _this.$scroller.width(),
			tableWidth = _this.$table.width(),
			offsetWidth = ( tableWidth - scrollerWidth ),
			atStart,
			atEnd,
			isActive;

		// check if the table is larger than the scroller.
		isActive = ( scrollerWidth < tableWidth );

		// check if we are at start, or end.
		atStart = ( left <= _this.defaults.edgeBuffer );
		atEnd = ( left >= offsetWidth - _this.defaults.edgeBuffer );

		// set the state for triggered events
		_this.state.isActive = isActive;
		_this.state.atStart = atStart;
		_this.state.atEnd = atEnd;

	};

	/**
	 * set the classes on the element according to
	 * the value held in state
	 */
	_proto.setClasses = function() {

		var _this = this;

		// toggle the css classes accordingly
		_this.$wrapper
			.toggleClass( _this.options.startClass, _this.state.atStart )
			.toggleClass( _this.options.endClass, _this.state.atEnd )
			.toggleClass( _this.options.activeClass, _this.state.isActive );

		_this.$wrapper.trigger( "update.tableScroll", _this.state );

	};

	/**
	 * remove all set classes
	 */
	_proto.unsetClasses = function() {

		var _this = this;

		_this.$wrapper
			.removeClass( _this.options.startClass )
			.removeClass( _this.options.endClass )
			.removeClass( _this.options.activeClass )
			.removeClass( "pos-r" );

		_this.$scroller.removeClass( "ofx-a pad-b20" );

	};

	/**
	 * method to help with refreshing classes
	 * and state when needed.
	 */
	_proto.refresh = function() {

		var _this = this;

		_this.unsetClasses();
		_this.updateState();
		_this.setClasses();

	};

	/**
	 * bind the event for scrolling the table
	 */
	_proto.bind = function() {

		var _this = this;

		_this.unbind();

		_this.$scroller
			.on( "scroll." + _this.options.namespace, function() {

				clearTimeout( _this.scrollTimer );

				// we want to limit the amount we set the classes
				// for performance consideration
				_this.scrollTimer = setTimeout( function() {

					// first update the state, then
					// set the classes
					_this.updateState();
					_this.setClasses();

				}, _this.options.pollingInterval );

			});

		// bind an event on global resize which will
		// make sure the tables look OK when screen size changes.
		LC.pubsub.on( "resize.globalresize.tableScroll", function() {

			// first update the state, then
			// set the classes
			_this.refresh();

		});

	};

	/**
	 * remove the event bindings set up at init
	 */
	_proto.unbind = function() {

		var _this = this;

		_this.$scroller.off( _this.options.namespace );
		LC.pubsub.off( "resize.globalresize.tableScroll" );

	};

	/**
	 * destroy the scroller
	 */
	_proto.destroy = function() {

		var _this = this;
		_this.deleteClone();
		_this.unsetClasses();
		_this = null;

	};




	/**
	 * Use a $.fn... binding to apply this component
	 * directly to a DOM element.
	 * @param  { Object } options  	| Object with options properties.
	 * @return { $ }         		| returns the jQuery chainable.
	 */
	$.fn.lcTableScroll = function( options ) {

		var $this = $(this);

		return $this.each( function( k, el ) {

			var $el = $( el ),
				scroller;

			// only initialise if not already
			// initialised.
			if ( $el.data("lcTableScroll") ) {

				LC.info( "table scroll already initialised for: ", el );
				return;

			}

			// assign the prototype to a data-attribute for
			// access later on.
			scroller = new LC.Components.TableScroll( $el, options );
			scroller.init();

			$el.data( "lcTableScroll", scroller );

		});

	};

}( window.LC = window.LC || {}, jQuery ));




/**
 *
 * finally, bind all ".table-wrapper" elements to
 * the lcTableScroll component, and set up a global
 * event to re-bind them if needed.
 *
 */

$(function() {

	"use strict";

	LC.pubsub.on("bindTables global.openmodal", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find( ".table-wrapper" )
			.lcTableScroll();

	}).trigger("bindTables");

});


/**
 * A helper for limiting and counting the number of characters
 * and lines in a textarea.
 * use like:
 *
 *	<div class="lc-field" data-maxlength=160 data-maxlength-unicode=70 data-maxline=10>
 *		<textarea class="lc-field__textarea"></textarea>
 * </div>
 *
 * UCS / GSM conditions;
 * https://www.twilio.com/docs/glossary/what-sms-character-limit
 *
 */

$.fn.lcTextareaCount = function() {

	"use strict";

	return $( this ).each( function( i, el ) {

		var $textarea = $( el ),
			$field = $textarea.parent(".lc-field"),
			$counter = $("<span />"),
			setText,
			validateText,
			validateLines,
			validateGSM,
			destroy,
			maxlength = parseInt( $field.attr("data-maxlength") ) || 160,
			maxlengthUnicode = parseInt( $field.attr("data-maxlength-unicode") ) || maxlength,
			maxline = parseInt( $field.attr("data-maxline") ) || 0;

		$counter.addClass( "lc-field__counter txt-12 txt-lc" );
		$textarea.addClass( "is-counting" );
		$textarea.attr( "maxlength", maxlength );
		$textarea.attr( "maxline", maxline );
		$field.append( $counter );

		validateGSM = function(text) {

			var test = /^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\[~\]|\u20AC]*$/gi,
				isGSM = text.match( test );

			return !!isGSM;

		};

		validateText = function(e) {

			var text = $textarea.val(),
				charCount = text.length,
				isGSMonly = validateGSM( text ),
				max = isGSMonly ? maxlength : maxlengthUnicode,
				atLimit = charCount === max,
				overLimit = charCount > max;

			if ( overLimit ) {
				$textarea.val( $textarea.val().slice( 0, max ) );
			}

			$counter
				.toggleClass( "is-warning", atLimit )
				.toggleClass( "is-error", overLimit );

			$textarea
				.toggleClass( "is-warning", atLimit )
				.toggleClass( "is-error", overLimit );

			return atLimit;

		};

		validateLines = function(e) {

			if ( maxline ) {

				var lines = $textarea.val().match(/\n/gi),
					lineCount = lines ? lines.length : 0,
					atLineLimit = lineCount >= maxline;

				return atLineLimit && e.keyCode === 13;

			} else {

				return true;

			}

		};

		setText = function() {

			var text = $textarea.val(),
				charCount = text.length,
				isGSMonly = validateGSM( text ),
				max = isGSMonly ? maxlength : maxlengthUnicode;

			$counter.text( charCount + "/" + max );

		};

		destroy = function() {

			$textarea.off( ".lcTextareaCount" );
			$textarea.removeClass( "is-counting" );
			$counter.remove();

		};

		$textarea.off( ".lcTextareaCount" );

		$textarea.on(
			"change.lcTextareaCount " +
			"focus.lcTextareaCount " +
			"blur.lcTextareaCount " +
			"compositionupdate.lcTextareaCount " +
			"compositionend.lcTextareaCount " +
			"keydown.lcTextareaCount " +
			"keyup.lcTextareaCount " +
			"update.lcTextareaCount", function( e ) {

				var textLimit = validateText(),
					lineLimit = validateLines(e);

				if ( lineLimit ) {
					return false;
				} else {
					setText();
					return true;
				}

			});

		$textarea.on( "destroy.lcTextareaCount", destroy );

	});

};

$(function() {

	"use strict";

	LC.pubsub

		.on( "global.openmodal forms.textareaCount", function( e, data ) {

			var $scope = $("body");

			if ( data && typeof data.scope !== "undefined" ) {
				$scope = data.scope;
			}

			$scope
				.find( ".lc-field[data-maxlength]")
				.find( ".lc-field__textarea" )
				.not( ".is-counting" )
				.lcTextareaCount();

		})

		.trigger( "forms.textareaCount" );

});


/**
 *
 * register a global pubsub which will remove orphans
 * from any element with the "js-no-orphans" className.
 *
 * use like:
 * 	<p class="js-no-orphans">Lorem Ipsum Dolor Sit Amet</p>
 *
 * or:
 * 	<p class="js-no-orphans" data-orphans="2">Lorem Ipsum Dolor Sit Amet</p>
 *
 * unorphanize is a plugin loaded through yarn.
 *
 */

$(function() {

	"use strict";

	LC.pubsub.on("global.unorphanize", function( e, data ) {

		var $scope = $("body");

		if ( data && typeof data.scope !== "undefined" ) {
			$scope = data.scope;
		}

		$scope
			.find(".js-no-orphans")
			.each( function() {

				var $this = $(this),
					orphans = 1;

				if ( $this.data("orphans") ) {
					orphans = $this.data("orphans");
				}

				$this.unorphanize( orphans );

			});

	});

	LC.pubsub.trigger("global.unorphanize");

});


/**
 *
 * [ module ]
 * tracking -> helpers
 *
 * these helper methods are used throughout the tracking
 * modules, and should be loaded before the core/affiliates.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.tracking = LC.tracking || {};

	LC.tracking.helpers = lcmodule( function( exports ) {

		exports.moduleName = "tracking.helpers";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, exports ),
			_this = {};

		_this.trackingConfig = LC.config.getValue("tracking");

		/**
		 * initialise the helpers module
		 */

		ex.init = function() {

		};

		/**
		 * should we allow any marketing tracking at all?
		 * @return { Boolean }
		 */
		ex.performMarketing = function() {

			var enabled = (
				typeof _this.trackingConfig.performMarketingTracking !== "undefined" &&
				typeof _this.trackingConfig.performMarketingTracking.switch !== "undefined" &&
				_this.trackingConfig.performMarketingTracking.switch !== "false"
			);

			LC.info( "Marketing: " + ( enabled  ? "enabled" : "disabled" ) );

			return enabled;

		};

		/**
		 * should we allow any marketing for a specific affiliate?
		 *
		 * @param  { String } affiliate | The name of the tracking affiliate to test,
		 *                  			  this is euqal to the tracking config property name
		 * @return { Boolean }
		 */
		ex.performAffiliate = function( affiliate ) {

			var enabled = (
				typeof _this.trackingConfig[ affiliate ] !== "undefined" &&
				typeof _this.trackingConfig[ affiliate ].switch !== "undefined" &&
				_this.trackingConfig[ affiliate ].switch !== "false"
			);

			LC.info( affiliate + ": " + ( enabled  ? "enabled" : "disabled" ) );

			return ex.performMarketing() && enabled;

		};

		/**
		 * get the current page's data layer, or
		 * an empty object.
		 *
		 * @return { Object } | The data layer
		 */
		ex.getDataLayer = function() {

			return window.LCDataLayer[ 0 ] || {};

		};

		/**
		 * get the current page's data layer id, or return
		 * an empty string.
		 *
		 * @return { String } | The data layer id
		 */
		ex.getDatalayerId = function() {

			return $("body").data("data-layer-id") || "";

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));



/**
 *
 * [ module ]
 * LC.modal
 * universal module for showing/hiding a modal
 * which can accept static content or ajax response.
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "tracking.ecomm", function( exports, _this ) {

		var ex = exports;

		ex.setup = function() {

		};

		/**
		 * initialise.
		 */
		ex.init = function() {

			_this.addProductObj = {
				currency: "",
				value: 0,
				items: []
			};

			_this.removeProductObj = {
				currency: "",
				value: 0,
				items: []
			};

			_this.viewCartObj = {
				currency: "",
				value: 0,
				items: []
			};

			_this.addWishlistObj = {
				currency: "",
				value: 0,
				items: []
			};

			_this.orderBeginCheckoutObj = {
				currency: "",
				value: 0,
				items: []
			};

			_this.orderShippingObj = {
				currency: "",
				value: 0,
				shipping_tier: "",
				items: []
			};

			_this.productObj = {
				item_id: "",
				item_name: "",
				affiliation: "",
				coupon: "",
				discount: 0.00,
				index: 0,
				item_brand: "",
				item_category: "",
				item_category2: "",
				item_category3: "",
				item_category4: "",
				item_category5: "",
				item_list_id: "",
				item_list_name: "",
				item_variant: "",
				location_id: "",
				price: 0.00,
				quantity: 1
			};

		};

		ex.getDataLayer = function() {
			return window.LCDataLayer[ 0 ];
		};

		/**
		 * Get the product information from the input object and format it into a usable object
		 * @param {Object} item An object containing product information
		 * @param {String} affiliation The affiliation of the product
		 * @returns {Object} a clean product object
		 */
		ex.getProductInfo = function( item, affiliation ) {

			var productObj = jQuery.extend({}, _this.productObj );

			productObj.item_id = item.skuId;
			productObj.item_name = item.name;
			productObj.item_variant = item.variant;
			productObj.affiliation = affiliation || "";
			productObj.item_brand = item.brandName;
			productObj.price = item.originalPrice;
			productObj.discount = item.discount;
			productObj.quantity = item.quantity;
			productObj.item_list_id = item.itemListId || "";
			productObj.item_list_name = item.itemListName || "";

			return productObj;

		};

		/**
		 * Access the DataLayer and loop through products, building a new clean object
		 * @param {String} affiliation the affiliation of the products
		 * @returns {Array} a clean product list
		 */
		ex.getProductList = function( affiliation ) {

			var productList = [],
				dataLayer = ex.getDataLayer();

			dataLayer.products.forEach( function( item ) {
				productList.push( ex.getProductInfo( item, affiliation ) );
			} );

			return productList;

		};

		ex.getAffiliateData = function() {

			if ( Cookies.get( "linkShareSiteID" ) ) {

				return "linkshare";

			} else if ( Cookies.get( "chineseanAccess" ) )  {

				return "ChineseAn";

			}

			return LC.config.getValue( "site.siteUrl" );

		};

		ex.addProductObj = function( itemDetail ) {

			var addProductObj = _.extend( {}, _this.addProductObj ),
				quantity = itemDetail.quantity || 1;

			addProductObj.currency = itemDetail.currencyCode;
			addProductObj.value = itemDetail.salePrice * quantity;

			addProductObj.items = [];
			addProductObj.items.push( ex.getProductInfo( itemDetail, ex.getAffiliateData() ) );

			return addProductObj;

		};

		ex.trackViewItem = function( itemDetail ) {

			var viewItemObj = ex.addProductObj( itemDetail );

			LC.track.trigger( "ga.event", { eventName: "view_item", eventData: viewItemObj } );

		};

		ex.trackAddToCart = function( itemDetail ) {

			var addProductObj = ex.addProductObj( itemDetail );

			LC.track.trigger( "ga.event", { eventName: "add_to_cart", eventData: addProductObj } );

		};

		ex.trackRemoveFromCart = function( itemDetail ) {

			var removeProductObj = _.extend( {}, _this.removeProductObj ),
				removeAmount = 0,
				productList = [],
				dataLayer = ex.getDataLayer();

			productList.push( ex.getProductInfo( itemDetail ) );
			removeAmount = itemDetail.salePrice * itemDetail.quantity;

			removeProductObj.currency = dataLayer.currency;
			removeProductObj.value = removeAmount;
			removeProductObj.items = productList;

			LC.track.trigger( "ga.event", { eventName: "remove_from_cart", eventData: removeProductObj } );

		};

		ex.trackViewCart = function() {

			var viewCartObj = _.extend( {}, _this.viewCartObj ),
				dataLayer = ex.getDataLayer();

			viewCartObj.currency = dataLayer.currency;
			viewCartObj.value =  dataLayer.total;

			dataLayer.products.forEach( function( product ) {
				viewCartObj.items.push( ex.getProductInfo( product ) );
			});

			LC.track.trigger( "ga.event", { eventName: "view_cart", eventData: viewCartObj } );

		};

		ex.trackUpdateAdd = function( addItem, updateQty ) {

			var addProductObj = _.extend( {}, _this.addProductObj ),
				dataLayer = ex.getDataLayer();

			addItem.quantity = updateQty;

			addProductObj.items.push( ex.getProductInfo( addItem ) );
			addProductObj.currency = dataLayer.currency;
			addProductObj.value = addItem.salePrice * updateQty;

			LC.track.trigger( "ga.event", { eventName: "add_to_cart", eventData: addProductObj } );

		};

		ex.trackUpdateRemove = function( removeItem, updateQty ) {

			var removeProductObj = _.extend( {}, _this.removeProductObj ),
				dataLayer = ex.getDataLayer();

			removeItem.quantity = updateQty;

			removeProductObj.items = [];
			removeProductObj.items.push( ex.getProductInfo( removeItem ) );
			removeProductObj.currency = dataLayer.currency;
			removeProductObj.value = removeItem.salePrice * updateQty;

			LC.track.trigger( "ga.event", { eventName: "remove_from_cart", eventData: removeProductObj } );

		};

		ex.trackUpdateCart = function( itemDetail, originalQty, newQty ) {

			var addQty = newQty > originalQty,
				minusQty = originalQty > newQty,
				updateQty;

			if ( addQty ) {

				updateQty = newQty - originalQty;
				ex.trackUpdateAdd( itemDetail, updateQty );

			} else if ( minusQty ) {

				updateQty = originalQty - newQty;
				ex.trackUpdateRemove( itemDetail, updateQty );

			}

		};

		ex.trackWishlist = function( eventDetail ) {

			var addWishlistObj = _.extend({}, _this.addWishlistObj );

			addWishlistObj.currency = eventDetail.currencyCode;
			addWishlistObj.value = eventDetail.salePrice;
			addWishlistObj.items = [ ex.getProductInfo( eventDetail, ex.getAffiliateData() ) ];

			LC.track.trigger( "ga.event", { eventName: "add_to_wishlist", eventData: addWishlistObj } );

		};

		ex.trackBeginCheckout = function() {

			var orderBeginCheckoutObj = _.extend( {}, _this.orderBeginCheckoutObj ),
				dataLayer = ex.getDataLayer();

			orderBeginCheckoutObj.currency = dataLayer.currency;
			orderBeginCheckoutObj.value = dataLayer.total;
			orderBeginCheckoutObj.items = ex.getProductList( dataLayer.affiliation );

			LC.track.trigger( "ga.event", { eventName: "begin_checkout", eventData: orderBeginCheckoutObj } );

		};

		ex.trackOrderShipping = function( shippingMethod ) {

			var orderShippingObj = _.extend( {}, _this.orderShippingObj ),
				dataLayer = ex.getDataLayer();

			orderShippingObj.currency = dataLayer.currency;
			orderShippingObj.value = dataLayer.total;
			orderShippingObj.shipping_tier = shippingMethod;
			orderShippingObj.items = ex.getProductList( dataLayer.affiliation );

			LC.track.trigger( "ga.event", { eventName: "add_shipping_info", eventData: orderShippingObj } );

		};

		ex.events = function() {

		};

		ex.subscribe = function() {

			LC.track.on( "ecomm.viewItem", function( e, itemDetail ) {

				ex.trackViewItem( itemDetail );

			} );

			LC.track.on( "ecomm.addItem", function( e, itemDetail ) {

				ex.trackAddToCart( itemDetail );

			} );

			LC.track.on( "ecomm.removeItem", function( e, itemDetail ) {

				ex.trackRemoveFromCart( itemDetail );

			} );

			LC.track.on( "ecomm.viewShoppingCart", function( e ) {

				ex.trackViewCart();

			} );

			LC.track.on( "ecomm.updateCart", function( e, itemDetail, originalQty, newQty ) {

				ex.trackUpdateCart( itemDetail, originalQty, newQty );

			} );

			LC.track.on( "ecomm.addToWishlist", function( e, eventDetail ) {

				ex.trackWishlist( eventDetail );

			} );

			LC.track.on( "ecomm.trackBeginCheckout", function() {

				ex.trackBeginCheckout();

			} );

			LC.track.on( "ecomm.setShipping", function( e, shippingMethod ) {

				ex.trackOrderShipping( shippingMethod );

			} );

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * tracking -> ga
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.tracking = LC.tracking || {};

	LC.tracking.ga = lcmodule( function( exports ) {

		exports.moduleName = "tracking.ga";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, exports ),
			_this = {};

		/**
		 * initialise the ga module
		 */

		ex.init = function() {

			// only initiate tagging if set to
			if ( $("body").data( "tagging" ) ) {
				ex.lazySubscribe();
			}

		};

		/**
		 * string to replace masked content with
		 * @type  {string}
		 */
		_this.mask = "**masked**";

		/**
		 * return a string with any "email" portions masked
		 * @param   {String}  input  the string of text to mask
		 * @return  {String}         a string with any emails masked out.
		 */
		_this.maskedEmail = function( input ) {

			var pattern = "([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})",
				regex = new RegExp( pattern, "ig" );

			return input.replace( regex, _this.mask );

		};

		/**
		 * return a string with any "personal information" masked out
		 * based on some PII keys in the LC config
		 * @param   {String}  input  a string of text to mask out
		 * @return  {String}         the input text with any PII masked out
		 */
		_this.maskedContent = function( input ) {

			var piiKeys = LC.config.getValue("url.personalInfoKeys"),
				maskedInput = _this.maskedEmail( input ),
				maskedOutput = maskedInput;

			piiKeys.forEach( function( key ) {

				// grab the param value from the url for this PII key
				// "/page.jsp?blah=blah&phone=1234567" => "1234567"
				var maskValue = LC.helpers.getQueryParam( key, maskedOutput );

				// if the maskValue exists then lets mask it!
				if ( typeof maskValue !== "undefined" ) {
					maskedOutput = maskedOutput.replace( maskValue, _this.mask );
				}

			});

			return maskedOutput;

		};

		/**
		 * fire a pageview for GA
		 * @param   {Event}   e                 redundant pubsub event
		 * @param   {Object}  data              the pageview data from the pubsub
		 * @param   {String}  data.pageName     the pageName for the pageview
		 * @param   {String}  data.version      which version of the pageview to fire (default; both)
		 */
		_this.pageview = function( e, data ) {

			data = data || {};

			if ( !data.pageName ) {

				LC.warn( "pageview called with no 'pageName', setting to window location" );
				data.pageName = LC.location.pathname + LC.location.search + LC.location.hash;

			}

			var maskedPageName = _this.maskedContent( data.pageName );

			// because GA4 will fire page_view twice if we do the pageview.jsp,
			// we need to check which version of the pageview to fire
			// (default; both)

			if ( data.version === "ua" ) {

				ga( "send", "pageview", maskedPageName ); // ua

			} else if ( data.version === "ga4" ) {

				gtag( "event", "page_view", { page_title: maskedPageName }); // ga4

			} else {

				gtag( "event", "page_view", { page_title: maskedPageName }); // ga4
				ga( "send", "pageview", maskedPageName ); // ua

			}

		};

		/**
		 * fire a tracking event for GA
|		 * @param   {Event}   e      Event Object from jQuery
|		 * @param   {Object}  data   the event data from the pubsub
		 * ga4 special event data:
		 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
		 * @param   {string}  data.eventName the event name for GA4 Special Events
		 * @param   {string}  data.eventData the event parameters for GA4 Special Events
		 * classic custom event data:
		 * @param   {string}  data.category  the event category
		 * @param   {string}  data.action    the event action
		 * @param   {string}  data.label     the event label
		 * @param   {integer} data.value     the event value
		 */
		_this.event = function( e, data ) {

			if ( data ) {

				var eventName = 		data.eventName,
					eventData = 		data.eventData,
					category = 			data.category,
					action = 			data.action,
					label = 			data.label,
					value = 			data.value || 0,
					fieldsObject = 		data.fieldsObject || {};

				if ( category && action && typeof label !== "undefined" ) {

					// if the event data looks like a traditional custom event,
					// then just perform a standard event tracking

					gtag( "event", action, {
						event_category: category,
						event_label: label,
						value: value
					}); // ga4

					ga( "send", "event", category, action, label, value, fieldsObject ); // ua

					LC.info( "GA4 and UA event fired: " + action + " with data:", category, label, value, fieldsObject );

				} else if ( eventName ) {

					// otherwise if the event data only has action,
					// but no category or label, then we assume it's a GA4 event
					// and just pass all the event data through

					gtag( "event", eventName, eventData ); // ga4
					LC.info( "GA4 event fired: " + eventName + " with data:", eventData );

				}

			} else {

				LC.warn( "event tracking called with missing options" );

			}

		};

		/**
		 * fire a social event for GA
		 * @param  {Event}  e    | Event Object from jQuery
		 * @param  {Object} data | the social event data from the pubsub
		 */
		_this.social = function( e, data ) {

			if ( data && data.network && data.action && data.path ) {

				var network = 			data.network,
					action = 			data.action,
					path = 				data.path;

				// ga4 does't support tracking social specially,
				// so we'll just use events tacking instead
				_this.event({
					category: "social",
					action: action,
					label: network,
					value: path
				});

				ga( "send", "social", network, action, path ); // ua


			} else {

				LC.warn( "social tracking called with missing options" );

			}

		};

		/**
		 * Method to handle SNS Events, will just
		 * fire a google event for social sign in
		 * @param  {Event}  e    | Event Object from jQuery
		 * @param  {Object} data | Data Object with action/category values
		 */
		_this.snsEvent = function( e, data ) {

			if ( data && data.action ) {

				_this.event( e, {

					category: 	"SignIn/Registration",
					action: 	data.action,
					label:  	data.label

				});

			} else {

				LC.warn( "sns event tracking called with bad arguments" );

			}

		};

		/**
		 * Method for looping through a SNS Error object, and
		 * sending multiple GA events for each error in the form.
		 * @param  {Event}  e    | Event Object from jQuery
		 * @param  {Object} data | Data Object with form-error response and action value
		 */
		_this.snsError = function( e, data ) {

			if ( data && data.response ) {

				_.each( data.response.errorList, function( key, error ) {

					_this.event( e, {

						category: 	"SignIn/Registration",
						action: 	"Error: " + data.action,
						label: 		error.errorCode

					});

				});

			} else {

				LC.warn( "sns error tracking called with bad arguments" );

			}

		};

		/**
		 * user timing for real user time tracking in google analytics
		 * @param  { Object } e    | Event Object from jQuery
		 * @param  { Object } data | Data Object with name and value of timing
		 */
		_this.timing = function( e, data ) {

			if ( typeof window.performance === "undefined" ||
				typeof window.performance.now === "undefined" ) {

				LC.warn( "browser doesn't support performance timing" );

			} else if ( data && data.category && data.name ) {

				var category = data.category,
					name = data.name,
					time = data.time || window.performance.now();

				gtag( "event", "timing_complete", {

					timing_category: category,
					timing_label: name,
					timing_value: Math.round(time)

				}); // ga4

				ga( "send", "timing", category, name, Math.round( time ) ); // ua


			} else {

				LC.warn( "user timing called with missing options" );

			}

		};

		ex.lazySubscribe = function() {

			LC.track
				.on( "pageview ga.pageview", 	_this.pageview )
				.on( "event ga.event", 			_this.event )
				.on( "timing ga.timing", 		_this.timing )
				.on( "social ga.social", 		_this.social )
				.on( "sns.event",				_this.snsEvent )
				.on( "sns.error",				_this.snsError );

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * tracking -> hotjar
 *
 */

( function( LC ) {

	"use strict";

	LC.tracking = LC.tracking || {};

	LC.tracking.hotjar = lcmodule( function( exports ) {

		exports.moduleName = "tracking.hotjar";

		var ex = exports = $.extend( {}, exports );

		/**
		 * make sure HotJar is available on the window
		 * object so we can trigger events on it.
		 */
		window.hj = window.hj || function() {
			( hj.q = hj.q || [] ).push(arguments);
		};

		/**
		 * initialise the module
		 */
		ex.init = function() {
			ex.subscribe();
		};

		/**
		 * fire hotjar event for tracking that a form
		 * submitted successfully
		 * https://docs.hotjar.com/v1.0/docs/using-javascript-to-handle-form-submissions
		 */
		ex.formSuccess = function() {
			hj( "formSubmitSuccessful" );
		};

		/**
		 * fire hotjar event for tracking that a form
		 * failed to submit successfully
		 * https://docs.hotjar.com/v1.0/docs/using-javascript-to-handle-form-submissions
		 */
		ex.formFail = function() {
			hj( "formSubmitFailed" );
		};

		ex.subscribe = function() {

			LC.pubsub.on("forms.success", ex.formSuccess );
			LC.pubsub.on("forms.fail", ex.formFail );

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * tracking -> core
 *
 * for releasing the tracking buffer, and also
 * for setting up subscriptions and method-names
 * for tracking.
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.tracking = LC.tracking || {};

	LC.tracking.core = lcmodule( function( exports ) {

		exports.moduleName = "tracking.core";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, exports ),
			_this = {};

		/**
		 * initialise the core module
		 */

		ex.init = function() {

			_this.releaseBuffer();

		};

		/**
		 *
		 * The relaseBuffer() method will loop through the buffer.
		 * For each item inside we figure out the tracking event type
		 * ( which should be the object-key ) and fire a core-method
		 * with the same name.
		 *
		 */
		_this.releaseBuffer = function() {

			if ( typeof LC.tracking.buffer !== "undefined" && _.isArray( LC.tracking.buffer ) ) {

				LC.info( "releasing the tracking buffer for consumption", LC.tracking.buffer );

				_.each( LC.tracking.buffer, function( item ) {

					// get the property key
					var trackingType = _.keys( item )[ 0 ],
						trackingMethod,
						trackingArguments;

					if ( typeof trackingType === "string" ) {

						// which method to fire
						trackingMethod = ex[ trackingType ];
						// collect the arguments
						trackingArguments = item[ trackingType ];
						// fire method with arguments
						trackingMethod( trackingArguments );

					}

				});

				LC.tracking.buffer = [];

			}

		};

		/**
		 * the pageview tracking method.
		 * @param  { Object } data  | object of data for the pageview
		 *                  		| should be in format:
		 *                  		| { pageName: "x" }
		 */
		ex.pageview = LC.tracking.pageview = function( data ) {

			// LC.track is a pubsub specifically for tracking.
			LC.track.trigger( "pageview", data );

		};


		/**
		 * the event tracking method.
		 * @param  { Object } data  | object of data for the event
		 *                  		| should be in format:
		 *                  		| { category: "x", action: "x", label: "x" }
		 */
		ex.event = LC.tracking.event = function( data ) {

			// LC.track is a pubsub specifically for tracking.
			LC.track.trigger( "event", data );

		};


		/**
		 * the social tracking method.
		 * @param  { Object } data  | object of data for the social
		 *                  		| should be in format:
		 *                  		| { network: "x", action: "x", path: "x" }
		 */
		ex.social = LC.tracking.social = function( data ) {

			// LC.track is a pubsub specifically for tracking.
			LC.track.trigger( "social", data );

		};


		/**
		 * the timing tracking method.
		 * @param  { Object } data  | object of data for the timing
		 *                  		| should be in format:
		 *                  		| { category: "x", name: "x", time: 123456 }
		 */
		ex.timing = LC.tracking.timing = function( data ) {

			// LC.track is a pubsub specifically for tracking.
			LC.track.trigger( "timing", data );

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 * set up a core method and execute it instantly, so at
	 * any point in the future we can re-run "LC.jsTrack()" to
	 * re-bind the delegated click events. This is just incase
	 * we ever replace the <main> DOM element's content.
	 */
	( LC.jsTrack = function() {

		// get the main element to delegate tracking from;
		var $main = $("#main, main").first();

		// set up the click event binding, delegated on
		// any element with ".js-track" className.
		$main
			.off(".lcTrackable")
			.on("click.lcTrackable", ".js-track", function() {

				var $track = $(this),
					trackData = $track.data(),
					mainData = $main.data(),
					category,
					action,
					label,
					value = trackData.value,
					canTrack = true;

				/**
				 * if the clicked element has a data-category, we use that,
				 * otherwise we try to get the data-category from the #main element.
				 */
				if ( trackData.category ) {

					category = trackData.category;

				} else if ( mainData.category ) {

					category = mainData.category;

				} else {

					LC.warn( "Cannot execute 'lcTrackable' on ",
						$track, "without a data-category" );

					canTrack = false;

				}

				/**
				 * if the clicked element has an data-action, we use that.
				 */
				if ( trackData.action ) {

					action = trackData.action;

				} else {

					LC.warn( "Cannot execute 'lcTrackable' on ", $track, "without a data-action" );
					canTrack = false;

				}

				/**
				 * if the clicked element has a data-label, we use that,
				 * otherwise we try to get the data-label from the #main element.
				 */

				if ( trackData.label ) {

					label = trackData.label;

				} else if ( $track.attr( "title" ) ) {

					label = $track.attr( "title" );

				} else {

					LC.warn( "Cannot execute 'lcTrackable' on", $track, "without a data-label" );
					canTrack = false;

				}

				/**
				 * fire google analytics tracking event
				 */
				if ( canTrack ) {

					LC.track.trigger( "ga.event", {

						category: category,
						action: action,
						label: label,
						value: value

					});

				}

			});

	})();

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> header -> account
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "nav.account", function( exports, _this ) {

		var ex = exports;

		/**
		 * first check that the user is logged in, no point to
		 * load the account module if not signed in.
		 */

		if (
		    typeof LC.config.getValue === "undefined" ||
			LC.config.getValue("profile.isLogin") === false
		) {

			return false;

		}

		ex.mobile = {};
		_this.mobile = {};
		ex.tablet = {};
		_this.tablet = {};

		ex.setup = function() {

			_this.isOpen = false;

			ex.openSpeed = ex.speed;
			ex.closeSpeed = ex.speed;

		};

		ex.init = function() {

			_this.$body = 		$("body");
			_this.$header = 	_this.$body.find(".site-header");
			_this.$form = 		$("#logoutForm");

			_this.successUrl = 	_this.$form.find(".logoutSuccessURL").val();

			/**
			 * run the proper device init method
			 */
			if ( Foundation.MediaQuery.atLeast("tb") ) {

				ex.tablet.init();

			} else {

				ex.mobile.init();

			}

			/**
			 * when the screen size changes, handle the
			 * difference init methods.
			 */
			$( window ).on("changed.zf.mediaquery", function( e, now, previous ) {

				if ( now !== "mb" && previous === "mb" ) {

					ex.mobile.destroy();
					ex.tablet.init();

				} else if ( now === "mb" ) {

					ex.tablet.destroy();
					ex.mobile.init();

				}

			});

		};

		_this.signout = function() {

			// open global preloader

			// store ajax signout action
			var signout = $.ajax({

				url: _this.$form.attr("action"),
				data: _this.$form.serialize(),
				type: "POST"

			});

			// use stored ajax promise to load the signout page.
			signout.always(function() {

				// GA4 Special Event
				LC.track.trigger( "ga.event", {
					eventName: "login",
					eventData: { method: "header" }
				});

				window.location.href =
					URI( _this.successUrl )
						.addSearch( "globalSignOut", "true" )
						.toString();

			});

		};

		_this.mobile.getHeight = function() {

			// un-set the css height attr, because we need the menu
			// to be open to calc the correct height.

			return _this.mobile.$usermenu
				.css("height", "")
				.outerHeight();

		};

		ex.mobile.init = function() {

			_this.mobile.$account = 		_this.$header.find(".flyout-account__header");
			_this.mobile.$arrow = 			_this.mobile.$account.find(".account-title__arrow");
			_this.mobile.$usermenu = 		_this.$header.find(".flyout-account__content");
			_this.mobile.$signoutButton = 	$("#signOutLink");

			_this.mobile.userMenuHeight = 	_this.mobile.getHeight();
			ex.mobile.close();

			ex.mobile.events();
			ex.mobile.subscribe();

		};

		ex.mobile.open = function() {

			// use css to open the account menu
			_this.mobile.$usermenu.css({
				height: _this.mobile.userMenuHeight + "px"
			});

			_this.mobile.$account.addClass( ex.openClass );
			_this.isOpen = true;

		};


		ex.mobile.close = function() {

			// use css to animate user menu closing, we set to 1px because safari
			// has some strange bug meaning that when set to 0 it doesn't repaint
			// the UI and looks retarded.

			_this.mobile.$usermenu.css({
				height: "1px"
			});

			_this.mobile.$account.removeClass( ex.openClass );
			_this.isOpen = false;

		};

		ex.mobile.toggle = function() {

			if ( _this.isOpen ) {

				ex.mobile.close();

			} else {

				ex.mobile.open();

			}

		};

		ex.mobile.events = function() {

			_this.mobile.$account
				.on("click.globalaccount", function(e) {

					e.preventDefault();
					ex.mobile.toggle();


				});

			_this.mobile.$signoutButton
				.on("click.globalaccount", function(e) {

					e.preventDefault();
					_this.signout();


				});


			// prevent user from following the link on the <a> tag.
			// this can happen on long-presses of the $myaccount toggle.
			_this.mobile.$account
				.children("a")
				.on("click.globalaccount", function(e) {
					e.preventDefault();
				});

		};

		ex.mobile.subscribe = function() {

		};

		ex.mobile.destroy = function() {

			_this.mobile.$account
				.add( _this.mobile.$signoutButton )
				.add( _this.mobile.$account.children("a") )
				.off( ".globalaccount" );

			ex.mobile.close();

		};




		ex.tablet.init = function() {

			_this.tablet.$account = 		_this.$header.find(".iconbar__item--myaccount");
			_this.tablet.$accountLink = 	_this.tablet.$account.find(".iconbar__item-inner");
			_this.tablet.$modal = 			_this.$header.find(".header-my-account");
			_this.tablet.$signoutButton = 	$("#header-signout-btn");

			ex.tablet.close();

			ex.tablet.events();
			ex.tablet.subscribe();

		};

		ex.tablet.open = function() {

			_this.tablet.$modal.slideDown( ex.openSpeed );
			_this.tablet.$account.addClass( ex.activeClass );
			_this.isOpen = true;

			LC.pubsub.trigger("closeAllModals", { not: "globalaccount" });

			// adds handler to body for closing
			// we add it here to improve performance across
			// the app when clicking on body.
			_this.bodyEvent();

		};

		ex.tablet.close = function() {

			_this.tablet.$modal.slideUp( ex.openSpeed );
			_this.tablet.$account.removeClass( ex.activeClass );
			_this.isOpen = false;

			_this.removeBodyEvent();

		};

		ex.tablet.toggle = function() {

			if ( _this.isOpen ) {

				ex.tablet.close();

			} else {

				ex.tablet.open();

			}

		};

		ex.tablet.events = function() {

			_this.tablet.$accountLink
				.off(".globalaccount")
				.on("click.globalaccount", function(e) {

					e.preventDefault();
					ex.tablet.toggle();

				});

			_this.tablet.$signoutButton
				.off(".globalaccount")
				.on("click.globalaccount", function(e) {

					e.preventDefault();
					_this.signout();

				});

		};

		_this.bodyEvent = function() {

			_this.$body
				.off(".globalaccount")
				.on("click.globalaccount", function(e) {

					var $target = $( e.target );

					// if we did _not_ click on the popup, or any
					// descendants, then close it.

					if ( _this.isOpen &&
						!$target.is( _this.tablet.$account ) &&
						!$.contains( _this.tablet.$account.get(0), $target.get(0) ) ) {

						ex.tablet.close();

					}

				});

		};

		_this.removeBodyEvent = function() {

			// remove the body event if it triggered.
			_this.$body.off( ".globalaccount" );

		};

		ex.tablet.destroy = function() {

			_this.$body
				.add( _this.tablet.$signoutButton )
				.add( _this.tablet.$accountLink )
				.off( ".globalaccount" );

			ex.tablet.close();

		};

		ex.tablet.subscribe = function() {

			LC.pubsub.on( "closeAllModals", function( e, data ) {
				if (( !data ) || ( data && !data.not.includes("globalaccount") )) {
					ex.tablet.close();
				}
			});

		};

		ex.subscribe = function() {

			LC.pubsub.on( "account.globalsignout", _this.signout );

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> header -> megamenu
 *
 */

( function( LC, $ ) {

	"use strict";

	/**
	 * first check that the user is logged in, no point to
	 * load the account module if not signed in.
	 */

	LC.nav = LC.nav || {};

	LC.nav.megamenu = lcmodule( function( exports ) {

		exports.moduleName = "nav.megamenu";

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports ),
			_this = {};

		// objects to hold mobile data/methods
		ex.mobile = {};
		_this.mobile = {};

		// objects to hold tablet data/methods
		ex.tablet = {};
		_this.tablet = {};

		// objects to hold desktop data/methods
		ex.desktop = {};
		_this.desktop = {};




		// private variables / methods

		_this.$html = 		$("html");
		_this.$body = 		$("body");
		_this.$header = 	_this.$body.children(".site-header");
		_this.$nav = 		$("nav").filter(".navigation");
		_this.$mega = 		_this.$nav.children( $(".megamenu") );

		_this.$buContainers = $(".megamenu__bu-container");
		_this.$buItems = $(".megamenu__bu-item");
		_this.$sectionItems = $(".megamenu__section-item");
		_this.$itemItems = $(".megamenu__item-item");

		_this.$sections = $(".megamenu__section");
		_this.$columns = $(".megamenu__column");




		_this.trackOpenMenu = function( key ) {

			// check if this menu Key was opened already on this page, and
			// either trigger the open, or re-open menu event.

			if ( !_this.menuOpened[ key ] ) {

				LC.pubsub.trigger( "megamenu.openMenu", key );
				_this.menuOpened[ key ] = true;

			} else {

				LC.pubsub.trigger( "megamenu.reopenMenu", key );

			}

		};

		_this.navTime = function( type ) {

			var elapsed = _this.groupTimes(),
				trigger = "";

			if ( elapsed ) {

				if ( type === "image" ) {

					trigger = ".timeToClickImage";

				} else {

					trigger = ".timeToNavigate";

				}

				LC.pubsub.trigger( "megamenu" + trigger, elapsed );

			}

		};

		_this.closeTime = function() {

			var elapsed = _this.groupTimes();
			if ( elapsed ) {
				LC.pubsub.trigger( "megamenu.timeToClose", elapsed );
			}

		};

		_this.groupTimes = function() {

			if ( _this.startTime && _this.endTime ) {

				var elapsed = (_this.endTime - _this.startTime) / 1000;

				if ( elapsed < 1 ) {
					return "< 1 second";

				} else if ( elapsed <= 3 ) {
					return "1 - 3 seconds";

				} else if ( elapsed <= 5 ) {
					return "3 - 5 seconds";

				} else if ( elapsed <= 10 ) {
					return "5 - 10 seconds";

				} else if ( elapsed <= 15 ) {
					return "10 - 15 seconds";

				} else if ( elapsed <= 25 ) {
					return "15 - 25 seconds";

				} else if ( elapsed <= 40 ) {
					return "25 - 40 seconds";

				} else if ( elapsed <= 60 ) {
					return "40 - 60 seconds";

				} else if ( elapsed <= 90 ) {
					return "60 - 90 seconds";

				} else if ( elapsed <= 120 ) {
					return "90 - 120 seconds";

				} else {
					return "> 2 minutes";
				}

			}

			return false;

		};

		/**
		 * Return us a nice clean url for use in comparing the current
		 * window location with each menu link.
		 *
		 * @param  {string} url // either supply a url, or use window.location.href
		 * @return {string}     // returns a url without the host or clickFromTopNav.
		 */
		_this.getCleanURL = function( url ) {

			url = url || window.location.href;

			var clean = new URI( url ).removeSearch( "clickFromTopNav" ),
				path = clean.path(),
				query = clean.query();

			return URI.build({ path: path, query: query });

		};

		/**
		 * Takes a menu item / section as argument and will store
		 * the needed data in to the localStorage for later use.
		 * @param  {jQuery Object} $item // either an item, or section from megamenu
		 */
		_this.saveEndpoint = function( $item ) {

			var data = $item.data("section") || $item.data("item") || null;

			try {

				LC.localStorage.setItem("megamenu_endpoint", data );

			} catch ( e ) {

				LC.warn( "Couldn't save to localStorage, megamenu endpoint not saved." );
				LC.error( e );

			}

		};

		/**
		 * returns the stored "endpoint" value, which is created
		 * with the "saveEndpoint() method"
		 * @return {string} // localStorage value.
		 */
		_this.getEndpoint = function() {

			return LC.localStorage.getItem("megamenu_endpoint");

		};




		ex.init = function() {

			// for tracking which menus were opened
			_this.menuOpened = {};

			ex.events();
			ex.subscribe();

			if ( Foundation.MediaQuery.atLeast("tb") ) {

				ex.tablet.init();

				if ( Foundation.MediaQuery.atLeast("dt") ) {

					ex.desktop.init();

				}

			} else {

				ex.mobile.init();

			}

			$( window ).on("changed.zf.mediaquery", function( e, now, previous ) {

				// initialise tablet+ display if the current
				// view is NOT mobile.
				if ( now !== "mb" && previous === "mb") {

					ex.mobile.destroy();
					ex.tablet.init();

				// initialise mobile display if the current
				// view is mobile.
				} else if ( now === "mb" ) {

					ex.tablet.destroy();
					ex.mobile.init();

				}

				// if the current view is larger than
				// tablet, then let's place the sections for
				// desktop view.
				if ( now === "dt" || now === "hg" ) {

					ex.placeSections("dt");

				// if the current view is tablet, and the
				// previous view was desktop+, then we
				// will place sections in tablet format.
				} else if ( now === "tb" && previous === "dt" || previous === "hg" ) {

					ex.placeSections("tb");

				}

			});

		};

		/**
		 * Loop through all the menu items and highlight the one which
		 * is the same as the current URL && has the same saved endpoint.
		 * We do this because there could be duplicate URLs in the menus, so
		 * we want to be sure (if we ever can) that the link we clicked on before
		 * is the one we're highlighting now.
		 */
		ex.highlightCurrent = function() {

			var url = _this.getCleanURL(),
				endpoint = _this.getEndpoint(),
				$highlight = $();

			_this.$sectionItems
				.add( _this.$itemItems )
				.each( function() {

					var $this = $(this),
						$a = $this.children("a"),
						data = $this.data("section") || $this.data("item"),
						aurl = _this.getCleanURL( $a.attr("href") );

					if ( url === aurl ) {
						if ( endpoint ) {
							if ( endpoint === data ) {
								$highlight = $highlight.add($a);
							}
						} else {
							$highlight = $highlight.add($a);
						}
					}

				});

			$highlight
				.first()
				.addClass( ex.activeClass );

		};

		/**
		 * position the nav in the correct place, necessary so
		 * the menu can be displayed differently for mobile/desktop.
		 */
		ex.placeNav = function( options ) {

			options = options || { type: "mb" };

			if ( options.type === "mb" ) {

				_this.$mega.appendTo( _this.mobile.$navHolder );

			} else {

				_this.$mega.prependTo( _this.$nav );

			}

		};

		/**
		 * place the sections in to the correct columns on
		 * tablet/desktop view. Should not apply to Mobile view.
		 */
		ex.placeSections = function( view ) {

			var type = view === "tb" ? "tablet-column" : "desktop-column";

			_this.$sections.each( function() {

				var $this = $(this),
					column = $this.data( type );

				$this
					.closest( _this.$buContainers )
					.children( _this.$columns )
					.filter(".megamenu__column--" + column )
					.append( $this );

			});

		};


		ex.events = function() {


		};

		ex.subscribe = function() {


		};



		ex.mobile.init = function() {

			_this.mobile.isOpen = false;

			_this.mobile.$flyout = 		_this.$header.find(".mobile-flyout");
			_this.mobile.$heading = 	_this.mobile.$flyout.find(".flyoutbar__header");
			_this.mobile.$back = 		_this.mobile.$flyout.find(".flyoutbar__item--back a");
			_this.mobile.$navHolder = 	_this.mobile.$flyout.find(".mobile-flyout__nav-holder");

			_this.mobile.$links =		$(".megamenu__bu-link, " +
										  ".megamenu__section-link, " +
										  ".megamenu__item-link");

			_this.mobile.$hamburger = 	_this.$header.find(".flyoutbar__item--hamburger, " +
														   ".iconbar__item--hamburger");

			// "[data-open-bu] can be applied to any element
			// on a page, and it will auto-open the mobile menu to the
			// corresponding BU.
			_this.mobile.$buLink = 		$("[data-open-bu]");

			_this.mobile.$level1 = 		_this.mobile.$flyout;
			_this.mobile.$level2 = 		$(".megamenu__bu-container");
			_this.mobile.$level3 = 		$(".megamenu__item");

			ex.placeNav({ type: "mb" });

			_this.mobile.handleOverflow();
			_this.mobile.setPanelHeading();

			ex.mobile.events();
			ex.mobile.subscribe();

		};

		_this.mobile.clickItem = function( e ) {

			var $link = $(this),
				$target = $(),
				loc = $link.attr("href"),
				anyVisible = false,
				target,
				level;

			/* this is important to stop the link being clicked */
			e.preventDefault();

			/* check for any sections under this business unit */
			if ( $link.hasClass("megamenu__bu-link") ) {

				level = 2;
				target = $link.parent().data("bu");
				$target = $(".megamenu__bu-container[data-bu-target=" + target + "]");

			/* check for any items under this section */
			} else if ( $link.hasClass("megamenu__section-link") ) {

				level = 3;
				target = $link.parent().data("section");
				$target = $(".megamenu__item[data-section-target=" + target + "]");

			}

			/* are any of the items in this menu visible on mobile? */
			$target.children().each(function() {
				if ( $(this).data("show-on-mobile") !== false ) {
					anyVisible = true;
				}
			});

			/**
			 * we just checked the clicked link to see if it contains
			 * a target sub-menu, if that submenu exists then:
			 */
			if ( $target.length && anyVisible ) {

				/* just to be safe, close any open sub-levels */
				_this.mobile.closeLevel( level );

				/* open the target */
				$target.addClass( ex.openClass );

				/* set the panel heading and overflow values */
				_this.mobile.setPanelHeading( "open" );
				_this.mobile.handleOverflow();

				_this.trackOpenMenu( target );

			} else if ( loc !== "" && loc !== "#" ) {

				/**
				 * if there was no target sub-menu to open, then
				 * navigate to the given url.
				 */

				// used for calculating time from open to close/navigate
				_this.endTime = new Date().getTime();
				_this.navTime();

				target =
					$link.parent().data("bu") ||
					$link.parent().data("section") ||
					$link.parent().data("item") ||
					$link.attr("href");

				LC.pubsub.trigger( "megamenu.navigate", target );

				_this.mobile.endpoint( $link );
				LC.pubsub.trigger("preloader.open");

			}

		};

		_this.mobile.closeLevel = function( level ) {

			if ( typeof level === "number" ) {

				if ( level === 1 ) {

					_this.mobile.isOpen = false;

				}

				while ( level <= 3 ) {

					_this.mobile[ "$level" + level ].removeClass( ex.openClass );
					level++;

				}

			} else if ( level === "all" ) {

				_this.mobile.$level2
					.add( _this.mobile.$level3 )
					.removeClass( ex.openClass );

				ex.mobile.close();

			}

			_this.mobile.resetScroll();

		};

		/**
		 * Method to set the endpoint and navigate to it
		 * @param  {jQuery Object} $a // the <a> tag which we clicked on
		 */
		_this.mobile.endpoint = function( $a ) {

			_this.saveEndpoint( $a.parent() );
			window.location = $a.attr("href");

		};

		/**
		 * Helper function to decide which menu level is currently open;
		 * used for closing or naming levels mostly
		 * @return {number}
		 */
		_this.mobile.getOpenLevel = function() {

			if ( _this.mobile.$level3.hasClass( ex.openClass ) ) {

				return 3;

			} else if ( _this.mobile.$level2.hasClass( ex.openClass ) ) {

				return 2;

			} else if ( _this.mobile.$level1.hasClass( ex.openClass ) ) {

				return 1;

			} else {

				return false;

			}

		};

		_this.mobile.goBack = function() {

			var open = _this.mobile.getOpenLevel(),
				type = ( open === 1 ) ? null : "close";

			_this.mobile.closeLevel( open );

			_this.mobile.handleOverflow();
			_this.mobile.setPanelHeading( type );

		};

		/**
		 * goToBu() allows easy API access to opening the first level (business unit)
		 * of the navigation. Accepts either an Array of BUs (for cross-site) or a string
		 * and will open the corresponding BU by "BCC NAME" from BCC.

		 * @param  {string/Array} bu // correponds to the BCC_NAME of a BU from BCC.
		 */
		_this.mobile.goToBU = function( bu ) {

			// a helper function for triggering the BU
			// without repeating code.

			var i = bu.length,
				openBu = function( unit ) {

					if ( _this.mobile.$level2.filter( "[data-bu-target=" + unit + "]" ).length ) {

						ex.mobile.open();

						_this.mobile.$level3
							.removeClass( ex.openClass );

						_this.mobile.$level2
							.removeClass( ex.openClass )
							.filter( "[data-bu-target=" + unit + "]" )
							.addClass( ex.openClass );

						_this.mobile.handleOverflow();
						_this.mobile.setPanelHeading();

						_this.trackOpenMenu( unit );

					}

				};



			if ( $.type(bu) === "array" ) {

				while ( i-- ) {
					openBu( bu[ i ] );
				}

			} else if ( typeof bu === "string" ) {

				openBu( bu );

			}

		};

		/**
		 * A method to set the correct overflow values on the body/html and
		 * submenus in the navigation, this is used because otherwise we will
		 * get ugly scrolling glitches in ios when the menu is scrolled.
		 */
		_this.mobile.handleOverflow = function() {

			var level = _this.mobile.getOpenLevel(),
				oflow = "is-locked";

			_this.$html
				.add( _this.$body )
				.toggleClass( oflow, level >= 1 );

			_this.mobile.$level1.toggleClass( oflow, level > 1 );
			_this.mobile.$level2.toggleClass( oflow, level > 2 );

		};

		_this.mobile.resetScroll = function() {

			_this.$html
				.add( _this.$body )
				.add( _this.mobile.$level1 )
				.add( _this.mobile.$level2 )
				.add( _this.mobile.$level3 )
				.filter( "." + ex.openClass )
				.scrollTop( 0 );

		};

		_this.mobile.setPanelHeading = function( direction ) {

			var heading = _this.mobile.$heading.data("menu"),
				level = _this.mobile.getOpenLevel(),
				target, inClass, outClass;

			if ( level === 2 ) {

				target =
					_this.mobile.$level2
						.filter("." + ex.openClass )
						.data("bu-target");

				heading =
					_this.$buItems
						.filter("[data-bu=" + target + "]")
						.children(".megamenu__bu-link")
						.text()
						.trim();

			} else if ( level === 3 ) {

				target =
					_this.mobile.$level3
						.filter("." + ex.openClass )
						.data("section-target");

				heading =
					_this.mobile.$level2
						.filter("." + ex.openClass )
						.find( _this.$sectionItems )
						.filter("[data-section=" + target + "]")
						.children(".megamenu__section-link")
						.first()
						.clone()
						.children(".megamenu__section-bar, .megamenu__section-seeall")
						.remove()
						.end()
						.text()
						.trim();

			}



			/**
			 * if no direction given, just change the text,
			 * otherwise set some css classes for animating.
			 */

			if ( !direction ) {

				_this.mobile.$heading.text( heading );

			} else if ( direction === "open" ) {

				inClass = "anim-in";
				outClass = "anim-out";

			} else if ( direction === "close" ) {

				inClass = "anim-in reverse";
				outClass = "anim-out reverse";

			}




			if ( typeof inClass !== "undefined" ) {

				/* now actually do the animation */

				_this.mobile.$heading
					.removeClass( inClass )
					.removeClass( outClass )
					.addClass( outClass );

				setTimeout(function() {

					_this.mobile.$heading
						.text( heading )
						.removeClass( outClass )
						.addClass( inClass );

					setTimeout(function() {

						_this.mobile.$heading
							.removeClass( inClass );

					}, 20 );

				}, 100 );

			}

		};

		ex.mobile.close = function() {

			_this.mobile.$flyout.removeClass( ex.openClass );

			_this.mobile.isOpen = false;
			_this.mobile.handleOverflow();
			_this.mobile.setPanelHeading();
			_this.mobile.resetScroll();

			LC.pubsub.trigger( "megamenu.closeMenu", "Top Menu" );

		};

		ex.mobile.open = function() {

			_this.mobile.$flyout.addClass( ex.openClass );

			// this chunk is for the A-Z page, where there's a twitter
			// dropdown, we should hide it before we open the menu.

			if ( $(".tt-dropdown-menu").length ) {

				$(".tt-dropdown-menu").hide();
				$(".tt-suggestions").hide();
				$(".tt-input").blur();

			}

			_this.mobile.isOpen = true;
			_this.mobile.handleOverflow();
			_this.mobile.setPanelHeading();

			// used for calculating time from open to close/navigate
			_this.startTime = new Date().getTime();
			_this.trackOpenMenu( "Top Menu" );

		};

		ex.mobile.toggle = function() {

			if ( _this.mobile.isOpen ) {
				ex.mobile.close();
			} else {
				ex.mobile.open();
			}

		};

		ex.mobile.events = function() {

			_this.mobile.$hamburger
				.on( "click.megamenu", function(e) {

					e.preventDefault();
					LC.pubsub.trigger("megamenu.hamburger");
					ex.mobile.toggle();

				});

			_this.mobile.$back
				.on( "click.megamenu", function(e) {

					e.preventDefault();
					_this.mobile.goBack();

				});

			/**
			 * When a user clicks on a [data-open-bu] link, use the data (string, or array)
			 * to open the corresponding business unit mobile menu.
			 *
			 * The data-attribute should be either a single string to open a single business
			 * unit, or more commonly an array of strings with each string being the
			 * region-specific business unit's "BCC NAME"
			 *
			 * eg:
						data-open-bu="['hk-men', 'cn-men', 'row-men']"
			 *
			 */
			_this.mobile.$buLink.on( "click.megamenu", function(e) {

				_this.mobile.goToBU( $(this).data("open-bu") );
				e.preventDefault();

			});

			_this.mobile.$links
				.on( "click.megamenu", function(e) {

					_this.mobile.clickItem.call( this, e );

				});

		};

		ex.mobile.subscribe = function() {

		};

		ex.mobile.destroy = function() {

			// unregister the events for mobile
			_this.mobile.$hamburger
				.add( _this.mobile.$back )
				.add( _this.mobile.$buLink )
				.add( _this.mobile.$links )
				.add( $(window) )
				.off( ".megamenu" );

			// and close the menu
			ex.mobile.close();

		};




		ex.tablet.init = function() {

			_this.tablet.hoverTime = 300;
			_this.tablet.intentY = 0;
			_this.tablet.intentPreviousY = 0;

			_this.tablet.$closeIcons = $(".megamenu__close-icon");
			_this.tablet.$buList = _this.$mega.find(".megamenu__bu");

			_this.tablet.createModalBg();
			_this.tablet.closeMenus();

			ex.placeNav({ type: "tb" });
			ex.placeSections( "tb" );

			ex.tablet.events();
			ex.tablet.subscribe();

		};

		_this.tablet.clickBU = function( bu ) {

			if ( bu ) {

				var $buItem = _this.$buItems.filter("[data-bu=" + bu + "]" ),
					$buContainer =
						_this.$buContainers.filter(".megamenu__bu-container--" + bu );


				if ( $buContainer.length &&
					!$buContainer.hasClass( ex.activeClass ) ) {

					// if the BU has a menu, and that menu is _not_ active currently,
					// then open that menu and close the others.

					_this.tablet.openSubMenu( bu );


				} else {

					// otherwise, trigger the hyperlink.

					LC.pubsub.trigger( "megamenu.navigate", bu );

					window.location = $buItem.children("a").attr("href");
					_this.tablet.closeMenus( true );

				}

			}

		};

		_this.tablet.closeMenus = function() {

			if ( typeof _this.tablet.$overlay !== "undefined" ||
				typeof _this.tablet.$fakeOverlay !== "undefined" ) {

				_this.tablet.$overlay
					.removeClass( ex.activeClass );

				_this.tablet.$fakeOverlay
					.removeClass( ex.activeClass );

			}

			_this.$mega
				.removeClass( ex.activeClass );

			_this.$buContainers
				.filter( "." + ex.activeClass )
				.each( function() {

					var $this = $(this),
						bu = $this.data("bu-target");

					$this
						.removeClass( ex.activeClass )
						.css("z-index", "");

					LC.pubsub.trigger( "megamenu.closeMenu", bu );

					// used for calculating time from open to close/navigate
					_this.endTime = new Date().getTime();
					_this.closeTime();

				});

			_this.$buItems.removeClass( ex.tempClass );

		};

		_this.tablet.containerHoverOut = function() {

			clearTimeout( _this.tablet.hoverTimer );

			_this.tablet.hoverTimer = setTimeout( function() {

				_this.tablet.closeMenus();

			}, _this.tablet.hoverTime );


		};

		_this.tablet.containerHoverIn = function() {

			clearTimeout( _this.tablet.hoverTimer );

		};

		_this.tablet.createModalBg = function() {

			if ( typeof _this.tablet.$overlay !== "undefined" &&
				typeof _this.tablet.$fakeOverlay !== "undefined" ) {

				_this.tablet.destroyModalBg();

			}

			if ( typeof _this.tablet.$overlay === "undefined" ||
				typeof _this.tablet.$fakeOverlay === "undefined" ) {

				_this.tablet.$overlay = 		$("<div class=\"megamenu-overlay\" />");
				_this.tablet.$fakeOverlay = 	$("<div class=\"megamenu-fake-overlay\" />");

			}

			_this.$body.append( _this.tablet.$overlay );
			_this.$header.prepend( _this.tablet.$fakeOverlay );

		};

		_this.tablet.destroyModalBg = function() {

			_this.tablet.$overlay.remove();
			_this.tablet.$fakeOverlay.remove();

		};

		/**
		 * method for detecting the intended direction of user's mouse
		 * @param  {object} 	e // event, passed from the mousemove handler
		 * @return {boolean}   	whether the user is tending downwards
		 */
		_this.tablet.mouseIntent = function( e ) {

			var goingDown = false;

			_this.tablet.intentY = e.pageY;

			if ( !_this.tablet.intentPreviousY ) {

				_this.tablet.intentPreviousY = _this.tablet.intentY;

			}

			goingDown = ( _this.tablet.intentY > _this.tablet.intentPreviousY );

			_this.tablet.intentPreviousY = _this.tablet.intentY;

			return goingDown;

		};

		_this.tablet.openSubMenu = function( bu ) {

			var $overlay = _this.tablet.$overlay,
				$fakeOverlay = _this.tablet.$fakeOverlay,
				$item = _this.$buItems.filter("[data-bu=" + bu + "]"),
				$container =
					_this.$buContainers.filter(".megamenu__bu-container--" + bu );


			// only open the menu if it isnt already open.

			if ( $container.length && !$container.hasClass( ex.activeClass ) ) {

				// used for calculating time from open to close/navigate
				_this.startTime = new Date().getTime();

				clearTimeout( _this.tablet.closeTimer );

				$overlay
					.addClass( ex.activeClass );

				$fakeOverlay
					.addClass( ex.activeClass );

				_this.$mega
					.addClass( ex.activeClass );

				_this.$buItems
					.removeClass( ex.tempClass )
					.filter( $item )
					.addClass( ex.tempClass );

				$container
					.addClass( ex.activeClass )
					.css("z-index", "10")
					.siblings()
					.css("z-index", "");


				// because the menu uses a css animation to hide
				// the menu, we need a little timer so there's not a strange
				// transparent fade.

				_this.tablet.closeTimer = setTimeout( function() {

					$container
						.siblings()
						.removeClass( ex.activeClass );

				}, 70 );


				LC.pubsub.trigger("closeAllModals", { not: "megamenu" });



				_this.trackOpenMenu( bu );

			} else if ( !$container.length ) {

				_this.tablet.closeMenus();

			}

		};

		/**
		 * openSubMenu() allows easy API access to opening the first level (business unit)
		 * of the navigation.
		 *
		 * @param  {string/Array} bu // correponds to the BCC_NAME of a BU from BCC.
		 */
		ex.tablet.openSubMenu = function( bu ) {

			var $overlay = _this.tablet.$overlay,
				$fakeOverlay = _this.tablet.$fakeOverlay,
				$item = _this.$buItems.filter("[data-bu=" + bu + "]"),
				$container =
					_this.$buContainers.filter(".megamenu__bu-container--" + bu );


			// only open the menu if it isnt already open.

			if ( $container.length && !$container.hasClass( ex.activeClass ) ) {

				// used for calculating time from open to close/navigate
				_this.startTime = new Date().getTime();

				clearTimeout( _this.tablet.closeTimer );

				$overlay
					.addClass( ex.activeClass );

				$fakeOverlay
					.addClass( ex.activeClass );

				_this.$mega
					.addClass( ex.activeClass );

				_this.$buItems
					.removeClass( ex.tempClass )
					.filter( $item )
					.addClass( ex.tempClass );

				$container
					.addClass( ex.activeClass )
					.css("z-index", "10")
					.siblings()
					.css("z-index", "");

				LC.pubsub.trigger( "bindLazy", { scope: $container });

				// because the menu uses a css animation to hide
				// the menu, we need a little timer so there's not a strange
				// transparent fade.

				_this.tablet.closeTimer = setTimeout( function() {

					$container
						.siblings()
						.removeClass( ex.activeClass );

				}, 70 );



				_this.trackOpenMenu( bu );

			} else if ( !$container.length ) {

				_this.tablet.closeMenus();

			}

		};




		ex.tablet.events = function() {

			_this.$buItems.on( "mouseover.megamenu", function() {

				var $this = $(this),
					closed = _this.$buContainers.filter( "." + ex.activeClass ).length < 1,
					open;

				// a helper function to accept a delay, which will
				// trigger a timeout. This timeout will open the sub-menu and trigger
				// the event after a delay.
				open = function( delay ) {

					clearTimeout( _this.tablet.hoverTimer );
					_this.tablet.hoverTimer = setTimeout(function() {

						_this.tablet.$buList.off("mousemove.megamenu");

						var bu = $this.data("bu");
						ex.tablet.openSubMenu( bu );

					}, delay );

				};


				// if all sub-menus are closed, then we just simply open the menu
				// after a delay.
				if ( closed ) {

					open( _this.tablet.hoverTime );

				// if a sub-menu is already open, then we are moving from one BU to another,
				// and we don't want to open the submenu of adjacent BUs if we are moving down
				// towards the current menu.
				} else {

					// first start opening the menu, with a fast time (100), if nothing else
					// happen, then it will just open the menu.
					open( 100 );

					// now bind a mousemove, and all the time we are moving down
					// towards the open menu, we reset the timeout and check again.
					_this.tablet.$buList.on("mousemove.megamenu", function( e ) {

						// figure out if we are moving down towards new menu or not.
						if ( _this.tablet.mouseIntent(e) ) {
							open( 100 );
						}

					});

				}

			});

			_this.$buItems.on( "mouseout.megamenu", function() {

				/**
				 * cancels the timer so the menu open event wont fire
				 * on a BU if we were still moving down with intent.
				 */
				clearTimeout( _this.tablet.hoverTimer );

			});

			_this.$buItems
				.children("a")
				.on( "click.megamenu", function(e) {

					e.preventDefault();

					var bu = $(this).parent().data("bu");
					_this.tablet.clickBU( bu );

				});


			// we must prevent the <a> for sections from triggering if the href is blank, or #...
			// because otherwise the menu will close and the page will refresh.

			_this.$sectionItems
				.add( _this.$itemItems )
				.on( "click.megamenu", "> a", function(e) {

					var loc = $(this).attr("href") || "";

					if ( loc === "" || loc === "#" ) {

						e.preventDefault();

					}

				});


			_this.$sectionItems.on( "click.megamenu", function() {

				var $this = $(this),
					loc = $this.children(".megamenu__section-link").attr("href") || "";

				if ( loc !== "" && loc !== "#" ) {

					_this.saveEndpoint( $this );
					_this.tablet.closeMenus();

					LC.pubsub
						.trigger( "megamenu.navigate", $this.data("section") )
						.off( "megamenu.closeMenu" );

				}

			});

			_this.$itemItems.on( "click.megamenu", function() {

				var $this = $(this),
					item = $this.data("item"),
					loc = $this.children(".megamenu__item-link").attr("href") || "";


				if ( loc !== "" && loc !== "#" ) {

					_this.saveEndpoint( $this );


					// used for calculating time from open to close/navigate
					_this.endTime = new Date().getTime();


					if ( $this.hasClass("megamenu__item-item--image") ) {

						_this.navTime("image");

						LC.pubsub
							.trigger( "megamenu.clickImage", item )
							.off( "megamenu.closeMenu" );

					} else {

						_this.navTime();
						LC.pubsub
							.trigger( "megamenu.navigate", item )
							.off( "megamenu.closeMenu" );

					}

					LC.pubsub
						.off( "megamenu.timeToClose" )
						.off( "megamenu.clickCloseIcon" );

				}

			});



			_this.tablet.$overlay.on( "mouseover.megamenu", _this.tablet.containerHoverOut );
			_this.$buContainers.on( "mouseover.megamenu", _this.tablet.containerHoverIn );

			_this.tablet.$fakeOverlay.on( "mouseover.megamenu", function() {
				_this.tablet.closeMenus();
			});


			// use click for body as we need the "e.target" property

			_this.$body.on( "click.megamenu", function(e) {

				if ( _this.$buContainers.hasClass( ex.activeClass ) ) {

					var $target = $(e.target);

					if ( !$target.is( _this.$buContainers ) &&
						!$.contains( _this.$buContainers.get(0), $target.get(0) ) &&
						!$target.is( _this.$buItems ) &&
						!$.contains( _this.$buItems.get(0), $target.get(0) ) ) {

						_this.tablet.closeMenus();

					}

				}

			});

			_this.tablet.$closeIcons
				.add( _this.tablet.$overlay )
				.on( "click.megamenu", function(e) {

					e.preventDefault();
					_this.tablet.closeMenus();

				});

			_this.tablet.$closeIcons
				.on( "click.megamenu", function() {

					LC.pubsub.trigger( "megamenu.clickCloseIcon" );

				});


		};

		ex.tablet.destroy = function() {

			// unregister the events for Tablet
			_this.$body
				.add( _this.tablet.$overlay )
				.add( _this.tablet.$fakeOverlay )
				.add( _this.tablet.$closeIcons )
				.add( _this.$buItems )
				.add( _this.$buItems.children("a") )
				.off( ".megamenu" );

			// and close the menu
			_this.tablet.closeMenus();

			// and remove the overlay
			_this.tablet.destroyModalBg();

		};

		ex.tablet.subscribe = function() {

			LC.pubsub.on( "closeAllModals", function( e, data ) {
				if (( !data ) || ( data && !data.not.includes("megamenu") )) {
					_this.tablet.closeMenus();
				}
			});

		};

		ex.desktop.init = function() {

			ex.placeSections( "dt" );

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> header -> search
 *
 */

( function( LC, $ ) {

	"use strict";

	/**
	 * first check that the user is logged in, no point to
	 * load the account module if not signed in.
	 */

	LC.nav = LC.nav || {};

	LC.nav.search = lcmodule( function( exports ) {

		exports.moduleName = "nav.search";

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports ),
			_this = {};

		// objects to hold mobile data/methods
		ex.mobile = {};
		_this.mobile = {};

		// objects to hold tablet data/methods
		ex.tablet = {};
		_this.tablet = {};




		// private variables / methods

		_this.$body = 		$("body");
		_this.$header = 	_this.$body.find(".site-header");

		_this.$search = 		$(".header-search");
		_this.$form = 			_this.$search.find(".header-search__form");
		_this.$input = 			_this.$search.find(".header-search__input");
		_this.$openIcon = 		$(".js-open-search");
		_this.$closeIcon = 		$(".header-search__close-icon");

		// if the search area has the class of "is-open" then set the flag to
		// true so our toggle works correctly.
		_this.mobile.isOpen = _this.$search.hasClass("is-open");
		_this.tablet.isOpen = _this.$search.hasClass("is-open");

		// figure out if we're on the search-result page.
		_this.isResultPage = _this.$body.hasClass("search_results_css");



		/**
		 * position the search bar in the correct place, necessary so
		 * the search bar is visible inside the mobile menu, and outside of it.
		 */
		_this.placeSearch = function( options ) {

			options = options || { type: "mobile" };

			if ( options.type === "mobile" ) {

				_this.$search.appendTo( _this.$header );

			} else {

				_this.$search.prependTo( _this.$nav );

			}

		};

		_this.clear = function() {

			_this.$input.val("");
			_this.change();

		};

		_this.change = function() {

			if ( _this.$input.val() !== "" ) {

				_this.$closeIcon.addClass( ex.clearClass );

			} else {

				_this.$closeIcon.removeClass( ex.clearClass );

			}

		};



		// public variables / methods

		ex.init = function() {

			ex.events();
			ex.subscribe();

			if ( Foundation.MediaQuery.atLeast("tb") ) {
				ex.tablet.init();
			} else {
				ex.mobile.init();
			}

			$( window ).on("changed.zf.mediaquery", function( e, now, previous ) {

				if ( now !== "mb" && previous === "mb" ) {

					ex.mobile.destroy();
					ex.tablet.init();

				} else if ( now === "mb" && previous !== "mb" ) {

					ex.tablet.destroy();
					ex.mobile.init();

				}

			});

		};

		ex.isOpen = function() {

			return _this.isOpen;

		};

		ex.open = function() {

			_this.$search.addClass( ex.openClass );
			_this.$input.trigger("focus");
			_this.isOpen = true;

			LC.pubsub.trigger("closeAllModals", { not: "globalsearch" });

			// adds handler to body for closing
			// we add it here to improve performance across
			// the app when clicking on body.
			_this.bodyEvent();

		};

		ex.close = function( clear ) {

			// we don't want to close the search bar if we're
			// on the search result page.
			if ( !_this.isResultPage ) {

				_this.$search.removeClass( ex.openClass );
				_this.$input.trigger("blur");
				_this.isOpen = false;

				if ( clear ) {
					_this.clear();
				}

				_this.removeBodyEvent();

			}

		};

		ex.toggle = function() {

			if ( ex.isOpen() && !_this.isResultPage ) {

				ex.close();

			} else {

				ex.open();

			}

		};

		_this.search = function(e) {

			var term = _this.$input.val();

			if ( term === "" ) {

				e.preventDefault();

			} else {

				/* eslint-disable camelcase */
				LC.track.trigger( "event", {
					eventName: "search",
					eventData: { search_term: term }
				});
				/* eslint-enable camelcase */

			}

		};

		ex.events = function() {

			_this.$openIcon
				.off(".globalsearch")
				.on( "click.globalsearch", function(e) {

					ex.toggle();
					e.preventDefault();

				});

			_this.$closeIcon
				.off(".globalsearch")
				.on( "click.globalsearch", function(e) {

					if ( _this.isResultPage || _this.$input.val() !== "" ) {

						_this.clear();

					} else {

						ex.close();

					}

					e.preventDefault();

				});

			_this.$input
				.off(".globalsearch")
				.on("change.globalsearch keyup.globalsearch", function() {

					_this.change();

				});

			_this.$form
				.off( ".globalsearch" )
				.on( "submit.globalsearch", _this.search );

		};

		_this.bodyEvent = function() {

			_this.$body
				.off(".globalsearch")
				.on("click.globalsearch", function(e) {

					var $target = $( e.target );

					// if we did _not_ click on the search box, or the
					// open/close icons, or any descendants, then close it.

					if ( ex.isOpen &&
						!$target.is( _this.$search ) &&
						!$target.is( _this.$openIcon ) &&
						!$target.is( _this.$closeIcon ) &&
						!$.contains( _this.$search.get(0), $target.get(0) ) &&
						!$.contains( _this.$openIcon.get(0), $target.get(0) ) ) {

						ex.close();

					}

				});

		};

		_this.removeBodyEvent = function() {

			// remove the body event if it triggered.
			_this.$body.off( ".globalsearch" );

		};

		ex.subscribe = function() {

			LC.pubsub.on( "closeAllModals", function( e, data ) {
				if (( !data ) || ( data && !data.not.includes("globalsearch") )) {
					ex.close();
				}
			});

		};



		ex.mobile.init = function() {

			_this.placeSearch({ type: "mobile" });
			ex.close();

		};

		ex.mobile.events = function() {

		};

		ex.mobile.subscribe = function() {

			LC.pubsub.on( "megamenu.hamburger", function() {
				ex.close();
			});

		};

		ex.mobile.destroy = function() {

		};




		ex.tablet.init = function() {

			_this.placeSearch({ type: "tablet" });
			ex.close();

		};

		ex.tablet.events = function() {

		};

		ex.tablet.destroy = function() {

		};

		ex.tablet.subscribe = function() {

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> header -> sign in
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.nav = LC.nav || {};

	LC.nav.signin = lcmodule( function( exports ) {

		exports.moduleName = "nav.signin";

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports ),
			_this = {};




		// private variables / methods

		_this.isOpen = false;

		_this.$body = 		$("body");
		_this.$header = 	_this.$body.find(".site-header");

		_this.$signin = 		_this.$header.find(".iconbar__item--sign-in");
		_this.$signinLink = 	_this.$signin.find(".iconbar__item-inner");
		_this.$modal = 			_this.$header.find(".header-sign-in");

		_this.$form = 			$("#headerSignInForm");
		_this.$formMessage =	$("#outputMsgContainerOverlay");
		_this.$submitButton = 	_this.$form.find("#header-signin-btn");
		_this.$forgotPassword = $("#forGotPasswordOverlay");

		// generating HTML in the Javascript should be kept to a minimum.
		// if we do it, we should make sure it's very clean and easy to edit.
		_this.$spinner =
			$("<span class='" +
			  	"spinner spinner--tiny spinner--dark spinner--static " +
				" pos-r r-10 b-2 disp-ib va-m' />");



		_this.addSpinner = function() {

			_this.$submitButton.prepend( _this.$spinner );

			_this.$submitButton
				.addClass( ex.disabledClass )
				.attr( "disabled", true );

		};

		_this.removeSpinner = function() {

			_this.$spinner.remove();

			// should always remove the disabled
			// effect when the ajax completes.

			_this.$submitButton
				.removeClass( ex.disabledClass )
				.removeAttr( "disabled" );

		};

		_this.formSubmit = function() {

			var ajaxUrl = _this.$form.attr( "action" ),
				ajaxData = _this.$form.serialize(),
				ajaxCall;

			if ( ajaxUrl ) {

				_this.addSpinner();

				ajaxCall = $.ajax({

					url: ajaxUrl,
					data: ajaxData,
					type: "post",
					cache: false

				});

				ajaxCall
					.done( _this.formSuccess )
					.fail( _this.formError )
					.always( _this.formAlways );

			}

		};

		_this.formSuccess = function( data ) {

			// object to send to form error handler,
			// and sign-in verification handler
			var errorObject = {

				form: "#headerSignInForm",
				response: data

			};

			// check the resulting JSON and
			// make sure the sign in was a success

			if ( data ) {

				LC.pubsub.trigger( "globalsignin.signin" );

				if ( data.isProcessSuccess || data.isProcessSuccess === "true" ) {

					LC.pubsub.trigger( "globalsignin.success" );

					window.location.href =
						URI( window.location.href )
							// this following typo is on purpose -_-;
							.addSearch( "headerSingInForm", "true" )
							.addSearch( "globalSignIn", "true" )
							.toString();

				} else {

					LC.pubsub.trigger( "globalsignin.failure" );

					// show the form errors, and
					// run the account-verification check
					LC.pubsub.trigger("forms.showErrors", errorObject );
					LC.pubsub.trigger("forms.verifyCheck", errorObject );

				}

			}

		};

		_this.formError = function() {

			_this.$formMessage.text( Lang[ "signInForm.login.server.error" ] );

		};

		_this.formAlways = function() {

			_this.removeSpinner();

		};

		_this.forgotPassword = function() {

			ex.close();
			LC.pubsub.trigger( "global.forgotPassword" );

		};

		// public variables / methods

		ex.openSpeed = ex.speed;
		ex.closeSpeed = ex.speed;

		ex.init = function() {

			/**
			 * first check that the user is not logged in, no point to
			 * load the signin module if they are already signed in
			 */

			if (
			    typeof LC.config.getValue === "undefined" ||
			   	LC.config.getValue("profile.isLogin") === true
			) {

				delete LC.nav.signin;
				return;

			}

			/**
			 * carry on if possible
			 */

			ex.events();
			ex.subscribe();

		};

		ex.isOpen = function() {

			return _this.isOpen;

		};

		ex.open = function() {

			if ( !ex.isOpen() ) {

				_this.$modal.slideDown( ex.openSpeed );
				_this.$signin.addClass( ex.activeClass );
				_this.isOpen = true;

				LC.pubsub.trigger("closeAllModals", { not: "globalsignin" });

				// adds handler to body for closing
				// we add it here to improve performance across
				// the app when clicking on body.
				_this.bodyEvent();

			}

		};

		ex.close = function() {

			if ( ex.isOpen() ) {

				_this.$modal.slideUp( ex.openSpeed );
				_this.$signin.removeClass( ex.activeClass );
				_this.isOpen = false;

				_this.removeBodyEvent();

			}

		};

		ex.toggle = function() {

			if ( ex.isOpen() ) {

				ex.close();

			} else {

				ex.open();

			}

		};

		ex.events = function() {

			_this.$signinLink.on("click.globalsignin", function(e) {

				ex.toggle();
				e.preventDefault();

			});

			_this.$form.on( "submit.globalsignin", function(e) {

				e.preventDefault();
				_this.formSubmit();

			});

			_this.$submitButton.on( "click.globalsignin", function(e) {

				e.preventDefault();
				_this.$form.trigger( "submit" );

			});

			_this.$forgotPassword.on( "click.globalsignin", function(e) {

				e.preventDefault();
				_this.forgotPassword();

			});

		};

		_this.bodyEvent = function() {

			_this.$body
				.off(".globalsignin")
				.on("click.globalsignin", function(e) {

					var $target = $( e.target );

					// if the target is not the sign in link
					// and the target is not inside the modal
					// then close it

					if ( ex.isOpen() &&
					    !$target.is( _this.$signin ) &&
						!$.contains( _this.$signin.get(0), $target.get(0) ) ) {

						ex.close();

					}

				});

		};

		_this.removeBodyEvent = function() {

			// remove the body event if it triggered.
			_this.$body.off( ".globalsignin" );

		};

		ex.subscribe = function() {

			LC.pubsub.on( "closeAllModals", function( e, data ) {
				if (( !data ) || ( data && !data.not.includes("globalsignin") )) {
					ex.close();
				}
			});

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * global -> header -> tracking
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.nav = LC.nav || {};

	LC.nav.tracking = lcmodule( function( exports ) {

		exports.moduleName = "nav.tracking";

		// "exports" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type

		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports ),
			_this = {};


		ex.init = function() {

			ex.subscribe();
			ex.events();

		};

		_this.signin = function() {

			LC.track.trigger( "ga.event", {
				eventName: "login_attempt",
				eventData: { method: "form", type: "nav", page: LC.location.pathname }
			});

		};

		_this.signinSuccess = function() {

			LC.track.trigger( "ga.event", {
				eventName: "login",
				eventData: { method: "form", type: "nav", page: LC.location.pathname }
			});

		};

		_this.signinFailure = function() {

			LC.track.trigger( "ga.event", {
				eventName: "login_failure",
				eventData: { method: "form", type: "nav", page: LC.location.pathname }
			});

		};

		_this.navigate = function( title ) {

			if ( title ) {

				title = title.replace(/^(hk|cn|row)-/gi, "");

				LC.track.trigger( "event", {
					category: "Main Nav",
					action: "Navigate",
					label: title
				});

			}

		};

		_this.interact = {};

		_this.interact.openMenu = function( bu ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Open Menu (" + bu + ")"
			});

		};

		_this.interact.reopenMenu = function( bu ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Re-Open Menu (" + bu + ")"
			});

		};

		_this.interact.closeMenu = function( bu ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Close Menu (" + bu + ")"
			});

		};

		_this.interact.clickCloseIcon = function() {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Click close icon"
			});

		};

		_this.interact.clickImage = function( alt ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Navigate",
				label: "Click Image (" + alt + ")"
			});

		};

		_this.interact.timeToClose = function( timeGroup ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Time to Close (" + timeGroup + ")"
			});

		};

		_this.interact.timeToNavigate = function( timeGroup ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Time to Navigate (" + timeGroup + ")"
			});

		};

		_this.interact.timeToClickImage = function( timeGroup ) {

			LC.track.trigger( "event", {
				category: "Main Nav",
				action: "Interact",
				label: "Time to Click Image (" + timeGroup + ")"
			});

		};

		ex.events = function() {



		};

		ex.subscribe = function() {

			LC.pubsub

				.on("globalsignin.signin", function() {
					_this.signin();
				})

				.on("globalsignin.success", function() {
					_this.signinSuccess();
				})

				.on("globalsignin.failure", function() {
					_this.signinFailure();
				})

				.on("megamenu.navigate", function( pubsub, endpoint ) {
					_this.navigate( endpoint );
				})

				.on("megamenu.openMenu", function( pubsub, bu ) {
					_this.interact.openMenu( bu );
				})

				.on("megamenu.reopenMenu", function( pubsub, bu ) {
					_this.interact.reopenMenu( bu );
				})

				.on("megamenu.closeMenu", function( pubsub, bu ) {
					_this.interact.closeMenu( bu );
				})

				.on("megamenu.clickCloseIcon", function() {
					_this.interact.clickCloseIcon();
				})

				.on("megamenu.clickImage", function( pubsub, alt ) {
					_this.interact.clickImage( alt );
				})

				.on("megamenu.timeToClose", function( pubsub, timeGroup ) {
					_this.interact.timeToClose( timeGroup );
				})

				.on("megamenu.timeToNavigate", function( pubsub, timeGroup ) {
					_this.interact.timeToNavigate( timeGroup );
				})

				.on("megamenu.timeToClickImage", function( pubsub, timeGroup ) {
					_this.interact.timeToClickImage( timeGroup );
				});

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * forms -> errors
 *
 * This module is used to validate forms submitted via
 * the ATG ajax (json) form submission technique.
 *
 * When a form is submitted with the data-type of JSON:
 *
 * 		$.ajax({
 *
 *			url: _this.posturl,
 *			type: "POST",
 *			data: _this.$form.serialize(),
 *			dataType: "json"
 *
 *		}).done( _this.submitSuccess );
 *
 * It will return an object containing either a success
 * result or a failure with:
 *
 * 		data.isProcessSuccess
 *
 * ========================================================================
 *
 * If the form has failed submission; it is likely due
 * to validation errors. That's where this errors module comes in.
 * After the form returns a validation error, we can then trigger
 * this module to show the errors by doing:
 *
 * 		LC.pubsub.trigger("forms.showErrors", {
 *			form: "#wrapperElement",
 *			formName: "myForm",
 *			response: formSubmissionResponse,
 *			showIcons: false,
 *			scrollToFirstError: false
 *		});
 *
 * The 'form' property should be a string representing a jquery
 * selector, this will be used to scope all errors to this area.
 * It could be the form wrapper, or a container where the form sits.
 *
 * The 'formName' property is OPTIONAL, and used for analytics tracking
 * on form errors. By default it will be the 'form' value.
 *
 * ========================================================================
 *
 * To display the errors in the 'form' property, we need to
 * supply some elements in the HTML of the page.
 *
 * Firstly:
 *
 * 		<div class="lc-general-error / hide">
 * 		</div>
 *
 * This element will hold, and style, the "generic" error for
 * displaying at the top of the form. This should generally be
 * placed at the start of the form.
 *
 * ========================================================================
 *
 * Next we need to supply each field's error holder. This can
 * be done by providing a '.lc-field-error' element, which also
 * has a 'data-error-code' attribute. This element should come
 * just after the '.lc-field' element.
 *
 *		<div class="lc-field" data-error-code="firstName">
 *	 		<input id="FirstName" type="text" placeholder="First Name" />
 *	   	</div>
 *
 *		<label for="FirstName"
 *			   class="lc-field-error / hide"
 *			   data-error-code="firstName">
 *	    </label>
 *
 * the 'data-error-code' is a reference to the error code
 * which is returned from the server in the form submission
 * failure response. If these don't match, nothing will be shown.
 *
 */



( function( LC, $ ) {

	"use strict";

	new lcmodule2( "forms.errors", function( exports, _this ) {

		var ex = exports;

		// class we will append to all errors
		ex.errorClass = "is-error";

		// the icon we will possibly append to each form field.
		ex.errorIcon =
			"<i class=\"lc-icon lc-icon--circle | txt-white bg-red pad-2 mar-r5 pos-r b-2\" " +
			"data-icon=\"minus-thick\" />";

		// use these overrides to globally set the behaviours
		// without needed to amend every trigger call.
		ex.scrollOverride = false;
		ex.errorIconOverride = false;

		/**
		 * initialise the errors module
		 */
		ex.init = function() {

			_this.$scroller = $("html, body");

		};

		/**
		 * Show each error message returned by the form
		 * in the response's `errorList`.
		 *
		 * @param  { Object } data 	| An object with at the very least a parameter of
		 *                  		| "response" which is the JSON response returned
		 *                  		| from ATG on form submission.
		 */
		_this.showErrors = function( data ) {

			if ( !data ||
			     typeof data.form === "undefined" ||
				 typeof data.response === "undefined" ) {

				LC.warn("Cannot show errors without a form / server response.");
				return;

			}

			var errors = data.response.errorList,
				$form = $( data.form ),
				$formFields = $form.find(".lc-field"),
				$formErrors = $form.find(".lc-field-error"),
				errorFilter;

			/**
			 * method used to filter the error codes in the
			 * element's data-error-code attribute, and determine
			 * if the current element's error should be shown.
			 *
			 * @param  {String} errorCode 	| the current error code to check
			 * @param  {Object} element 	| jQuery Object referencing the element
			 * @return {Boolean}        	| should we show this error or not?
			 */
			errorFilter = function( errorCode, element ) {

				var $el = $(element),
					errorCodes = $el.data("error-code"),
					hasError = false;

				if ( _.isString(errorCodes) ) {
					errorCodes = errorCodes.split( " " );
				}

				_.each( errorCodes, function( val ) {

					if ( val === errorCode ) {

						hasError = true;

					}

				});

				return hasError;

			};

			// hide all the errors in the form.
			_this.hideErrors( data );

			_.each( errors, function( error ) {

				// show icons if they are required
				if ( data.showIcons || ex.errorIconOverride ) {
					// but dont show icon for generic error
					if ( !error.errorCode.includes( "genericMsg-" ) ) {
						error.errorMessage = ex.errorIcon + " " + error.errorMessage;
					}
				}

				$formErrors
					.filter(function( key, element ) {
						return errorFilter( error.errorCode, element );
					})
					.html( error.errorMessage )
					.removeClass( ex.hiddenClass );

				$formFields
					.filter(function( key, element ) {
						return errorFilter( error.errorCode, element );
					})
					.find("[ class *= 'lc-field__' ]")
					.addClass( ex.errorClass );

				LC.track.trigger("ga.event", {
					category: "Form Error",
					action: data.formName || data.form,
					label: error.errorCode
				});

			});

		};

		/**
		 * Code to show the general error message of a form.
		 * This is based on two factors:
		 * 	1) Does the response have a `genericMsg-x` error message to show
		 * 	2) Does the response have `showGeneralErrorMessage` set.
		 *
		 * There was a revision by Gordon regarding SOA and Registration where we
		 * need to show the `genericMsg-x` as priority: https://jira-lc.atlassian.net/browse/ONLFT-399
		 * and so the code below reflects that.
		 *
		 * @param  { Object } data | The response data from the JSON form submit
		 */
		_this.showGenericError = function( data ) {

			if ( !data ||
			     typeof data.form === "undefined" ||
				 typeof data.response === "undefined" ) {

				LC.warn("Cannot show errors without a form / server response.");
				return;

			}

			var $form = $( data.form ),
				errors = data.response.errorList,
				generalError = data.response.errorMessage,
				showGeneralError = data.response.showGeneralErrorMessage,
				$errorContainer = $form.find(".lc-general-error"),
				genericError,
				finalError;

			/**
			 * check for any `genericMsg-x` errors in the error list,
			 * and return the first one we find.
			 */
			genericError = _.find( errors, function( error ) {
				return error.errorCode.includes( "genericMsg-" );
			});

			/**
			 * if we have a container to populate, and we have
			 * some general errors to show...
			 */
			if ( $errorContainer.length && ( showGeneralError || genericError ) ) {

				if ( genericError ) {

					finalError = genericError.errorMessage;

				} else if ( showGeneralError ) {

					finalError = generalError;

				}

				$errorContainer
					.html( finalError )
					.removeClass( ex.hiddenClass );

			}

		};

		/**
		 * A helper method for easily removing the errors from
		 * every field on the page, this is useful for scenarios
		 * where a user changes to another tab, or something, and you
		 * want to remove all the errors for when they come back.
		 *
		 * @param  { Object } data    | Object with a single key: "form",
		 *                  		  |	which is the wrapper element for the
		 *                  		  |	form with errors.
		 *
		 */
		_this.hideErrors = function( data ) {

			if ( !data || typeof data.form === "undefined" ) {

				LC.warn("Cannot hide errors without a form.");
				return;

			}

			var $form = $( data.form ),
				$formFields = $form.find(".lc-field"),
				$formErrors = $form.find(".lc-field-error, .lc-general-error");

			$formErrors.addClass( ex.hiddenClass );
			$formFields.find("[ class *= 'lc-field__' ]").removeClass( ex.errorClass );

		};

		/**
		 * Helper to add events to each field which will
		 * tell the field to change it's style once it's been
		 * changed or filled.
		 *
		 * @param { String } form | The form which we want to add events to
		 *
		 */
		ex.addEvents = function( form ) {

			if ( !form ) {

				LC.warn( "Cannot add events without a form." );
				return;

			}

			var $form = $( form ),
				$formFields = $form.find(".lc-field"),
				$formErrors = $form.find(".lc-field-error");

			if ( $form.length ) {

				// remove all the existing events first.
				ex.removeEvents( form );

				// for each field, we apply a change event
				// for it's input which will hide the error.
				$formFields.each( function() {

					var $this = $(this),
						code = $this.data("error-code"),
						$input = $this.find( ":input." + ex.errorClass ),
						isCheckboxAndRadio = $input.attr( "type" ) === "checkbox" ||
												$input.attr("type") === "radio";

					$input.one( "change.formError keyup.formError", function() {

						$input.removeClass( ex.errorClass );

						if ( isCheckboxAndRadio ) {
							$input.siblings().removeClass( ex.errorClass );
						}

						$formErrors
							.filter( function(i, fieldError) {
								return $(fieldError)
									.data("error-code")
									.split(" ")
									.includes(code);
							})
							.addClass(ex.hiddenClass);

					});

				});

			} else {

				LC.error( "can't add events without a form" );

			}

		};

		ex.removeEvents = function( form ) {

			if ( !form ) {

				LC.warn( "Cannot remove events without a form." );
				return;

			}

			var $form = $( form ),
				$formFields = $form.find(".lc-field");

			if ( $form.length ) {

				$formFields
					.find( ":input" )
					.off( "change.formError" );

			} else {

				LC.error( "can't remove events without a form" );

			}

		};

		/**
		 * helper to scroll the first error in to view
		 * so the user knows there's errors to fix in their form
		 *
		 * @param  { Selector } form | jQuery selector for the form with the errors
		 *
		 */
		ex.scrollToFirstError = function( form ) {

			if ( !form ) {

				LC.warn( "Cannot scroll to error without a form." );
				return;

			}

			var location,
				winLocation = 	$( window ).scrollTop(),
				$form = 		$( form ),
				$formFields = 	$form.find(".lc-field"),
				$generalError = $form.find(".lc-general-error").filter(":visible"),
				$firstError = 	$formFields.find(".is-error").first();

			// use the general error if it exists
			if ( $generalError.length ) {
				$firstError = $generalError;
			}

			if ( $firstError.length ) {

				// add some arbitrary padding to the top of
				// the window for better aesthetics
				location = $firstError.dimensions().offset.top;
				location -= 75;

				if ( location < winLocation ) {

					_this.$scroller.animate({ scrollTop: location }, ex.speed );

				}

			}

		};

		ex.subscribe = function() {

			LC.pubsub.on( "forms.showErrors", function( e, data ) {

				// we need to dupe/clone the data object to
				// avoid overwriting references in the object
				// we passed in!
				var duped = $.extend( true, {}, data );

				_this.showErrors( duped );
				_this.showGenericError( duped );

				if ( ex.scrollOverride ||
				    typeof duped.scrollToFirstError === "undefined" ||
				    duped.scrollToFirstError === true ) {

					ex.scrollToFirstError( duped.form );

				}

				// now bind events to each form field.
				ex.addEvents( duped.form );

			});

			LC.pubsub.on( "forms.hideErrors", function( e, data ) {

				// we need to dupe/clone the data object to
				// avoid overwriting references in the object
				// we passed in!
				var duped = $.extend( true, {}, data );

				// hide all the errors in the form.
				_this.hideErrors( duped );

				// now unbind events from each form field.
				ex.removeEvents( duped.form );

			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * forms -> existingAccount
 *
 * small module for handling registration forms
 * rendering of "existing account" error messages.
 *
 * if a register form returns a "account is exist"
 * error message, this module will handle updating the
 * HTML to provide a proper link to that sign in page.
 *
 *
 * ========================================================================
 *
 * After the form returns a validation error, and we've already
 * ran the form-error handling, we can then trigger
 * this module to handle the existing account message by doing:
 *
 * 		LC.pubsub.trigger("forms.existingCheck", {
 *			form: "#wrapperElement",
 *			response: formSubmissionResponse
 *		});
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "forms.existingAccount", function( exports, _this ) {

		var ex = exports;

		/**
		 * initialise the existingAccount module
		 */

		ex.init = function() {

			_this.existingClass = ".errorMessageUserAlreadyExistSignInLink";
			_this.signInUrl = "/account/globalSignIn.jsp";

		};



		/**
		 * method to take the form data, and check if the
		 * email/phone already exists. If it already exists we need
		 * to update the error message redirect link
		 *
		 * @param  {Object} data | atg response data
		 */
		ex.existingCheck = function( data ) {

			if ( !data || !data.form || !data.response.errorList ) {

				LC.warn( "tried to handle a registration error without necessary data" );
				return;

			}

			var $form = $( data.form ),
				errorId,
				$errorField,
				loginVal,
				existingUrl;

			errorId =
				$( _this.existingClass )
					.closest(".lc-field-error")
					.attr("for");

			$errorField = $( "#" + errorId );

			loginVal = $form.find( $errorField ).val();

			// We only need to continue if there was a loginVal.
			if ( loginVal ) {

				// get the existing account redirect url from
				// the getExistingUrl() method
				existingUrl = ex.getExistingUrl( loginVal );

				if ( !existingUrl ) {

					LC.warn( "couldn't create a valid existingUrl for sign in ");
					return;

				}

				// handle the rendering of the
				// error link in the form
				ex.renderErrorLink( $form, existingUrl );

			}

		};

		/**
		 * method to get a link to the sign in page
		 * which includes the user's email/# for prepoulation
		 *
		 * @param  { String } loginVal | users email/phone
		 * @return { String }          | the url string to the sign in page
		 */
		ex.getExistingUrl = function( loginVal ) {

			if ( !loginVal ) {

				LC.warn( "can't create existingUrl without a loginVal" );
				return false;

			}

			var existingUrl = new URI(),
				signInUrl = $("#signInUrl").val() || _this.signInUrl;

			existingUrl
				.path( signInUrl )
				.addQuery( "email", loginVal );

			return existingUrl.toString();

		};

		/**
		 * how to render the errorLink in the form
		 * by appending the redirect to sign in page
		 * url to the href of the link
		 *
		 * @param  { $Object } $form  	| jQuery object containing the form we submitted
		 * @param  { String } url    	| the url to append to the sign in link
		 */
		ex.renderErrorLink = function( $form, url ) {

			if ( !url ) {

				LC.warn( "no URL provided for error link" );
				return;

			}

			if ( !$form.length ) {

				LC.warn( "no $form provided to find error link" );
				return;

			}

			var $error = $form.find( _this.existingClass );
			$error.attr("href", url );

		};

		ex.subscribe = function() {

			LC.pubsub.on("forms.existingCheck", function( e, data ) {
				ex.existingCheck( data );
			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


/**
 *
 * [ module ]
 * forms -> stateCityDistrict
 *
 * This module is used to bind form select fields together.
 *
 * - country -> state
 * - state -> city / district
 * - city -> district
 *
 * The module relies on specific css classes and data-attributes on
 * form elements in the html. For example:
 *
 * 		<div class="lc-field">
 * 			<select
 * 				id="formState"
 * 				name="formState"
 * 				data-empty-value="Province"
 * 				class="lc-field__select / js-state-field"
 * 				data-district-field="#formDistrict">
 *
 * 				<option value="">Province</option>
 * 				<option selected="selected" value="北京市">Beijing</option>
 * 				<option value="上海市">Shanghai</option>
 * 				<option value="...">...</option>
 *
 * 			</select>
 * 		</div>
 *
 * 		<div class="lc-field">
 * 			<select
 *	  			id="formDistrict"
 *				class="lc-field__select"
 *				data-empty-value="District">
 *
 *				<option value="">District</option>
 *
 *			</select>
 * 		</div>
 *
 *
 * By supplying a \data-district-field\ in the HTML above,
 * we have created a one-way-bind between the `STATE` dropdown list
 * and the empty `DISTRICT` dropdown list.
 *
 * The javascript will detect changes in the `STATE` dropdown, and
 * perform an ajax call, then update the `DISTRICT` dropdown accordingly.
 *
 * You may also supply `data-empty-value` on the target dropdown
 * so that the first item in the list is a reference value and not
 * the first returned result.
 *
 */



( function( LC, $ ) {

	"use strict";

	new lcmodule2( "forms.stateCityDistrict", function( exports, _this ) {

		var ex = exports;

		ex.setup = function() {

			_this.countryField = 	"country-field";
			_this.stateField = 		"state-field";
			_this.cityField = 		"city-field";
			_this.districtField = 	"district-field";
			_this.emptyValue = 		"empty-value";
			_this.url = "/common/ajax/retrieveAddressInfo.jsp";
			_this.ajax = {};

		};

		/**
		 * initialise the stateCityDistrict module
		 */
		ex.init = function() {

			_this.$spinner = $( "<span class=\"spinner spinner--tiny spinner--fast / fade opc-0\"></span>" );

		};

		/**
		 * method to start the loading animation in a given select field
		 * @param  {$object} $lcField | The .lc-field element we want to show spinner in
		 */
		ex.startLoading = function( $lcField ) {

			var $positionedChildren =
				$lcField.children()
					.not( ".lc-field__label, .lc-field-error, .spinner" )
					.filter( "[class*='pos-']" );

			$lcField
				.find( ".lc-field__select-label" )
				.empty();

			if ( $positionedChildren.length ) {
				$positionedChildren.append( _this.$spinner );
			} else {
				$lcField.append( _this.$spinner );
			}

			_this.spinnerTimer = setTimeout( function() {
				_this.$spinner.removeClass( "opc-0" );
			}, 10);

		};

		/**
		 * method to stop the loading animation in a given select field
		 * @param  {$object} $lcField | The .lc-field element we want to stop spinner in
		 */
		ex.stopLoading = function() {

			_this.$spinner.addClass( "opc-0" );

			clearTimeout( _this.spinnerTimer );
			_this.spinnerTimer = setTimeout( function() {
				_this.$spinner.remove();
			}, 330 );

		};

		/**
		 * clear out any existing data in the <select> fields
		 * and replace with the "emptyValue" from the data attribute
		 * of that field.
		 * @param  {String} changingField  | name of the field we are currently changing
		 * @param  {$Object} $form         | the $form object where the children fields exist
		 */
		_this.clearFields = function( changingField, $form ) {

			if ( !changingField || !$form ) {
				LC.error( "no field/form to clear" );
			}

			var emptyValue = "",
				$emptyField;

			switch ( changingField ) {

				case "country":
				case "state":
				// if we changed the country; clear the state field

					$emptyField = $form.find( ".js-" + _this.stateField );
					emptyValue = $emptyField.data( _this.emptyValue );

					$emptyField.html(
						"<option disabled selected value=''>" + emptyValue + "</option>"
					);

					LC.pubsub.trigger( "forms.selects", {
						scope: $emptyField.closest( ".lc-field" )
					});

					// falls through

				case "city":
				// if we changed the state; clear the city field

					$emptyField = $form.find( ".js-" + _this.cityField );
					emptyValue = $emptyField.data( _this.emptyValue );

					$emptyField.html(
						"<option disabled selected value=''>" + emptyValue + "</option>"
					);

					LC.pubsub.trigger( "forms.selects", {
						scope: $emptyField.closest( ".lc-field" )
					});

					// falls through

				case "district":
				default:
				// if we changed the city; clear the district field

					$emptyField = $form.find( ".js-" + _this.districtField );
					emptyValue = $emptyField.data( _this.emptyValue );

					$emptyField.html(
						"<option disabled selected value=''>" + emptyValue + "</option>"
					);

					LC.pubsub.trigger( "forms.selects", {
						scope: $emptyField.closest( ".lc-field" )
					});

					break;

			}

		};

		/**
		 * main method for performing ajax request and handling response
		 * @param  {String} url         | the endpoint URL with correct params
		 * @param  {Object} $boundField | jQuery object of the bound select field to populate
		 * @param  {String} propName    | name/type of the bound field to populate
		 */
		ex.doAjax = function( url, $boundField, propName ) {

			var $boundParent = $boundField.closest(".lc-field"),
				$boundForm = $boundField.closest("form"),
				options = "",
				allowNoService = $boundForm.data( "no-service" );

			// clear out any existing data that will be overridden
			_this.clearFields( propName, $boundForm );

			// start displaying the loading spinner
			ex.startLoading( $boundParent );

			if ( _this.ajax[ propName ] ) {
				_this.ajax[ propName ].abort();
			}

			_this.ajax[ propName ] = $.ajax({
				dataType: "json",
				url: url
			})

				.done( function( items ) {

					_.each( items, function( item ) {

						if ( allowNoService && item[ propName + "NoService" ] ) {

							options += "<option value='' disabled>";
							options += 	item[ propName + "NoServiceName" ];
							options += "</option>";

						} else {

							options += "<option value='" + item[ propName + "Code" ] + "'>";
							options += 	item[ propName + "Name" ];
							options += "</option>";

						}

					});

					$boundField.append( options );

					LC.pubsub.trigger( "forms.selects", {
						scope: $boundParent
					});

				})

				.always( function() {

					ex.stopLoading();

				});

			return _this.ajax[ propName ];

		};

		/**
		 * change the country field and get all the states associated
		 * @param  {Object} el | jQuery object of the country select field
		 */
		ex.changeCountry = function( el ) {

			var $countryField = $( el ),
				bindType = "getState",
				propName = "state",
				boundField = $countryField.data( _this.stateField ),
				$boundField = $( boundField ),
				country = $countryField.val(),
				url;

			if ( $boundField.length && country ) {

				url = new URI( _this.url );
				url.setQuery({ mode: bindType, input: country });
				url = url.toString();

				return ex.doAjax( url, $boundField, propName );

			} else {

				return new $.Deferred().reject();

			}



		};

		/**
		 * change the state field and get all the cities associated with the current value
		 * @param  {Object} el | jQuery object of the state select field
		 */
		ex.changeState = function( el ) {

			var $stateField = $( el ),
				bindType,
				propName,
				boundField,
				$boundField,
				state = $stateField.val(),
				url;

			if ( $stateField.data( _this.cityField ) ) {

				bindType = "getCity";
				propName = "city";
				boundField = $stateField.data( _this.cityField );

			} else if ( $stateField.data( _this.districtField ) ) {

				bindType = "getDistrictFromState";
				propName = "district";
				boundField = $stateField.data( _this.districtField );

			}

			$boundField = $( boundField );

			if ( $boundField.length && state ) {

				url = new URI( _this.url );
				url.setQuery({ mode: bindType, input: state });
				url = url.toString();

				return ex.doAjax( url, $boundField, propName );

			} else {

				return new $.Deferred().reject();

			}

		};

		/**
		 * change the city field and get all the districts associated with the current value
		 * @param  {Object} el | jQuery object of the city select field
		 */
		ex.changeCity = function( el ) {

			var $cityField = $( el ),
				bindType = "getDistrict",
				propName = "district",
				boundField = $cityField.data( _this.districtField ),
				$boundField = $( boundField ),
				city = $cityField.val(),
				url;

			if ( $boundField.length && city ) {

				url = new URI( _this.url );
				url.setQuery({ mode: bindType, input: city });
				url = url.toString();

				return ex.doAjax( url, $boundField, propName );

			} else {

				return new $.Deferred().reject();

			}

		};

		ex.events = function( $scope ) {

			$scope = $scope || $("body");

			$scope
				.find( ".js-" + _this.countryField )
				.off(".formStateCityDistrict")
				.on("change.formStateCityDistrict fill.formStateCityDistrict", function(e) {
					ex.changeCountry(e.target);
				})
				.trigger("fill.formStateCityDistrict");

			$scope
				.find( ".js-" + _this.stateField )
				.off(".formStateCityDistrict")
				.on("change.formStateCityDistrict fill.formStateCityDistrict", function(e) {
					ex.changeState(e.target);
				})
				.trigger("fill.formStateCityDistrict");

			$scope
				.find( ".js-" + _this.cityField )
				.off(".formStateCityDistrict")
				.on("change.formStateCityDistrict fill.formStateCityDistrict", function(e) {
					ex.changeCity(e.target);
				})
				.trigger("fill.formStateCityDistrict");

		};

		ex.subscribe = function() {

			LC.pubsub.on("global.initModal global.forms forms.selects", function( e, data ) {

				var $scope = $("body");

				if ( data && typeof data.scope !== "undefined" ) {
					$scope = $( data.scope );
				}

				ex.events( $scope );

			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * forms -> verifyAccount
 *
 * small module for handling sign-in forms
 * rendering of "verify your account" error messages.
 *
 * if a sign-in form returns a "please verify your account"
 * error message, this module will handle updating the
 * HTML to provide a proper link to that verification page.
 *
 * It will also handle any redirect creation for special
 * scenarios such as the order confirmation page.
 *
 * ========================================================================
 *
 * After the form returns a validation error, and we've already
 * ran the form-error handling, we can then trigger
 * this module to handle the verification message by doing:
 *
 * 		LC.pubsub.trigger("forms.verifyCheck", {
 *			form: "#wrapperElement",
 *			response: formSubmissionResponse
 *		});
 *
 */

( function( LC, $ ) {

	"use strict";

	new lcmodule2( "forms.verifyAccount", function( exports, _this ) {

		var ex = exports;

		ex.setup = function() {

			_this.verifyClass = ".js-errorMessage-firstTimeAccess";

		};

		/**
		 * take the error message's loginVal (email/#) and
		 * send it to the errorLink for rendering to the form.
		 *
		 * @param  { Object } data 	| An object with a parameter of
		 *                  		| "response" which is the JSON response returned
		 *                  		| from ATG on form submission. and
		 *                  		| "form" which is a selector to choose
		 *                  		| which form the verify link is in.
		 */
		ex.verifyCheck = function( data ) {

			if ( !data || !data.form || !data.response.errorList ) {

				LC.warn( "tried to handle a sign-in verification error without necessary data" );
				return;

			}

			var $form = $( data.form ),
				loginVal,
				verifyUrl;

			// get the loginVal (email/#) from the error response
			// if it exists at all. We only need to continue if the
			// response says there was a loginVal error.
			loginVal = _.find( data.response.errorList, { errorCode: "loginVal" } );

			if ( loginVal ) {

				// get the verification redirect url from
				// the getVerifyUrl() method
				verifyUrl = ex.getVerifyUrl( loginVal.errorMessage );

				if ( !verifyUrl ) {

					LC.warn( "couldn't create a valid verifyUrl for sign-in verification");
					return;

				}

				// handle the rendering of the
				// error link in the form
				ex.renderErrorLink( $form, verifyUrl );

			}

		};


		/**
		 * method to get a link to the verification page
		 * which includes the user's email/# for prepoulation,
		 * and also the eventual destination url (after verification).
		 *
		 * this is mostly custom logic for random pages which
		 * need to handle redirections differently.
		 *
		 * @param  { String } loginVal | users email/phone
		 * @return { String }          | the url string to the verification page
		 */
		ex.getVerifyUrl = function( loginVal ) {

			var currentLocation = URI().path(),
				verifyUrl = new URI(),
				queries = {},

				mainData = $( "main" ).data(),
				saleAuthId = mainData.saleAuthorisationId,
				salePreviewLevel = mainData.salePreviewLevel;

			if ( !loginVal ) {

				LC.warn( "can't create verifyUrl without a loginVal" );
				return false;

			}

			if ( currentLocation.includes( "orderconf" ) ) {
				queries.returnTo = "accountDetails,orderconf";
			}

			if ( currentLocation.includes( "checkoutLogin" ) ) {
				queries.returnTo = "shippingInfo";
			}

			if ( typeof saleAuthId !== "undefined" && typeof salePreviewLevel !== "undefined" ) {
				queries.returnTo = "sale";
				queries.salePreviewLevel = salePreviewLevel;
				queries.saleAuthorisationId = saleAuthId;
			}

			verifyUrl.path( "/account/verify/" + loginVal );
			verifyUrl.addQuery( queries );

			return verifyUrl.toString();

		};

		/**
		 * how to render the errorLink in the form
		 * by appending the redirect to verification page
		 * url to the href of the link
		 *
		 * @param  { $Object } $form  	| jQuery object containing the form we submitted
		 * @param  { String } url    	| the URl to append to the verification link
		 */
		ex.renderErrorLink = function( $form, url ) {

			if ( !url ) {

				LC.warn( "no URL provided for error link" );
				return;

			}

			if ( !$form.length ) {

				LC.warn( "no $form provided to find error link" );
				return;

			}

			var $error = $form.find( _this.verifyClass );

			if ( $error.length > 0 ) {
				$error.attr("href", url );
			}

		};

		ex.subscribe = function() {

			LC.pubsub.on("forms.verifyCheck", function( e, data ) {
				ex.verifyCheck( data );
			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * promo -> popup
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.promo = LC.promo || {};

	// we copy/duplicate the object so that
	// in future we can re-name the module from
	// the legacy "promoPopup" to "promo.popup".
	LC.promo.popup = LC.promoPopup = lcmodule( function( exports ) {

		exports.moduleName = "promo.popup";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, window.lcmoduledefaults, exports ),
			_this = {};

		/**
		 * initialise the popup module
		 */

		_this.defaultName = "popupModule";

		ex.defaults = {

			moduleName: 		_this.defaultName,
			nameOfCF: 			"popup_" + _this.defaultName,
			remindTimeout: 		7,
			prohibitPeriod: 	60,
			disableTimeout: 	30 * 24 * 60,
			doneTimeout: 		90 * 24 * 60

		};

		ex.defaults.cookieNames = {

			disable: 	_this.defaultName + "Disable",
			remind: 	_this.defaultName + "RemindLater",
			done: 		_this.defaultName + "Done"

		};

		ex.defaults.excludeUrls = {

			productPage: 		".*(/pdp/|/product.lc).*",
			cartSummary: 		".*/summary.*",
			checkout: 			".*/checkout.*",
			account: 			".*/account.*"

		};

		/*

			following object urls() can't put into the  init()
			that is because we need to take care both LC2 (revamped) and non-revamped JS style.

			non-revamped JS is not in module style (e.g. http://media.lanecrawford.com/feature/js/popup/popup-survey-row.js)
			but revamped is in module style. So we can't use the init to do initialization. Otherwise init() will run later than
			the popup-survey-xx.js

		 */
		_this.urls = {};
		_this.urls.contentFragment = "/common/include/getContentFragment.jsp?contentFragmentId=";


		_this.isExcluded = function( excludeUrls ) {

			var page = LC.location.href;

			if ( !excludeUrls ) {

				return false;

			}

			return _.some( excludeUrls, function( value ) {

				var regex = new RegExp( value );
				return regex.test( page );

			});

		};

		_this.setCookie = function( name, data, deltaTime ) {

			var expires = new Date( new Date().getTime() + deltaTime );

			// set the cookie.
			Cookies.set( name, data, { expires: expires });

		};

		_this.getVisitDuration = function() {

			return LC.sessionDuration.getSessionDuration();

		};

		ex.create = function( config ) {

			var settings,
				thisPopup;

			if ( !config ) {
				LC.error( "Popup Module Config is missing" );
				return {};
			}

			if ( typeof config !== "object" ) {
				LC.error( "Popup Module Config is wrong format" );
				return {};
			}

			if ( !config.moduleName ) {
				LC.error( "Popup Module Name is missing" );
				return {};
			}

			// create a settings object with the defaults extended
			settings = $.extend( {}, ex.defaults, config );

			thisPopup = ex.makePopup( settings );
			ex.registerPopup( settings.moduleName, thisPopup );

			return thisPopup;

		};

		ex.makePopup = function( settings ) {

			var thisPopup = {},
				trackCategory = "Popup-" + settings.moduleName,
				wrapperId;

			if ( !settings ) {

				LC.warn( "Cannot generate a Popup Module without settings" );
				return {};

			}

			wrapperId = "promo-popup-" + settings.moduleName;

			thisPopup.init = function( callback ) {

				var isDisabled = Cookies.get( settings.cookieNames.disable ),
					isFinished = Cookies.get( settings.cookieNames.done ),
					isExcluded = _this.isExcluded( settings.excludeUrls ),
					date,
					lastCloseTime,
					visitDuration;

				if ( isDisabled || isFinished || isExcluded ) {

					// don't run the popup if we are not allowed
					// to; that means if it's finished, disabled, or on
					// an excluded page... or non-secure page.
					LC.info( "Popup previously set as Disabled, not running." );
					return;

				}

				date = new Date();
				lastCloseTime = Cookies.get( settings.cookieNames.remind );

				if ( lastCloseTime ) {

					lastCloseTime = parseInt( lastCloseTime, 10 );

				} else {

					lastCloseTime = date;

				}

				if ( date >= lastCloseTime ) {

					visitDuration = _this.getVisitDuration();

					if ( visitDuration > settings.prohibitPeriod ) {

						thisPopup.launch( 2000, callback );

					}

				}

			};

			thisPopup.launch = function( delay, callback ) {

				var $wrapper = $( "#" + wrapperId ),
					contentPath = _this.urls.contentFragment + settings.nameOfCF,
					setupEvents;

				if ( typeof delay === "function" ) {
					callback = delay;
				}

				if ( typeof delay !== "number" ) {
					delay = 0;
				}

				if ( typeof callback !== "function" ) {
					callback = function() {};
				}

				if ( !$wrapper.length ) {

					$wrapper =
						$("<div />")
							.attr("id", wrapperId )
							.addClass("popupModule / pos-f b-0 l-50p")
							.appendTo( document.body );

				}

				// click events.

				$( window ).on( "unload", function() {

					if ( $wrapper.length ) {

						LC.track.trigger( "ga.event", {
							category: trackCategory,
							action: "Pop up close",
							label: "No response"
						});

					}

					return true;

				});

				setupEvents = function() {

					LC.track.trigger( "ga.pageview", {
						pageName: /promoPopup/ + settings.moduleName
					});

					$wrapper
						.off( "click.promoPopup" )
						.on( "click.promoPopup", ".action-dontAskAgain", function( e ) {

							e.preventDefault();

							LC.track.trigger( "ga.event", {
								category: trackCategory,
								action: "Click",
								label: $(this).data("galabel") || "Dont ask again"
							});

							thisPopup.disablePopup();
							thisPopup.close();

						})
						.on( "click.promoPopup", ".action-remindMeLater", function( e ) {

							e.preventDefault();

							LC.track.trigger( "ga.event", {
								category: trackCategory,
								action: "Click",
								label: $(this).data("galabel") || "Remind Me Later"
							});

							thisPopup.remindLater();
							thisPopup.close();

						})
						.on( "click.promoPopup", "[ class *= 'action-signUp' ]", function( e ) {

							e.preventDefault();

							LC.track.trigger( "ga.event", {
								category: trackCategory,
								action: "Click",
								label: $(this).data("galabel") || "Start Now"
							});

							thisPopup.setDone();
							thisPopup.disablePopup();
							thisPopup.close();

						});

				};

				setTimeout( function() {

					$.get( contentPath )

						.done( function( response ) {

							$wrapper.append( response );
							setupEvents();

							// first add the animation classes so it's
							// ready to be animated;
							setTimeout( function() {
								$wrapper.addClass( "an-drop-in an-long" );
							}, 50 );

							// then add the "go" class to begin the animation.
							setTimeout( function() {
								$wrapper.addClass( "go " + ex.openClass );
								callback.call();
							}, 100 );

						});

				}, delay );

			};

			thisPopup.remindLater = function() {

				var remindDate = new Date(),
					fiveMinutes = ( 5 * 60 * 1000 );

				remindDate.setTime( remindDate.getTime() + fiveMinutes );

				_this.setCookie(
					settings.cookieNames.remind,
					remindDate.getTime(),
					settings.remindTimeout * 60 * 1000
				);

			};

			thisPopup.disablePopup = function() {

				_this.setCookie(
					settings.cookieNames.disable,
					true,
					settings.disableTimeout * 60 * 1000
				);

			};

			thisPopup.setDone = function() {

				_this.setCookie(
					settings.cookieNames.done,
					true,
					settings.doneTimeout * 60 * 1000
				);

			};

			thisPopup.close = function() {

				var $wrapper = $( "#" + wrapperId );

				$wrapper
					.removeClass( ex.openClass )
					.removeClass( "go" );

				setTimeout( function() {

					$wrapper.remove();

				}, 1000 );

			};

			/**
			 * some extra method bindings to maintain the
			 * backwards-compatibility with production code;
			 * todo: remove after entire site revamped.
			 */
			thisPopup.closePopup = thisPopup.close;
			thisPopup.saveDoneFlag = thisPopup.setDone;
			thisPopup.launchSurvey = thisPopup.disablePopup;
			thisPopup.disableOnSitePopup = thisPopup.disablePopup;

			return thisPopup;

		};

		ex.registerPopup = function( name, data ) {

			// array for holding all the
			// running popups on the page.
			ex.popups = ex.popups || [];

			ex.popups.push({
				moduleName: name,
				moduleData: data
			});

			return data;

		};

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));

/**
 *
 * [ module ]
 * sessionDuration
 *
 * universal module for opening and handling the sessionDuration
 * modal dialog and such
 *
 */

( function( LC, $ ) {

	"use strict";

	LC.sessionDuration = lcmodule( function( exports ) {

		exports.moduleName = "sessionDuration.global";

		// "exports"" is always passed in, and always
		// returned at the end. Bind "ex" as it's easier to type
		// "_this" is used for private binding and maintaining scope

		var ex = exports = $.extend( {}, exports ),
			_this = {},
			domain = document.domain;

		/**
		 * module variables
		 */

		_this.cookieNames = {
			duration: "LCSessionVisitedDuration",
			returning: "LCSessionLastTS",
			visits: "LCNoOfVisit"
		};

		_this.oneHour = 1 / 24;
		_this.oneMinute = _this.oneHour / 60;

		_this.lcSite = "lanecrawford.com";
		_this.currentDomain = domain.substring( domain.indexOf("."), domain.length );

		/**
		 * initialise the sessionDuration module
		 */

		ex.init = function() {

			// initialisation code


			ex.events();

		};

		_this.start = function() {

			var referrer = document.referrer,
				sameVisit = referrer.includes( _this.lcSite ),
				isReturning = Cookies.get( _this.cookieNames.returning );

			if ( sameVisit || isReturning ) {

				_this.sessionDuration = _this.getPreviousSessionDuration();

			} else {

				_this.sessionDuration = 0;

			}

			_this.pageStart = _this.getTime();

		};

		_this.end = function() {

			var oneHour = _this.oneHour,
				twentySeconds = _this.oneMinute / 3,
				pageEnd = _this.pageEnd = _this.getTime(),
				pageStart = _this.pageStart,
				pageDuration = _this.getDuration( pageStart, pageEnd );

			LC.info( "Page Duration: ", pageDuration );

			_this.sessionDuration += pageDuration;

			LC.info( "Session Duration: ", _this.sessionDuration );

			Cookies.set( _this.cookieNames.duration, _this.sessionDuration, { expires: oneHour, domain: _this.currentDomain });
			Cookies.set( _this.cookieNames.returning, true, { expires: twentySeconds, domain: _this.currentDomain });

		};

		ex.getSessionDuration = function() {

			return _this.sessionDuration;

		};

		_this.getPreviousSessionDuration = function() {

			var previousSessionDuration = Cookies.get( _this.cookieNames.duration ) || 0;
			return parseInt( previousSessionDuration, 10 );

		};

		_this.getTime = function() {

			return new Date().getTime();

		};

		_this.getDuration = function( start, end ) {

			var duration;

			if ( typeof start !== "undefined" && typeof end !== "undefined" ) {

				duration = Math.round( ( end - start ) / 1000 );

			}

			return duration;

		};

		_this.getVisits = function() {

			var visitCount = Cookies.get( _this.cookieNames.visits ) || 0;
			return parseInt( visitCount, 10 );

		};

		_this.addVisit = function() {

			var visits = _this.getVisits(),
				visitsPlusOne = visits + 1;

			LC.info( "Site Visits: ", visitsPlusOne );

			Cookies.set( _this.cookieNames.visits, visitsPlusOne, { expires: 365, domain: _this.currentDomain });

		};

		ex.events = function() {

			$(window).on("unload", _this.end );

		};

		/*
			following start() and addVisit() function can't put into the above init()
			that is because we need to take care both LC2 (revamped) and non-revamped JS style.

			non-revamped JS is not in module style (e.g. http://media.lanecrawford.com/feature/js/popup/popup-survey-row.js)
			but revamped is in module style. So we can't use the init to do initialization.

			If we put these 2 functions in the init() that will cause the popup-survey-row.js running before init().
		 */
		_this.start();
		_this.addVisit();

		return exports;

	}({}));

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * product
	 * module for getting product data from server
	 *
	 * @param  {Object} exports | public-access object of methods/properties
	 * @param  {Object} _this   | private-scoped object of methods/properties
	 * @return {Object}         | return the public-access object
	 */
	new lcmodule2( "product", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * init() is executed when DOM is ready.
		 */
		ex.init = function() {

			_this.bodydata = $("body").data();

		};

		/**
		 * fire an ajax call to get the product data and then
		 * return a promise
		 * @param   {object}  ajaxData   | ajaxData needed to submit the request
		 * @return  {promise}            | deferred object
		 */
		ex.getProductData = function( ajaxData ) {

			if ( typeof ajaxData === "undefined" ||
				typeof ajaxData.productId === "undefined" ||
				typeof ajaxData.skuId === "undefined" ||
				typeof ajaxData.locale === "undefined" ||
				typeof ajaxData.profileId === "undefined" ||
				typeof ajaxData.cardTier === "undefined" ||
				typeof ajaxData.country === "undefined" ) {

				LC.warn( "missing at least one required parameter" );
				return new $.Deferred().reject( "missing at least one required parameter" );

			}

			return $.ajax({

				url: "/rest/bean/lc/rest/LCProductInfoRest/getProductInfo",
				type: "POST",
				cache: false,
				data: {
					arg1: ajaxData.productId,
					arg2: ajaxData.skuId,
					arg3: ajaxData.locale,
					arg4: ajaxData.profileId,
					arg5: ajaxData.cardTier,
					arg6: ajaxData.mps || false,
					arg7: ajaxData.country,
					arg8: ajaxData.recentView || false
				}

			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * wishlist.addMoveDialog
	 * The code needed to execute whenever the "add-move" dialog is opened,
	 * which allows the user to choose to add/move a wishlist item to
	 * another wishlist. This also allows creating new lists at that time.
	 *
	 * @param  {Object} exports | public-access object of methods/properties
	 * @param  {Object} _this   | private-scoped object of methods/properties
	 * @return {Object}         | return the public-access object
	 */
	new lcmodule2( "wishlist.addMoveDialog", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * setup() is executed when module is created
		 * but before the DOM is ready.
		 */
		ex.setup = function() {

		};

		/**
		 * lazyInit() is executed when modal is opened.
		 */
		ex.lazyInit = function( data ) {

			_this.name = data.name;
			_this.skuId = data.skuId;
			_this.type = data.type;
			_this.fromWishlistId = data.fromWishlistId;
			_this.fromCart = _.isString( data.fromWishlistId ) && data.fromWishlistId === "cart";
			_this.origin = data.origin;

			if ( _this.type !== "move" ) {
				_this.type = "add";
			}

			ex.getElements();
			ex.lazySubscribe();
			ex.lazyEvents();
			ex.slideInMobile();

		};

		ex.getElements = function() {

			var $newListTemplate = $("#tmpl-wishlist-new-list");
			_this.newListTemplate = Handlebars.compile( $newListTemplate.html() );

			_this.$dialog = $(".ui-dialog--" + _this.name );
			_this.$list = _this.$dialog.find( ".wishlist-squares__list" );
			_this.$create = _this.$dialog.find( ".wishlist-square--create" );
			_this.$form = _this.$dialog.find( "#addMoveWishlistForm" );
			_this.$input = _this.$dialog.find( "input" );
			_this.$icon = _this.$dialog.find( ".wishlist-squares__create-icon" );
			_this.$error = _this.$dialog.find( ".wishlist-squares__error-name" );

		};

		/**
		 * subtle slide in animation for the mobile
		 * viewport, as it will look more app-like.
		 */
		ex.slideInMobile = function() {

			setTimeout( function() {
				_this.$dialog.addClass( ex.openClass );
			}, 100 );

		};

		_this.submitNewListForm = function() {

			var newWishlistName = _this.$input.val();

			_this.$newest = $( _this.newListTemplate({ txtName: newWishlistName }) );

			_this.$form.slideUp( function() {

				_this.$input.val("");
				_this.$create.after( _this.$newest );

				LC.pubsub.trigger( "wishlist.create", {
					name: newWishlistName
				});

			});

		};

		_this.updateNewList = function() {

			var id = LC.wishlist.general.getNewestWishlist().repositoryId;

			_this.$newest
				.data( "wishlist-id", id )
				.removeClass( "wishlist-square--creating opc-50" );

		};

		_this.openNewListForm = function() {

			_this.$form.slideDown( function() {

				setTimeout(function() {
					_this.$input.focus();
				}, 100 );

			});

		};

		_this.addToList = function( wishlistId ) {

			if ( _this.type === "move" ) {

				if ( _this.fromCart ) {

					// trigger the wishlist module to move item
					LC.pubsub.trigger( "wishlist.moveToWishlist", {
						skuId: _this.skuId,
						wishlistId: wishlistId,
						origin: _this.origin
					});

				} else {

					LC.pubsub.trigger( "wishlist.moveBetweenWishlists", {
						skuId: _this.skuId,
						toWishlistId: wishlistId,
						fromWishlistId: _this.fromWishlistId
					});

				}

				LC.log( "moving", _this.skuId,
					"from", _this.fromWishlistId,
					"to", wishlistId );

			} else {

				LC.pubsub.trigger( "wishlist.add", {
					skuId: _this.skuId,
					wishlistId: wishlistId,
					origin: _this.origin
				});

				LC.log( "adding", _this.skuId,
					"to", wishlistId,
					"on page", _this.origin );
			}

			LC.modal.close( _this.$list );

		};

		/**
		 * events() is executed last, after the
		 * DOM is ready to receive event bindings.
		 */
		ex.lazyEvents = function() {

			var squares = ".wishlist-square" +
				":not( .wishlist-square--create )" +
				":not( .wishlist-square--creating )";

			_this.$form
				.off( "submit" )
				.on( "submit", function(e) {

					// stop the form from submitting and reloading page
					e.preventDefault();

					var name = _this.$input.val().trim(),
						length = _this.$input.attr( "maxlength" ),
						shortEnough = ( name.length <= length ),
						isFilled = name !== "",
						passes = shortEnough && isFilled;

					if ( !passes ) {

						_this.$error.removeClass( ex.hiddenClass );

					} else {

						_this.$error.addClass( ex.hiddenClass );
						_this.submitNewListForm();

					}

				});

			_this.$input
				.off( ".addMoveDialog" )
				.on(
					"keyup.addMoveDialog " +
					"compositionupdate.addMoveDialog " +
					"change.addMoveDialog",
					function( e ) {

						e.preventDefault();
						_this.$icon.toggleClass( "opc-0", _this.$input.val() === "" );

					});

			_this.$create
				.off( "click" )
				.on( "click", function( e ) {

					e.preventDefault();
					_this.openNewListForm();

				});

			_this.$list
				.off( "click" )
				.on( "click", squares, function( e ) {
					e.preventDefault();

					var $list = $(this),
						toWishlistId = $list.data( "wishlist-id" ),
						isEnabled = !$list.hasClass( "wishlist-square--disabled" ),
						canAddToList = ( toWishlistId && isEnabled );

					if ( canAddToList ) {
						_this.addToList( toWishlistId );
					}

				});

		};

		ex.lazySubscribe = function() {

			LC.pubsub
				.off( "wishlist.created.addMoveModal" )
				.on( "wishlist.created.addMoveModal", function() {
					_this.updateNewList();
				});

		};

		/**
		 * subscribe() is executed after setup() and
		 * before the DOM is ready.
		 */
		ex.subscribe = function() {

			// subscribe to listen for modal opening.

			LC.pubsub.on( "global.initModal", function( e, options ) {
				if ( options.name === "wishlist-add-move-popup" ) {
					ex.lazyInit( options );
				}
			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * wishlist.addMove
	 * Handle the global ability for moving items to a
	 * wishlist by leveraging a small popup dialog.
	 *
	 * @param  {Object} exports | public-access object of methods/properties
	 * @param  {Object} _this   | private-scoped object of methods/properties
	 * @return {Object}         | return the public-access object
	 */
	new lcmodule2( "wishlist.addMove", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * setup() is executed when module is created
		 * but before the DOM is ready.
		 */
		ex.setup = function() {

			_this.defaultList = LC.config.getValue( "wishlist.id" );

		};

		/**
		 * init() is executed when DOM is ready.
		 */
		ex.init = function() {

		};

		ex.getLists = function() {
			return LC.wishlist.general.getAllWishlists();
		};

		ex.getWishlistImages = function( wishlistId ) {
			return LC.wishlist.general.getWishlistImages( wishlistId );
		};

		ex.itemInWishlist = function( skuId, wishlistId ) {
			return LC.wishlist.general.itemInWishlist( skuId, wishlistId );
		};

		/**
		 * core method for either moving/adding an sku item to a wishlist
		 * @param   {String}  skuId           | the item's skuId which will be added/moved
		 * @param   {String}  method          | are we "move" or "add" the item to the wishlist
		 * @param   {String}  fromWishlistId  | wishlist id which we're "move" item from
		 * @param   {String}  origin          | optional "origin" name which is passed to the "add" method
		 */
		ex.addMove = function( skuId, method, fromWishlistId, origin ) {

			var redirectUrl;

			if ( !LC.helpers.isSku( skuId ) || !method ) {
				LC.warn( "Invalid skuId, or method, for moving item to wishlist" );
				return;
			}

			// if user logged in, add item to wishlist.
			if ( LC.config.getValue("profile.isLogin") ) {

				if ( method === "move" ) {

					ex.move( skuId, fromWishlistId, origin );

				} else {

					ex.add( skuId, fromWishlistId, origin );

				}

			} else {

				// if the user is not logged in, just take them
				// to the wishlist page for sign-up, and append redirect

				redirectUrl =
					new URI()
						.setQuery({ addToWishList: true, skuId: skuId })
						.toString();

				LC.pubsub.trigger("wishlist.logInAndRedirect", {
					redirect: redirectUrl
				});

			}

		};

		ex.move = function( skuId, fromWishlistId, origin ) {

			var lists = ex.getLists();

			if ( lists.length ) {

				// if user has more than one wishlist, show popup
				ex.createPopup( skuId, "move", fromWishlistId, origin );

			}

		};

		ex.add = function( skuId, fromWishlistId, origin ) {

			var lists = ex.getLists();

			if ( lists.length ) {

				// if user has more than one wishlist, show popup
				ex.createPopup( skuId, "add", fromWishlistId, origin );

			}

		};

		/**
		 * create a popup on the page which allows the user
		 * to select which wishlist they would like to add the
		 * given sku product to.
		 * @param   {String}  skuId           | the item's skuId which will be added/moved
		 * @param   {String}  type            | are we "move" or "add" the item to the wishlist
		 * @param   {String}  fromWishlistId  | wishlist id which we're moving item from, or "cart"
		 * @param   {String}  origin          | optional "origin" name which is passed to the "add" method
		 */
		ex.createPopup = function( skuId, type, fromWishlistId, origin ) {

			var lists = ex.getLists(),
				$content,
				$template = $("#tmpl-wishlist-add-move"),
				template = Handlebars.compile( $template.html() ),
				txtModalTitle = Lang[ "wishlist." + type + ".to" ],
				fromCart = _.isString( fromWishlistId ) && fromWishlistId === "cart";

			if ( !fromCart ) {

				// we cannot add/move a product to the same list
				// it is coming from, so we filter it out of the lists.
				lists = _.filter( lists, function( wishlist ) {
					return wishlist.repositoryId !== fromWishlistId;
				});

			}

			// massage the "lists" object so that the data is in the
			// format that the template file needs
			lists = lists.map( function( wishlist ) {

				wishlist.id = wishlist.repositoryId;
				wishlist.name = wishlist.name || Lang[ "wishlist.my.title" ];
				wishlist.images = ex.getWishlistImages( wishlist.id );
				wishlist.hasSku = !!ex.itemInWishlist( skuId, wishlist.id );
				wishlist.isPublic = wishlist.public;
				return wishlist;

			}).reverse();

			$content = $( template({
				txtTitle: txtModalTitle,
				lists: lists
			}));

			return LC.modal.openOnPageLoad( $content, {
				name: "wishlist-add-move-popup",
				skuId: skuId,
				type: type,
				fromWishlistId: fromWishlistId,
				origin: origin
			});

		};

		/**
		 * events() is executed last, after the
		 * DOM is ready to receive event bindings.
		 */
		ex.events = function() {



		};

		/**
		 * subscribe() is executed after setup() and
		 * before the DOM is ready.
		 */
		ex.subscribe = function() {

			LC.pubsub
				.off( "wishlist.openWishlistAddModal" )
				.on( "wishlist.openWishlistAddModal", function( e, data ) {

					if ( data && _.isObject( data ) ) {

						ex.addMove( data.skuId, data.method, data.fromWishlistId, data.origin );

					} else {

						LC.error( "addMove() requires an sku, method and wishlistId" );

					}

				});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	var base = "/rest/bean/lc/rest/LCGiftListService/",
		rest = "/rest/model/atg/commerce/gifts/GiftlistActor/";

	LC.wishlist = LC.wishlist || {};
	LC.wishlist.endpoints = {

		create: rest + "createGiftlist",
		update: rest + "updateGiftlist",
		delete: rest + "deleteGiftlist",
		setState: base + "setGiftListState",
		add: base + "addItemToGiftlist",
		remove: base + "removeItemFromGiftList",
		get: {
			byId: base + "getGiftListByGiftListId",
			byVipNumber: base + "getGiftListByVipNumber",
			all: rest + "profileGiftlists"
		},
		move: {
			toBag: base + "moveItemFromGiftListToShoppingBag",
			toList: base + "moveItemFromShoppingBagToGiftList"
		},
		gateway: "/gateway/wishlist",
		wishlist: "/wishlist/"

	};

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * wishlist.general
	 * module used handle global wishlist functionality,
	 * including redirects for logging in.
	 *
	 * @param	{Object} exports | public-access object of methods/properties
	 * @param	{Object} _this   | private-scoped object of methods/properties
	 * @return  {Object}         | return the public-access object
	 */
	new lcmodule2( "wishlist.general", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * setup() is executed when module is created
		 * but before the DOM is ready.
		 */
		ex.setup = function() {

			/**
			 * whether the wishlist is ready with downloaded lists
			 * @type  {boolean}
			 */
			_this.listsDownloaded = false;

			/**
			 * the id of the default wishlist
			 * @type  {string}
			 */
			_this.defaultList = LC.config.getValue( "wishlist.id" );

			/**
			 * create the object of endpoints for ajax requests
			 * @type  {object}
			 */
			_this.endpoints = LC.wishlist.endpoints;

		};

		/**
		 * init() is executed when DOM is ready.
		 */
		ex.init = function() {

			_this.$heartIcon = $(".iconbar__giftlist-icon--fill");
			_this.fillClass = "filled";

			ex.downloadWishlists().done(function() {
				ex.autoAdd();
			});


		};

		/**
		 * onReady function so that other modules can rely on the
		 * server-fetched "wishlists" object (getAllWishlists()) to
		 * have completed and return a valid object
		 * @return  {Promise}  | a promise that the module will be ready
		 */
		ex.onReady = function() {

			var promise = new $.Deferred(),
				isReady = function() {
					if ( _this.listsDownloaded ) {
						promise.resolve();
					} else {
						setTimeout( isReady, 200 );
					}
				};

			isReady();

			return promise;

		};

		/**
		 * get all the wishlists for the current user from
		 * the server and then store them internally for usage
		 * in the UI, this should alleviate server-load when
		 * trying to render the page with JSP.
		 * @return { Promise } | The promise of this ajax call
		 */
		ex.downloadWishlists = function() {

			return ex.get.all().done( function( response ) {

				var lists = response.giftlists || [],
					newestList,
					isLoggedIn = LC.config.getValue( "profile.isLogin" ),
					defaultName = Lang[ "wishlist.my.title" ];

				if ( lists.length ) {

					newestList = _.findLast( lists ).repositoryId;
					_this.listsDownloaded = true;
					_this.defaultList = newestList;

					LC.config.setValue( "wishlist.lists", lists );
					LC.config.setValue( "wishlist.id", newestList );
					LC.config.setValue( "profile.giftlist", newestList );

					LC.pubsub.trigger( "wishlist.downloaded" );
					ex.updateHeart();

				} else if ( isLoggedIn ) {

					// if there were no giftlists in the user's
					// profile, then we need to create one so they
					// can use it properly.
					LC.wishlist.modify.createWishlist( defaultName, false, false );

				} else {

					_this.listsDownloaded = true;

				}

			});

		};

		/**
		 * return a copy of wishlists stored internally
		 * @return  {Array}  | array of wishlists assigned to current user
		 */
		ex.getAllWishlists = function() {

			return $.extend( true, [], LC.config.getValue( "wishlist.lists" ) );

		};

		/**
		 * return the wishlist data which matches the given id
		 * @param   {String}  wishlistId  | id of wishlist to return object for
		 * @return  {Object}              | object containing details of wishlist
		 */
		ex.getWishlistById = function( wishlistId ) {

			return _.find( ex.getAllWishlists(), {
				repositoryId: wishlistId
			}) || {};

		};

		/**
		 * return the newest wishlist in the array of lists
		 * @return  {Object}  | object containing details of latest wishlist
		 */
		ex.getNewestWishlist = function() {

			return _.findLast( ex.getAllWishlists() );

		};

		/**
		 * return the images to display for the wishlist
		 * @param   {String}  wishlistId  | id of wishlist to get image for
		 * @return  {Array}               | array of image uris for products in the list
		 */
		ex.getWishlistImages = function( wishlistId ) {

			var wishlist = ex.getWishlistById( wishlistId ),
				images = [],
				items,
				item;

			if ( wishlist.giftlistItems.length ) {

				// reverse, so the newest image is first
				items = wishlist.giftlistItems.reverse();

				for ( item in items ) {
					// we only want max 4 images
					if ( item < 4 ) {
						images[ item ] = LC.helpers.imageFromId( items[ item ].productId, "s" );
					}
				}

			}

			return images;

		};

		/**
		 * automatically add a wishlist item to the user's
		 * wishlist if the query-parameter "addToWishList" is true.
		 */
		ex.autoAdd = function() {

			var params = LC.helpers.getQueryParams(),
				pageName = $("main").data("category"),
				skuId,
				shouldAdd,
				shouldMove;

			skuId = params.skuId;
			shouldAdd = ( params.addToWishList === "true" );
			shouldMove = ( params.moveToWishList === "true" );

			if ( shouldAdd && skuId ) {

				LC.info( "attempting to auto-add", skuId, "to the wishlist" );

				LC.pubsub.trigger("wishlist.openWishlistAddModal", {
					skuId: skuId,
					method: "add",
					origin: "auto add from " + pageName
				});

			} else if ( shouldMove && skuId ) {

				LC.info( "attempting to auto-move", skuId, "to the wishlist" );

				LC.pubsub.trigger("wishlist.openWishlistAddModal", {
					skuId: skuId,
					method: "move",
					fromWishlistId: "cart",
					origin: "auto move from " + pageName
				});

			}

		};

		/**
		 *
		 * object holding the ajax get methods for getting
		 * different wishlists by id/name/customer
		 * @type {Object}
		 *
		 */
		ex.get = {};

		/**
		 *
		 * general ajax get method, to avoid repetition of
		 * complicated ajax methods, we provide a reusable one
		 * for the get methods.
		 *
		 * @param  { String } endpoint | URL of the ajax call's endpoint
		 * @param  { String } value    | The ATG argument value for this ajax call
		 * @return { Promise }         | The promise of this ajax call, so we can handle requests
		 *
		 */
		ex.get.ajax = function( endpoint, value ) {

			if ( typeof endpoint !== "undefined" &&
				typeof value !== "undefined" ) {

				_this.ajaxGet = $.ajax({

					url: endpoint,
					type: "POST",
					dataType: "json",
					data: { arg1: value }

				});

				return _this.ajaxGet;

			} else {

				LC.warn( "no endpoint / value provided for retrieving wishlist" );

				// return a failing Promise.
				return new $.Deferred().reject();

			}

		};

		/**
		 *
		 * Method to get return all the giftlists for the current user,
		 * mostly used for helping with adding/moving items between lists.
		 *
		 * @return { Promise } 		| returns a promise to use async.
		 *
		 */
		ex.get.all = function() {

			var endpoint = _this.endpoints.get.all,
				id = LC.config.getValue( "profile.vipNumber" );

			return ex.get.ajax( endpoint, id );

		};

		/**
		 *
		 * Method to get a giftlist by the given id, or current profile's id.
		 *
		 * @param  { String } id 	| the id of a wishlist
		 * @return { Promise } 		| returns a promise to use async.
		 *
		 */
		ex.get.byId = function( id ) {

			if ( id ) {

				return ex.get.ajax( _this.endpoints.get.byId, id );

			} else {

				LC.warn( "no wishlist ID, returning default wishlist." );
				return ex.get.myWishlist();

			}

		};

		/**
		 *
		 * Method to get current profile's default wishlist id.
		 *
		 * @return { Promise } | returns a promise to use async.
		 *
		 */
		ex.get.myWishlist = function() {

			var endpoint = _this.endpoints.get.byId,
				id = _this.defaultList;

			return ex.get.ajax( endpoint, id );

		};

		/**
		 *
		 * Method to get a giftlist by the current profile's id.
		 *
		 * @return { Promise } | returns a promise to use async.
		 *
		 */
		ex.get.vipNumber = function() {

			var endpoint = _this.endpoints.get.byVipNumber,
				value = LC.config.getValue( "profile.vipNumber" );

			return ex.get.ajax( endpoint, value );

		};

		/**
		 *
		 * method for determining if a product is already
		 * in the wishlist.
		 *
		 * @param  { String } skuid 			| The skuid we want to check for.
		 * @param  { String } wishlistId 	| The id of hte wishlist we wish to check.
		 * @return { Boolean }      		| If the product is in the list
		 *
		 */
		ex.itemInWishlist = function( skuid, wishlistId ) {

			var wishlist,
				isProductId = LC.helpers.isProduct( skuid );

			if ( !LC.config.getValue( "profile.isLogin" ) ) {
				return false;
			}

			if ( !LC.helpers.isSku( skuid ) ) {
				LC.warn( "sku parameter is invalid, cannot check wishlist" );
				return false;
			}

			// if no wishlist provided, default to the default wishlist
			wishlistId = wishlistId || _this.defaultList;

			// get the contents of the wishlist, or an empty object
			wishlist = _.filter( ex.getAllWishlists(), { repositoryId: wishlistId } )[ 0 ] || {};

			// return boolean if the sku is found in the wishlist
			return _.find( wishlist.giftlistItems, function( item ) {
				if ( isProductId ) {
					return item.productId === skuid;
				} else {
					return item.skuId === skuid;
				}
			}) || false;

		};

		/**
		 * check if the given SKU is present in any of the
		 * user's wishlists, returning either the giftlistId, or false.
		 *
		 * @param   { String }  skuid  	| skuid of product to check for
		 * @return  { Boolean, String } | the giftlistId it was found in, or false.
		 */
		ex.itemInAnyWishlist = function( skuid ) {

			var foundInList,
				isProductId = LC.helpers.isProduct( skuid );

			if ( !LC.config.getValue( "profile.isLogin" ) ) {
				return false;
			}

			if ( !LC.helpers.isSku( skuid ) && !LC.helpers.isProduct( skuid ) ) {

				LC.warn( "sku parameter is invalid, cannot check wishlist" );
				return false;

			}

			foundInList = _.find( ex.getAllWishlists(), function( list ) {
				return _.find( list.giftlistItems, function( item ) {
					if ( isProductId ) {
						return item.productId === skuid;
					} else {
						return item.skuId === skuid;
					}
				});
			});

			if ( foundInList ) {

				return foundInList.repositoryId;

			} else {

				return false;

			}

		};

		/**
		 * check if the given productid is present in any of the
		 * user's wishlists, returning either the giftlistId, or false.
		 *
		 * @param   { String }  productid	| product id to check for
		 * @return  { Boolean, String } 	| the giftlistId it was found in, or false.
		 */
		ex.productInAnyWishlist = function( productid ) {

			var foundInList, allLists;

			if ( !LC.config.getValue( "profile.isLogin" ) ) {
				return false;
			}

			if (!LC.helpers.isProduct(productid)) {
				LC.warn( "productid parameter is invalid, cannot check wishlist" );
				return false;
			}

			allLists = LC.wishlist.general.getAllWishlists();

			foundInList = _.find(allLists, function(list) {
				return _.find(list.giftlistItems, function(item) {
					return item.productId === productid;
				});
			});

			if ( foundInList ) {
				return foundInList.repositoryId;
			} else {
				return false;
			}

		};

		/**
		 * returns true if any wishlist has items in it
		 * @return  {Boolean}  | does user have any items in wishlist?
		 */
		ex.anyWishlistHasItems = function() {

			var wishlists = LC.wishlist.general.getAllWishlists();

			return _.some( wishlists, function( wishlist ) {
				return wishlist.giftlistItems.length > 0;
			});

		};

		/**
		 *
		 * Generic method for posting data to the wishlist endpoints
		 *
		 * @param  { String }  sku        | skuid to be processed
		 * @param  { String }  wishlistId | the ID of the wishlist we want to modify
		 * @param  { String }  endpoint   | the endpoint to post data to
		 * @return { Promise }            | a deferred promise object to resolve/reject
		 *
 		 */
		_this.wishlistOperation = function( sku, wishlistId, endpoint ) {

			var params,
				promise = new $.Deferred();

			if ( typeof sku !== "undefined" &&
				LC.helpers.isSku( sku ) &&
				typeof endpoint !== "undefined" &&
				typeof wishlistId !== "undefined" ) {

				params = {

					arg1: wishlistId,
					arg2: sku,
					arg3: 1,
					arg4: LC.config.getValue( "site.siteID" )

				};

				// fire the ajax call to the wishlist endpoint
				// and wait for the response
				$.ajax({

					url: endpoint,
					type: "POST",
					dataType: "json",
					data: params

				}).always( function( operationResponse ) {

					// after the ajax call has completed, we need
					// to download all the wishlists again as they
					// are not returned in the payload
					ex.downloadWishlists().always( function() {

						// after the wishlists are downloaded, and
						// stored in teh cache, then we resolve the promise
						promise.resolve( operationResponse );

					});

				});

			} else {

				LC.warn( "posting to the wishlist endpoint failed, \n" +
					"because the parameters passed were incorrect" );

				promise.reject();

			}

			return promise;

		};

		/**
		 *
		 * Add or Remove items from wishlists.
		 * This is to avoid complicated and duplicated logic in
		 * the add and remove methods respectively.
		 *
		 * @param { String }   type       | "add" / "remove" type to determine the method
		 * @param { String }   sku        | skuid to be added / removed from wishlist
		 * @param { String }   wishlistId | the ID of the wishlist we want to modify
		 * @return { Promise }            | a deferred promise object to resolve/reject
		 *
		 */
		_this.addRemoveItem = function( type, sku, wishlistId ) {

			var url;

			if ( typeof type !== "undefined" ) {

				if ( !wishlistId ) {
					wishlistId = _this.defaultList;
				}

				if ( type === "remove" ) {
					url = _this.endpoints.remove;
				} else {
					url = _this.endpoints.add;
				}

				return _this.wishlistOperation( sku, wishlistId, url );

			} else {

				LC.warn( "No add/remove type was given" );
				return new $.Deferred().reject();

			}

		};

		/**
		 *
		 * Add an item to a wishlist, by sending the parameters through
		 * to the generic add / remove items method. Always fill the heart
		 * in the header, if it added successfully.
		 *
		 * @param { Object } data       | an object containing the skuId
		 * @param { String } wishlistId | the id of the wishlist we want to add to
		 * @param { Boolean } track 	| should fire tracking or not?
		 * @return { Promise } 			| the promise object of the ajax call
		 *
		 */
		ex.addItem = function( data, wishlistId, track ) {

			var sku = data.skuId,
				result = _this.addRemoveItem( "add", sku, wishlistId );

			if ( typeof track === "undefined" ) {
				track = true;
			}

			result.done( function( response ) {

				if ( response.atgResponse.resultCode === "200" ) {

					// update the icon to be filled.
					ex.fillHeart( true );

					LC.info( "successfully added", sku, "to the wishlist", wishlistId );

					// trigger a globally listenable event
					LC.pubsub.trigger( "wishlist.itemAdded", {
						skuId: sku,
						wishlistId: wishlistId
					});

					if ( track ) {
						LC.pubsub.trigger( "wishlist.trackAddItem", {
							skuId: sku,
							productId: LC.helpers.productFromSku( sku ),
							origin: data.origin
						});
					}

				} else {

					LC.error( "problem adding", sku, "to the wishlist!" );

					// trigger a globally listenable event
					LC.pubsub.trigger("wishlist.itemAddFailed", {
						skuId: sku,
						wishlistId: wishlistId
					});

					if ( track ) {
						LC.pubsub.trigger( "wishlist.trackAddItemFailed", {
							productId: LC.helpers.productFromSku( sku ),
							origin: data.origin
						});
					}

				}

			});

			return result;

		};

		/**
		 *
		 * Remove an item from a wishlist, by sending the parameters through
		 * to the generic add / remove items method. After that's finished
		 * we then attempt to update the heart-icon by getting the giftlistItems
		 * count from another ajax request.
		 *
		 * @param { Object } data       | an object containing the skuId
		 * @param { String } wishlistId | the id of the wishlist we want to remove from
		 * @param { Boolean } track 	| should fire tracking or not?
		 * @return { Promise } 			| the promise object of the ajax call
		 *
		 */
		ex.removeItem = function( data, wishlistId, track ) {

			var sku = data.skuId,
				result = _this.addRemoveItem( "remove", sku, wishlistId );

			if ( typeof track === "undefined" ) {
				track = true;
			}

			result.done( function( response ) {

				if ( response.atgResponse.resultCode === "200" ) {

					LC.info( "successfully removed", sku, "from the wishlist", wishlistId );

					LC.pubsub.trigger("wishlist.itemRemoved", {
						skuId: sku,
						wishlistId: wishlistId
					});

					if ( track ) {
						LC.pubsub.trigger( "wishlist.trackRemoveItem", {
							productId: LC.helpers.productFromSku( sku ),
							origin: data.origin
						});
					}

				} else {

					LC.error( "problem removing", sku, "from the wishlist", wishlistId );

					LC.pubsub.trigger("wishlist.itemRemoveFailed", {
						skuId: sku,
						wishlistId: wishlistId
					});

					if ( track ) {
						LC.pubsub.trigger( "wishlist.trackRemoveItemFailed", {
							productId: LC.helpers.productFromSku( sku ),
							origin: data.origin
						});
					}

				}

			});

			return result;

		};

		/**
		 *
		 * Move item to/from wishlist.
		 * This is to avoid complicated and duplicated logic in
		 * the moveFrom and moveTo methods respectively.
		 *
		 * @param { String }   type       | "toWishlist" / "toCart" type to determine the method
		 * @param { String }   sku        | skuid to be operated on
		 * @param { String }   wishlistId | the ID of the wishlist we want to modify
		 * @return { Promise }            | a deferred promise object to resolve/reject
		 *
		 */
		_this.moveItem = function( type, sku, wishlistId ) {

			var url;

			if ( typeof type !== "undefined" ) {

				if ( !wishlistId ) {
					wishlistId = _this.defaultList;
				}

				if ( type === "toWishlist" ) {
					url = _this.endpoints.move.toList;
				} else {
					url = _this.endpoints.move.toBag;
				}

				return _this.wishlistOperation( sku, wishlistId, url );

			} else {

				LC.warn( "No move type was given" );
				return new $.Deferred().reject();

			}

		};

		/**
		 *
		 * Move and item from one wishlist to another by using
		 * the addItem() and removeItem() methods in sync
		 *
		 * @param 	{ Object } data       		| an object containing the skuId
		 * @param   { String } fromWishlistId  	| the id of the wishlist we want to remove from
		 * @param 	{ String } toWishlistId 	| the id of the wishlist we want to move to
		 * @return 	{ Promise } 				| the promise object of the ajax call
		 *
		 */
		ex.moveItemBetweenWishlists = function( data, fromWishlistId, toWishlistId ) {

			var sku = data.skuId,
				result = new $.Deferred();

			ex.addItem( data, toWishlistId, false )
				.done( function( addResponse ) {

					if ( addResponse.atgResponse.resultCode === "200" ||
				 		 addResponse.atgResponse.resultCode === "204" ) {

						ex.removeItem( data, fromWishlistId, false )
							.done( function( removeResponse ) {

								if ( removeResponse.atgResponse.resultCode === "200" ) {

									LC.info( "successfully moved", sku,
										"from:", fromWishlistId, ", to:", toWishlistId );

									// custom object resolution for the promise,
									// and we supply both the add and remove response
									result.resolve({
										atgResponse: {
											message: "success",
											resultCode: "200",
											addResponse: addResponse,
											removeResponse: removeResponse
										}
									});

									LC.pubsub.trigger( "wishlist.itemMoved", {
										to: "wishlist",
										skuId: sku,
										wishlistId: toWishlistId,
										fromWishlistId: fromWishlistId
									});

									LC.pubsub.trigger( "wishlist.trackMoveBetweenWishlists", {
										productId: LC.helpers.productFromSku( sku )
									});

								} else {

									result.resolve( removeResponse );

								}

							});

					} else {

						result.resolve( addResponse );

						LC.pubsub.trigger("wishlist.itemMoveFailed", {
							to: "wishlist",
							skuId: sku,
							wishlistId: toWishlistId,
							fromWishlistId: fromWishlistId
						});

						LC.pubsub.trigger( "wishlist.trackMoveBetweenWishlistsFailed", {
							productId: LC.helpers.productFromSku( sku )
						});

					}

				});

			return result;

		};

		/**
		 *
		 * Move an item from the given wishlist to the shopping cart.
		 * After that's finished we then attempt to update the heart-icon
		 *
		 * @param { Object } data       | an object containing the skuId
		 * @param { String } wishlistId | the id of the wishlist we want to remove from
		 * @return { Promise } 			| the promise object of the ajax call
		 *
		 */
		ex.moveItemToCart = function( data, wishlistId ) {

			var sku = data.skuId,
				result = _this.moveItem( "toCart", sku, wishlistId );

			result.done( function( response ) {

				if ( response.atgResponse.resultCode === "200" ) {

					LC.info( "successfully moved", sku,  "to the cart!" );

					LC.pubsub.trigger("wishlist.itemMoved", {
						to: "cart",
						skuId: sku,
						wishlistId: wishlistId
					});

					LC.pubsub.trigger( "wishlist.trackMoveToCart", {
						productId: LC.helpers.productFromSku( sku )
					});

				} else {

					LC.error( "problem moving", sku, "to the cart!" );

					LC.pubsub.trigger("wishlist.itemMoveFailed", {
						to: "cart",
						skuId: sku,
						wishlistId: wishlistId
					});

					LC.pubsub.trigger( "wishlist.trackMoveToCartFailed", {
						productId: LC.helpers.productFromSku( sku )
					});

				}

			});

			result.always( function() {

				LC.pubsub.trigger( "cart.refresh", {
					type: "moveToCart"
				});

			});

			return result;

		};

		/**
		 *
		 * Move an item from the shopping cart to the given wishlist.
		 * After that's finished we then attempt to update the heart-icon
		 *
		 * @param { Object } data       | an object containing the skuId
		 * @param { String } wishlistId | the id of the wishlist we want to remove from
		 * @return { Promise } 			| the promise object of the ajax call
		 *
		 */
		ex.moveItemToWishlist = function( data, wishlistId ) {

			var sku = data.skuId,
				result = _this.moveItem( "toWishlist", sku, wishlistId );

			result.done( function( response ) {

				if ( response.atgResponse.resultCode === "200" ) {

					// update the icon to be filled.
					ex.fillHeart( true );

					LC.info( "successfully moved", sku, "to the wishlist", wishlistId );

					LC.pubsub.trigger("wishlist.itemMoved", {
						to: "wishlist",
						skuId: sku,
						wishlistId: wishlistId
					});

					LC.pubsub.trigger( "wishlist.trackMoveToWishlist", {
						productId: LC.helpers.productFromSku( sku ),
						origin: data.origin
					});

				} else {

					LC.error( "problem moving", sku, "to the wishlist!" );

					LC.pubsub.trigger("wishlist.itemMoveFailed", {
						to: "wishlist",
						skuId: sku,
						wishlistId: wishlistId
					});

					LC.pubsub.trigger( "wishlist.trackMoveToWishlistFailed", {
						productId: LC.helpers.productFromSku( sku ),
						origin: data.origin
					});

				}

			});

			result.always( function() {

				LC.pubsub.trigger( "cart.refresh", {
					type: "moveToWishlist"
				});

			});

			return result;

		};

		/**
		 * method to handle sending a user to the Wishlist
		 * login/signin page, and then redirect them back
		 * to wherever needed.
		 *
		 * @param  { String } redirect | URL to go back to after log in.
		 * 							   | the URL must contain a valid LaneCrawford domain
		 */
		ex.logInAndRedirect = function( redirect ) {

			var gatewayUrl = new URI( _this.endpoints.gateway ),
				endPoint;

			if ( !redirect ) {

				// if no "redirect" was set, we want to send the
				// user back to the current page.

				endPoint = new URI().toString();

			} else {

				endPoint = new URI( redirect ).toString();

			}

			gatewayUrl.setQuery({
				action: "register",
				endPoint: endPoint
			});

			gatewayUrl = gatewayUrl.toString();
			LC.location.href = gatewayUrl;

		};

		/**
		 * update the heart icon in the header if
		 * it should be filled or not
		 */
		ex.updateHeart = function() {

			ex.fillHeart( ex.anyWishlistHasItems() );

		};

		/**
		 * update the header ui with a filled heart icon.
		 * @param  { Boolean } filled | whether to fill or not.
		 */
		ex.fillHeart = function( filled ) {

			filled = filled || false;
			_this.$heartIcon.toggleClass( _this.fillClass, filled );

		};

		ex.events = function() {

		};

		ex.subscribe = function() {

			LC.pubsub.on("wishlist.add", function( e, data ) {
				ex.addItem( data, data.wishlistId );
			});

			LC.pubsub.on("wishlist.remove", function( e, data ) {
				ex.removeItem( data, data.wishlistId );
			});

			LC.pubsub.on("wishlist.moveToCart", function( e, data ) {
				ex.moveItemToCart( data, data.wishlistId );
			});

			LC.pubsub.on("wishlist.moveToWishlist", function( e, data ) {
				ex.moveItemToWishlist( data, data.wishlistId );
			});

			LC.pubsub.on("wishlist.moveBetweenWishlists", function( e, data ) {
				ex.moveItemBetweenWishlists( data, data.fromWishlistId, data.toWishlistId );
			});

			LC.pubsub.on("wishlist.logInAndRedirect", function( e, data ) {
				ex.logInAndRedirect( data.redirect );
			});

			LC.pubsub.on("wishlist.update", function( e, options ) {
				if ( options ) {
					ex.fillHeart( options.filled );
				}
			});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


( function( LC, $ ) {

	"use strict";

	/**
	 *
	 * wishlist.modify
	 * methods for use in modifying (create, edit, delete) the
	 * customer's wishlists
	 *
	 * @param  {Object} exports | public-access object of methods/properties
	 * @param  {Object} _this   | private-scoped object of methods/properties
	 * @return {Object}         | return the public-access object
	 */
	new lcmodule2( "wishlist.modify", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * setup() is executed when module is created
		 * but before the DOM is ready.
		 */
		ex.setup = function() {

			/**
			 * create the object of endpoints for ajax requests
			 * @type  {object}
			 */
			_this.endpoints = LC.wishlist.endpoints;

		};

		/**
		 * init() is executed when DOM is ready.
		 */
		ex.init = function() {



		};

		/**
		 * using the given name, description and date, create
		 * a new wishlist for the current customer.
		 * @param   {String}  name          | name for the wishlist
		 * @param   {String}  [isPublic] 	| should the wishlist be set public (false)
		 * @param   {String}  [isTracked] 	| should the wishlist creation be tracked (true)
		 * @param   {String}  [description] | a description to store in db
		 * @param   {String}  [date]        | a date that the wishlist event occurs
		 * @return  {Promise}              	| the response from modify operation
		 */
		ex.createWishlist = function( name, isPublic, isTracked, description, date ) {

			var endpoint = _this.endpoints.create,
				data = {
					eventName: name,
					description: description,
					eventDate: date,
					isPublic: isPublic
				};

			if ( typeof name === "undefined" ) {

				LC.error( "Cannot create wishlist, as no 'name' was passed" );
				return new $.Deferred().reject();

			}

			if ( typeof isTracked === "undefined" ) {
				isTracked = true;
			}

			return _this.modifyOperation( data, endpoint )
				.done( function() {

					var newestList = LC.wishlist.general.getNewestWishlist().repositoryId;
					LC.pubsub.trigger( "wishlist.created", data );

					if ( isPublic !== true ) {
						ex.setWishlistState( newestList, false );
					}

					if ( isTracked ) {
						LC.pubsub.trigger( "wishlist.trackCreateWishlist", name );
					}

				})
				.fail( function() {

					LC.pubsub.trigger( "wishlist.trackCreateWishlistFailed", name );

				});

		};

		/**
		 * delete the wishlist at a given giftlistId if
		 * associated with the current user
		 * @param   {String}  wishlistId   | the id of wishlist to delete
		 * @return  {Promise}              | the response from modify operation
		 */
		ex.deleteWishlist = function( wishlistId ) {

			var endpoint = _this.endpoints.delete,
				data = { giftlistId: wishlistId };

			if ( typeof wishlistId === "undefined" ) {

				LC.error( "Cannot delete wishlist, as no 'wishlistId' was passed" );
				return new $.Deferred().reject();

			}

			return _this.modifyOperation( data, endpoint )
				.done( function( response ) {

					if ( !response || !response.formError ) {

						LC.pubsub.trigger( "wishlist.deleted", wishlistId );

					} else {

						LC.error( response.formExceptions );

					}

				})
				.fail( function() {

					LC.pubsub.trigger( "wishlist.trackDeleteWishlistFailed" );

				});

		};

		/**
		 * update a wishlist at the given id, with a new name,
		 * description and date; for the current customer.
		 * @param   {String}  wishlistId    | the id of wishlist to delete
		 * @param   {String}  [name]        | name for the wishlist
		 * @param   {String}  [description] | a description to store in db
		 * @param   {String}  [date]        | a date that the wishlist event occurs
		 * @return  {Promise}               | the response from modify operation
		 */
		ex.modifyWishlist = function( wishlistId, name, description, date ) {

			if ( typeof wishlistId === "undefined" ) {

				LC.error( "Cannot modify wishlist, as no 'wishlistId' was passed" );
				return new $.Deferred().reject();

			}

			var endpoint = _this.endpoints.update,
				data = {
					giftlistId: wishlistId,
					eventName: name,
					description: description,
					eventDate: date
				};

			return _this.modifyOperation( data, endpoint )
				.done( function( response ) {

					if ( !response.formError ) {

						LC.pubsub.trigger( "wishlist.updated", wishlistId );

					} else {

						LC.error( response.formExceptions );

					}

				})
				.fail( function() {

					LC.pubsub.trigger( "wishlist.trackModifyWishlistFailed", name );

				});

		};

		/**
		 * set the state (public/private) of a wishlist
		 * with the given id
		 * @param   {String}   wishlistId   | the id of wishlist to delete
		 * @param   {Boolean}  isPublic     | whether the wishlist is public or not
		 * @return  {Promise}               | the response from modify operation
		 */
		ex.setWishlistState = function( wishlistId, isPublic ) {

			if ( typeof wishlistId === "undefined" || typeof isPublic === "undefined" ) {

				LC.warn( "Cannot set wishlist state without parameters" );
				return new $.Deferred().reject();

			}

			var endpoint = _this.endpoints.setState,
				data = {
					arg1: wishlistId,
					arg2: isPublic
				};

			return _this.modifyOperation( data, endpoint )
				.done( function() {

					LC.pubsub.trigger( "wishlist.stateChanged", wishlistId );
					LC.pubsub.trigger( "wishlist.updated", wishlistId );

				});

		};

		/**
		 * the unified modification ajax call, used as a
		 * single point of entry to reduce duplicated ajax
		 * call logic in each operation.
		 * @param   {Object}  data      | data to be sent to the endpoint
		 * @param   {String}  endpoint  | the rest endpoint to post the data to
		 * @return  {Promise}           | jQuery promise
		 */
		_this.modifyOperation = function( data, endpoint ) {

			var promise = new $.Deferred();
			data = LC.helpers.session.mergeSessionConfirmation( data );

			if ( typeof endpoint === "undefined" ) {

				LC.warn( "posting to the wishlist endpoint failed, \n" +
					"because the parameters passed were incorrect" );

				promise.reject();

			} else {

				$.post( endpoint, data )

					.done( function( response ) {

						if ( !response.formError ) {

							LC.wishlist.general.downloadWishlists()
								.done( function( successResponse ) {
									promise.resolve( successResponse.giftlists || [] );
								});

						} else {

							promise.resolve( response );

						}

					})

					.fail( function() {

						promise.reject();

					});

			}

			return promise;

		};

		/**
		 * events() is executed last, after the
		 * DOM is ready to receive event bindings.
		 */
		ex.events = function() {



		};

		/**
		 * subscribe() is executed after setup() and
		 * before the DOM is ready.
		 */
		ex.subscribe = function() {

			LC.pubsub
				.off( "wishlist.delete" )
				.on("wishlist.delete", function( e, data ) {
					data = data || {};
					ex.deleteWishlist( data.id );
				});

			LC.pubsub
				.off( "wishlist.create" )
				.on("wishlist.create", function( e, data ) {
					data = data || {};
					ex.createWishlist( data.name, data.isPublic, data.isTracked,
						data.description, data.date );
				});

			LC.pubsub
				.off( "wishlist.modify" )
				.on("wishlist.modify", function( e, data ) {
					data = data || {};
					ex.modifyWishlist( data.id, data.name, data.description, data.date );
				});

			LC.pubsub
				.off( "wishlist.setState" )
				.on("wishlist.setState", function( e, data ) {
					data = data || {};
					ex.setWishlistState( data.id, data.isPublic );
				});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));


( function( LC ) {

	"use strict";

	/**
	 *
	 * wishlist.tracking
	 *
	 * @param	{Object} exports | public-access object of methods/properties
	 * @param	{Object} _this   | private-scoped object of methods/properties
	 * @return  {Object}         | return the public-access object
	 */
	new lcmodule2( "wishlist.tracking", function( exports, _this ) {

		// "ex" is short-hand for exports (public access) object
		var ex = exports;

		/**
		 * setup() is executed when module is created
		 * but before the DOM is ready.
		 */
		ex.setup = function() {

			/**
			 * the name used for category of every wishlist event
			 * @type {String}
			 */
			_this.categoryName = "Wish List";

		};

		/**
		 * initialise the tracking module.
		 */
		ex.init = function() {

		};

		ex.checkFailed = function( message, failed ) {
			if ( failed ) {
				return "FAILED: " + message;
			} else {
				return message;
			}
		};

		ex.trackAddItem = function( data, failed ) {

			var skuId = data.skuId,
				productId = LC.helpers.productFromSku( skuId ),
				locale = LC.config.getValue( "profile.locale" ),
				profileId = LC.config.getValue( "profile.vipNumber" ) || LC.config.getValue( "profile.id" ),
				cardTier = LC.config.getValue( "profile.vipLevel" ),
				country = LC.config.getValue( "profile.countryCode" ),
				currencyCode = LC.format.currencyCode();

			if ( !failed ) {

				LC.product.getProductData({
					productId: productId,
					skuId: skuId,
					locale: locale,
					profileId: profileId,
					cardTier: cardTier,
					country: country
				})
					.done( function( productData ) {

						// track enhanced commerce
						var originalPrice = LC.helpers.getNumber( productData.atgResponse.priceInfo.listPrice ),
							salePrice = LC.helpers.getNumber( productData.atgResponse.priceInfo.salePrice ),
							discountAmount = LC.helpers.getNumber( originalPrice - salePrice );

						LC.track.trigger( "ecomm.addToWishlist", {
							skuId: skuId,
							brandName: productData.atgResponse.essentialInfo.brandName,
							name: productData.atgResponse.essentialInfo.displayName,
							quantity: 1,
							salePrice: salePrice,
							originalPrice: originalPrice,
							discount: discountAmount,
							currencyCode: currencyCode,
							variant: "",
							origin: data.origin // used for tracking where the item was added from
						});

					});

				// track GA(4) event
				LC.track.trigger( "ga.event", {
					category: _this.categoryName,
					action: "Add from " + data.origin,
					label: data.productId
				});

			} else {

				LC.track.trigger( "ga.event", {
					category: _this.categoryName,
					action: "FAILED: Add from " + data.origin,
					label: data.productId
				});

			}

		};

		ex.trackRemoveItem = function( data, failed ) {

			LC.track.trigger("ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Remove from " + data.origin, failed ),
				label: data.productId
			});

		};

		ex.trackMoveToWishlist = function( data, failed ) {

			LC.track.trigger("ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Move to wishlist from " + data.origin, failed ),
				label: data.productId
			});

		};

		ex.trackMoveBetweenWishlists = function( data, failed ) {

			LC.track.trigger("ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Move item between wishlists", failed ),
				label: data.productId
			});

		};

		ex.trackMoveToCart = function( data, failed ) {

			LC.track.trigger("ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Add to shopping bag", failed ),
				label: data.productId
			});

		};

		ex.trackCreateWishlist = function( name, failed ) {

			LC.track.trigger( "ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Create new wishlist", failed ),
				label: name || "anonymous"
			});

		};

		ex.trackModifyWishlist = function( name, failed ) {

			LC.track.trigger( "ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Rename wishlist", failed ),
				label: name || "n/a"
			});

		};

		ex.trackDeleteWishlist = function( name, failed ) {

			LC.track.trigger( "ga.event", {
				category: _this.categoryName,
				action: ex.checkFailed( "Delete wishlist", failed ),
				label: "n/a"
			});

		};




		ex.subscribe = function() {

			LC.pubsub
				.off("wishlist.trackAddItem")
				.on("wishlist.trackAddItem", function( e, data ) {
					ex.trackAddItem( data );
				});

			LC.pubsub
				.off("wishlist.trackAddItemFailed")
				.on("wishlist.trackAddItemFailed", function( e, data ) {
					ex.trackAddItem( data, true );
				});

			LC.pubsub
				.off("wishlist.trackRemoveItem")
				.on("wishlist.trackRemoveItem", function( e, data ) {
					ex.trackRemoveItem( data );
				});

			LC.pubsub
				.off("wishlist.trackRemoveItemFailed")
				.on("wishlist.trackRemoveItemFailed", function( e, data ) {
					ex.trackRemoveItem( data, true );
				});

			LC.pubsub
				.off("wishlist.trackMoveToWishlist")
				.on("wishlist.trackMoveToWishlist", function( e, data ) {
					ex.trackMoveToWishlist( data );
				});

			LC.pubsub
				.off("wishlist.trackMoveToWishlistFailed")
				.on("wishlist.trackMoveToWishlistFailed", function( e, data ) {
					ex.trackMoveToWishlist( data, true );
				});

			LC.pubsub
				.off("wishlist.trackMoveBetweenWishlists")
				.on("wishlist.trackMoveBetweenWishlists", function( e, data ) {
					ex.trackMoveBetweenWishlists( data );
				});

			LC.pubsub
				.off("wishlist.trackMoveBetweenWishlistsFailed")
				.on("wishlist.trackMoveBetweenWishlistsFailed", function( e, data ) {
					ex.trackMoveBetweenWishlists( data, true );
				});

			LC.pubsub
				.off("wishlist.trackMoveToCart")
				.on("wishlist.trackMoveToCart", function( e, data ) {
					ex.trackMoveToCart( data );
				});

			LC.pubsub
				.off("wishlist.trackMoveToCartFailed")
				.on("wishlist.trackMoveToCartFailed", function( e, data ) {
					ex.trackMoveToCart( data, true );
				});

			LC.pubsub
				.off("wishlist.trackCreateWishlist")
				.on("wishlist.trackCreateWishlist", function( e, name ) {
					ex.trackCreateWishlist( name );
				});

			LC.pubsub
				.off("wishlist.trackCreateWishlistFailed")
				.on("wishlist.trackCreateWishlistFailed", function( e, name ) {
					ex.trackCreateWishlist( name, true );
				});

			LC.pubsub
				.off("wishlist.trackModifyWishlistFailed")
				.on("wishlist.trackModifyWishlistFailed", function( e, name ) {
					ex.trackModifyWishlist( name, true );
				});

			LC.pubsub
				.off("wishlist.trackDeleteWishlistFailed")
				.on("wishlist.trackDeleteWishlistFailed", function( e, name ) {
					ex.trackDeleteWishlist( name, true );
				});

		};

		return exports;

	});

}( window.LC = window.LC || {}, jQuery ));

 //# sourceURL=global.js
